// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode, useState } from 'react';

import * as React from 'react';
import { Action, Button, Icon, Input, FormErrors } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import styles from './ChangeableName.module.css';

type ChangeableNameProps = {
    name: string;
    lastName: string;
    firstName: string;
    onEdit: () => void;
    onCancel: () => void;
    onSave: (profile: { firstName: string; lastName: string }) => void;
    isLoading: boolean;
    showForm: boolean;
    errors?: FormErrors;
};

const ChangeableName = ({
    name,
    firstName,
    lastName,
    onEdit,
    onCancel,
    onSave,
    isLoading,
    showForm,
    errors,
}: ChangeableNameProps) => {
    const translate = useTranslate('accounts.profile.ChangeableName');
    const [newFirstName, setNewFirstName] = useState<string>(firstName);
    const [newLastName, setNewLastName] = useState<string>(lastName);
    const handleSave = () => onSave({ firstName: newFirstName, lastName: newLastName });
    const handleCancel = () => {
        setNewFirstName(firstName);
        setNewLastName(lastName);
        onCancel();
    };

    let errorMessage: ReactNode | undefined = undefined;
    let content: ReactNode | undefined = undefined;

    if (showForm) {
        const inputs = {
            firstName: (
                <Input
                    placeholder={translate('firstName')}
                    type="text"
                    value={newFirstName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setNewFirstName(e.target.value)
                    }
                    size={14}
                    data-type="first-name-field"
                    autoFocus
                />
            ),
            lastName: (
                <Input
                    placeholder={translate('lastName')}
                    type="text"
                    value={newLastName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setNewLastName(e.target.value)
                    }
                    size={14}
                    data-type="last-name-field"
                />
            ),
        };
        (Object.keys(inputs) as (keyof typeof inputs)[]).every((key) => {
            const [error] = Object.values(errors?.[key] || {});
            if (error) {
                inputs[key] = <div className={styles.errorInputWrapper}>{inputs[key]}</div>;
                errorMessage = (
                    <div className={styles.errorMessage}>
                        <Icon
                            name="exclamation-mark-circle-filled"
                            size="12"
                            className={styles.errorIcon}
                        />
                        {error}
                    </div>
                );
                return false;
            }
            return true;
        });
        content = isLoading ? (
            <Action progress>{translate('updating')}</Action>
        ) : (
            <>
                {inputs.firstName}
                {inputs.lastName}
                <div>
                    <Button icon="check-mark" onClick={handleSave} data-type="save-button" ghost />
                    <Button
                        icon="cross-mark"
                        onClick={handleCancel}
                        data-type="cancel-button"
                        ghost
                    />
                </div>
            </>
        );
    } else {
        content = (
            <>
                {name}
                <Button ghost icon="pencil" onClick={onEdit} data-type="edit-name-button" />
            </>
        );
    }

    return (
        <>
            <div className={styles.customInPlaceEdit} data-type="changeable-name">
                {content}
            </div>
            {showForm && errorMessage}
        </>
    );
};

export default ChangeableName;
