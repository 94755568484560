// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import Footer from '@platform360/libs/shared-web/components/Footer';
import WebprosLogo from '@platform360/libs/shared-web/components/WebprosLogo';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';
import { Button, Dropdown, Menu, MenuItem } from '@plesk/ui-library';
import { Link } from 'react-router-dom';
import styles from './LandingPage.module.css';
import { useMediaQuery } from 'usehooks-ts';

export type LandingPageProps = {
    onLogin: () => void;
    onSignUp: () => void;
};

const LandingPage = ({ onLogin, onSignUp }: LandingPageProps) => {
    const translate = useTranslate('auth.LandingPage');
    const isCompactMenu = useMediaQuery('(max-width: 768px)');

    const buttons = isCompactMenu ? (
        <Dropdown
            menu={
                <Menu>
                    <MenuItem onClick={onSignUp}>{translate('signUpButton')}</MenuItem>
                    <MenuItem onClick={onLogin}>{translate('signInButton')}</MenuItem>
                </Menu>
            }
        >
            <Button icon="menu" ghost className={styles.menuTrigger} />
        </Dropdown>
    ) : (
        <>
            <Button ghost onClick={onSignUp}>
                {translate('signUpButton')}
            </Button>
            <Button ghost onClick={onLogin}>
                {translate('signInButton')}
            </Button>
        </>
    );

    return (
        <div className={styles.root}>
            <div className={styles.header} data-type="header">
                <Link to="/">
                    <WebprosLogo inverse />
                </Link>
                <div className={styles.expander} />
                <LocaleSwitcher />
                {buttons}
            </div>
            <div className={styles.main} data-type="main">
                <h1 className={styles.title}>{translate('title')}</h1>
                <p>{translate('description')}</p>
                <Button
                    size="lg"
                    intent="primary"
                    onClick={onLogin}
                    className={styles.primarySignIn}
                >
                    {translate('signInButton')}
                </Button>
            </div>
            <Footer />
        </div>
    );
};

export default LandingPage;
