// Copyright 2024. WebPros International GmbH. All rights reserved.

import SignUpByInvitation, { getInvitationEmailFromLocation } from './SignUpByInvitation';
import withGuest from '@platform360/auth/web/components/withGuest';
import { useOperationStatus } from '@platform360/auth/web/components/useOperationStatus';
import AuthLayout from '@platform360/libs/shared-web/components/AuthLayout';
import Header from '@platform360/libs/shared-web/components/Header';
import styles from './SignUpContainer.module.css';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';
import { Link, Text } from '@plesk/ui-library';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import Loading from '@platform360/libs/shared-web/components/Loading';

export const SignUpContainer = () => {
    const { statusMessage, setOperationStatus } = useOperationStatus();
    const translate = useTranslate('Auth.AuthContainer');
    const { login } = useAuth();
    const invitationEmail = getInvitationEmailFromLocation(window.location);

    useEffectOnce(() => {
        const { search } = location;
        const params = new URLSearchParams(search);

        // Redirect to Sitejet branded login page (required for facebook review procedure)
        if (params.has('sitejet')) {
            void login({ page: 'login', branding: 'sitejet' });
            return;
        }

        if (!invitationEmail) {
            void login({ page: 'sign-up' });
        }
    });

    const handleLogin = () => {
        void login();
    };

    if (!invitationEmail) {
        return <Loading />;
    }

    return (
        <AuthLayout className={styles.root}>
            <Header addon={<LocaleSwitcher />} />
            {statusMessage}
            <SignUpByInvitation setOperationStatus={setOperationStatus} />
            <div style={{ textAlign: 'center' }}>
                <Text intent="muted" fontSize="sm">
                    {translate('alreadyHaveAnAccount')}&nbsp;
                    <Link onClick={handleLogin}>{translate('loginLink')}</Link>
                </Text>
            </div>
        </AuthLayout>
    );
};

export default withGuest(SignUpContainer);
