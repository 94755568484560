// Copyright 2024. WebPros International GmbH. All rights reserved.

export const PERMISSION_ALL_USERS_MANAGE = 'all:users:manage';
export const PERMISSION_ALL_USERS_IMPERSONATE = 'all:users:impersonate';
export const PERMISSION_ALL_USERS_DELETE = 'all:users:delete';
export const PERMISSION_ALL_EMAIL_METAS_MANAGE = 'all:email-metas:manage';
export const PERMISSION_ALL_LEIKA_EMAIL_HASHES_MANAGE = 'all:leika-email-hashes:manage';

const PERMISSIONS = [
    PERMISSION_ALL_USERS_MANAGE,
    PERMISSION_ALL_EMAIL_METAS_MANAGE,
    PERMISSION_ALL_LEIKA_EMAIL_HASHES_MANAGE,
    PERMISSION_ALL_USERS_IMPERSONATE,
    PERMISSION_ALL_USERS_DELETE,
] as const;

export type Permission = (typeof PERMISSIONS)[number];
