// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './IconGravatar.module.less';
import classNames from 'classnames';

type IconGravatarProps = {
    className?: string;
    userProfile: null | {
        email: string;
        picture: string;
    };
};

const IconGravatar = ({ userProfile, className }: IconGravatarProps) => {
    if (!userProfile) {
        return null;
    }

    if (userProfile.picture) {
        return (
            <img
                src={userProfile.picture}
                alt={userProfile.email}
                title={userProfile.email}
                className={classNames(styles.gravatarImage, className)}
            />
        );
    }

    return null;
};

export default IconGravatar;
