// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dialog, FormFieldCheckbox, Heading } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Consent } from '@platform360/accounts/web/types';

export type EditConsentDialogProps = {
    isOpen: boolean;
    consent: Consent;
    onClose: () => void;
    onChange: (value: boolean) => void;
    isSaving: boolean;
    onSubmit: (consent: Consent) => void;
};
export const EditConsentDialog = ({
    isOpen,
    onClose,
    consent,
    onChange,
    isSaving,
    onSubmit,
}: EditConsentDialogProps) => {
    const translate = useTranslate('accounts.profile.ConsentManager');

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title={translate('dialogTitle')}
            form={{
                state: isSaving ? 'submit' : undefined,
                onSubmit: () => onSubmit(consent),
                submitButton: { children: translate('buttonSave'), 'data-type': 'save-button' },
            }}
            size="xs"
            data-type="edit-consent-dialog"
        >
            <FormFieldCheckbox
                label={
                    <>
                        <Heading level={5}>{consent.title}</Heading>
                        {consent.text}
                    </>
                }
                onChange={onChange}
                value={consent.enabled}
            />
        </Dialog>
    );
};
