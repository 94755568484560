// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Copied to clipboard.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'You are impersonating a different account.',
            depersonateButton: 'Stop Impersonating',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'You are no longer impersonating a different account.',
        },
        UserMenu: {
            team: 'Organization',
            logout: 'Log out',
            changePassword: 'Change password',
            businessProfile: 'Business profile',
            apiTokens: 'API tokens',
        },
    },

    common: {
        errors: {
            isNotEmpty: 'This required field is empty.',
            required: 'This required field is empty.',
            email: 'The value must be a valid email address.',
            isEmail: 'The value must be a valid email address.',
            min: '{min, plural, one{The value must be at least # character long.} other{The value must be at least # characters long.}}',
            maxLength: '{max, plural, one{The value must be at most # character long.} other{The value must be at most # characters long.}}',
            unique: 'The value must be unique.',
            unknown: 'Unknown error: {message}',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'You must enter a password.',
            errorPasswordIsWrong: 'Wrong password.',
            errorPasswordIsTooWeak: 'The password is too weak.',
            errorPasswordContainsPersonalInfo: 'The password must not contain personal information.',
            errorPasswordTooCommon: 'The password is too common.',
            errorPasswordPreviouslyUsed: 'The password has been used previously.',
            errorInvalidOtp: 'The code you entered is not valid.',
        },
    },

    ConfirmationPopover: {
        cancel: 'Cancel',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'WebPros Account uses ZendeskChat, a third party application.',
            description2: 'By proceeding, I hereby agree to use ZendeskChat, a third party application. This may involve my personal data (for example, IP address) being transferred to third parties in or outside of Europe. For more information, read our <a>Privacy Policy</a>.',
            confirmButton: 'Launch ZendeskChat',
        },
        supportFormPopover: {
            description: 'No agents are available at the moment. Please wait or use our ticket form to submit a request.',
            confirmButton: 'Open Form',
        },
        ChatButton: {
            title: 'Support',
        },
    },

    emailRequired: "To log in to WebPros using your Facebook account, access to view the email address must be granted. Make sure that the corresponding permission is granted and try again.",
    userBlocked: "The account was suspended due to a violation of the user's agreement. If you believe this is a mistake, please write to {emailLink}.",
    invalidDomain: 'Access is denied. The user email is not on the whitelist.',

    errorDefault: 'Something went wrong. Please try again later.',
    errorDefaultWithReqId: 'Something went wrong. Please try again later. Request ID: {requestId}.',
    errorTooManyRequests: 'API call limit reached for this IP address. Please try again in a minute.',
    errorTokenRenewal: 'Your session has expired. Please re-login.',
    errorNetworkIssue: 'It seems that you are having trouble connecting to the network right now.',

    errorCopyToClipboard: 'A copying error occurred, try copying manually.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH will process your provided data to process your request in accordance to its <a>Privacy Policy</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'The user does not exist.',
            bounceOrComplaint: 'This email address does not exist or has blocked our message as spam. Please <webProsContactLink>contact</webProsContactLink> the WebPros Account support team.',
            userIsNotVerified: 'The user exists, but has not been verified. We have sent a new verification email. Check your mailbox.',
            userExist: 'The user already exists.',
            errorPasswordIsEmpty: 'The password is required.',
            errorPasswordIsWrong: 'Wrong password.',
            errorPasswordIsTooWeak: 'The password is too weak.',
        },
        AuthContainer: {
            alreadyHaveAnAccount: 'Already have an account?',
            loginLink: 'Log in',
        },
        SignUp: {
            signUp: 'Create account',
            emailLabel: 'Email',
            passwordLabel: 'Password',
            privacyPolicyAgreementTitle: 'I agree with the <termsLink>Terms of Use</termsLink> and that WebPros will process my provided personal data in its internal systems according to the WebPros <privacyLink>Privacy Policy</privacyLink>',
            SignUpByInvitation: {
                emailDescription: 'You will be signed up with the email above',
                invitationSucceed: 'You successfully signed up. Please, log in now.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'First name',
                    lastNamePlaceholder: 'Last name',
                    nameLabel: 'Name',
                },
            },
        },
        ResetPasswordDrawer: {
            title: 'Reset password',
            text: 'Enter your email address and we will send you an email with a password reset link.',
            emailLabel: 'Email:',
            submitButton: 'Send',
            successTitle: 'Password Reset Email Sent',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Resend',
            loginForm: 'Log in now',
            retryCountdown: 'Resend is possible in {time}',
        },
    },
    Footer: {
        copyright: '© {year} WebPros International GmbH. All rights reserved.',
        company: 'Company',
        knowledgeBase: 'Knowledge Base',
        aboutWebPros: 'About WebPros',
        legal: 'Legal',
        cookieSettings: 'Cookie Settings',
        privacyPolicy: 'Privacy Policy',
        documentation: 'Documentation',
        contactUs: 'Contact Us',

    },
    Form: {
        requiredLegend: 'Required fields',
        submitButton: 'OK',
        cancelButton: 'Cancel',
    },

    FormField: {
      addMore: 'Add one more',
      remove: 'Remove',
      clear: 'Clear',
    },

    FormFieldPassword: {
        improvePassword: 'For a more secure password:',
        passwordTooShort: 'Make the password longer.',
        lettersLowerCase: 'Use at least one lowercase character.',
        lettersUpperCase: 'Use at least one uppercase character.',
        numbers1: 'Use at least one number.',
        numbers3: 'Use at least three numbers.',
        specialChar1: 'Use at least one special character (for example: !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Use at least two special characters (for example: !, @, #, $, %, ^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Use both uppercase and lowercase characters.',
        comboLettersAndNumbers: 'Use both numbers and characters.',
        comboLettersNumbersSpecial: 'Use numbers, characters, and special characters.',
        yourPasswordIsStrong: 'You can make it even stronger by increasing its length.',
        passwordStrength: 'The password strength is %%strength%%.',
        strengthVeryStrong: 'VERY STRONG',
        strengthStrong: 'STRONG',
        strengthVeryWeak: 'VERY WEAK',
        strengthWeak: 'WEAK',
        strengthMedium: 'MEDIUM',
        hidePassword: 'Hide password',
        showPassword: 'Show password',
        generateButton: 'Generate',
        generateButtonHint: 'Generate a strong password',
    },

    Pagination: {
        totalShort: 'of %%total%%',
        itemsPerPage: 'Items per page',
        all: 'All',
        total: 'Page %%current%% of %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Could not find any items matching the filter',
        emptyTitle: 'Looks like there is nothing here',
        filteredDescription: 'Refine your query or remove some of the criteria from the filter and try again.',
    },

    Dialog: {
        cancelButton: 'Cancel',
    },

    GaOptOutLink: {
        gaSuccess: 'Google Analytics has stopped tracking.',
    },

    InPlaceText: {
        ok: 'Save (Ctrl+Enter)',
        cancel: 'Cancel (Esc)',
        edit: 'Edit',
        add: 'Add',
    },

    Team: {
        Layout: {
            title: 'Organization',
            members: 'Members',
            invitations: 'Invitations',
        },
        settings: 'Settings',
        Members: {
            email: 'E-mail',
            fullName: 'Full name',
            mfaStatus: 'MFA Status',
            tags: 'Special marks',
            remove: 'Remove',
            removeMemberConfirmation: '<b>{name}</b> will no longer have access to the organization and shared resources within, servers, websites, and licenses.',
            SpecialMarks: {
                you: 'You',
                owner: 'Owner',
            },
            Onboarding: {
                title: 'Create your organization in our platform by adding your colleagues',
                description: 'Our platform offers you features for efficient organization management and collaboration. Your organization members can work with you on connected servers by accessing them directly from here. You no longer need to use insecure shared passwords or complicated access arrangements.',
                addMembersButton: 'Add organization members',
            },
            MultifactorStatus: {
                enabled: 'MFA Enabled',
                disabled: 'MFA Not Enabled',
            },
        },
        Invitations: {
            email: 'E-mail',
            expireAt: 'Expire At',
            expired: 'Expired',
            add: 'Add',
            revoke: 'Revoke',
            Onboarding: {
                title: 'Here you can invite your organizations members',
                description: 'Add your colleagues to share with them servers and websites.',
                addButton: 'Invite new member',
            },
            ListActions: {
                resendInvitationTooltip: 'Resend invite.',
                successMessage: 'Invite email sent again.',
            },
            AddInvitations: {
                title: 'Add new members to the organization',
                infoMessage: 'Invite your organization members to join by entering their work email addresses below.',
                infoDescription: 'Work email addresses are preferred to personal email addresses to safeguard users’ personal information.',
                emailsLabel: 'Email addresses for invitation link',
                submitButton: 'Send invitation',
                successMessage: '{count, plural, =1{Invite to {email} has been sent.} one{# invite has been sent.} other{# invites have been sent.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Revoke',
                noSelectedInvitations: 'Select one or more invitations to revoke.',
                revokeInvitationConfirmation: '{count, plural, =1{Revoke <b>{email}</b>?} one{Revoke <b>#</b> invitation?} other{Revoke <b>#</b> invitations?}} You cannot undo this action.',
                successRevokeInvitation: '{count, plural, =1{The <b>{email}</b> email has been revoked.} one{<b>#</b> invitation has been revoked.} other{<b>#</b> invitations have been revoked.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'WebPros may provide me with newsletters and individual offers in accordance to the WebPros <a>Privacy Policy</a>.',
            text: 'I may revoke this consent by clicking the link in any communication received',
        },
    },

    components: {
        List: {
            selectAll: 'Select all',
            deselectAll: 'Deselect',
            selectedRows: '{selectedRows, plural, one{# of {totalRows, plural, one{# item} other{# items}} selected} other{# of {totalRows, plural, one{# item} other{# items}} selected}}',
            totalRows: '{totalRows, plural, one{# item total} other{# items total}}',
            foundRows: '{foundRows, plural, one{# item found} other{# items found}}',
        },
        DataList: {
            groupSearch: 'Search',
        },
    },
};
