// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const {
    brandingValues,
    isbranding: isBranding,
    brandings,
} = makeEnum(
    'branding',
    'cpanel',
    'sitejet',
    'solusvm',
    'wpt',
    'wpg',
    'monitoring',
    'xovi',
    'koality',
    'whmcs',
    'platform',
    'plesk',
);

export type Branding = UnionOf<typeof brandingValues>;
