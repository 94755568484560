// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint-disable func-style */

import { UntypedAxiosError } from '@platform360/libs/shared-web/typings/api';
import {
    UseQueryOptions as ReactQueryUseQueryOptions,
    UseQueryResult,
    QueryFunction,
    QueryClient,
    Updater,
    NoInfer,
} from '@tanstack/react-query';

type QueryName = `${string}/${string}`;
type QueryKey<TVariables, TQueryName = QueryName> = undefined extends TVariables
    ? [TQueryName]
    : [TQueryName, TVariables];

type BaseQueryOptions<
    TVariables,
    TQueryFnData,
    TError,
    TQueryKey extends QueryKey<TVariables>,
> = Omit<
    ReactQueryUseQueryOptions<TQueryFnData, TError, TQueryFnData, TQueryKey>,
    'queryKey' | 'queryFn'
>;

type InternalQueryOptions<
    TVariables,
    TQueryFnData,
    TError,
    TQueryKey extends QueryKey<TVariables>,
> = BaseQueryOptions<TVariables, TQueryFnData, TError, TQueryKey> & {
    variables: TVariables;
};

type InternalQuery<TVariables, TQueryFnData, TError, TQueryKey extends QueryKey<TVariables>> = (
    options: InternalQueryOptions<TVariables, TQueryFnData, TError, TQueryKey> & {
        queryKey: TQueryKey;
        queryFn: QueryFunction<TQueryFnData, TQueryKey>;
    },
) => UseQueryResult<TQueryFnData, TError>;

type FetcherOptions<
    TVariables,
    TQueryKey extends QueryKey<TVariables>,
> = undefined extends TVariables
    ? {
          queryKey: TQueryKey;
          signal?: AbortSignal;
      }
    : {
          queryKey: TQueryKey;
          variables: TVariables;
          signal?: AbortSignal;
      };

type Fetcher<TVariables, TQueryFnData, TQueryKey extends QueryKey<TVariables>> = (
    options: FetcherOptions<TVariables, TQueryKey>,
) => TQueryFnData | Promise<TQueryFnData>;

type CreateQueryOptions<
    TVariables,
    TQueryFnData,
    TError extends UntypedAxiosError = UntypedAxiosError,
    TQueryName extends QueryName = QueryName,
    TQueryKey extends QueryKey<TVariables, TQueryName> = QueryKey<TVariables, TQueryName>,
> = {
    queryName: TQueryName;
    fetcher: Fetcher<TVariables, TQueryFnData, TQueryKey>;
    useQuery: InternalQuery<TVariables, TQueryFnData, TError, TQueryKey>;
};
type QueryWrapperOptions<
    TVariables,
    TQueryFnData,
    TError,
    TQueryKey extends QueryKey<TVariables>,
> = undefined extends TVariables
    ? BaseQueryOptions<TVariables, TQueryFnData, TError, TQueryKey>
    : BaseQueryOptions<TVariables, TQueryFnData, TError, TQueryKey> & {
          variables: TVariables;
      };

type QueryWrapperArguments<
    TVariables,
    TQueryFnData,
    TError,
    TQueryKey extends QueryKey<TVariables>,
> = undefined extends TVariables
    ? [options?: QueryWrapperOptions<TVariables, TQueryFnData, TError, TQueryKey>]
    : [options: QueryWrapperOptions<TVariables, TQueryFnData, TError, TQueryKey>];

export function createQuery<
    TVariables,
    TQueryFnData,
    TError extends UntypedAxiosError = UntypedAxiosError,
    TQueryName extends QueryName = QueryName,
    TQueryKey extends QueryKey<TVariables, TQueryName> = QueryKey<TVariables, TQueryName>,
>({
    queryName,
    fetcher,
    useQuery,
}: CreateQueryOptions<TVariables, TQueryFnData, TError, TQueryName, TQueryKey>) {
    const getQueryKey = (variables?: TVariables): TQueryKey => {
        if (typeof variables === 'undefined') {
            // @ts-expect-error
            return [queryName];
        }

        // @ts-expect-error
        return [queryName, variables];
    };
    const queryFn: QueryFunction<TQueryFnData, TQueryKey> = (context) => {
        const [, variables] = context.queryKey;
        // @ts-expect-error
        return fetcher({ ...context, variables });
    };
    const setQueryData = (
        queryClient: QueryClient,
        queryKey: TQueryKey,
        updater: Updater<NoInfer<TQueryFnData> | undefined, TQueryFnData | undefined>,
    ) => {
        queryClient.setQueryData(queryKey, updater);
    };

    const setQueriesData = (
        queryClient: QueryClient,
        queryKey: TQueryKey,
        updater: Updater<TQueryFnData | undefined, TQueryFnData | undefined>,
    ) => {
        queryClient.setQueriesData({ queryKey }, updater);
    };

    const useQueryWrapper = (
        ...args: QueryWrapperArguments<TVariables, TQueryFnData, TError, TQueryKey>
    ) => {
        const [options] = args;
        // @ts-expect-error
        const variables = options?.variables;

        const queryKey = getQueryKey(variables);
        return useQuery({ queryKey, queryFn, variables, ...options });
    };

    return Object.assign(useQueryWrapper, { getQueryKey, setQueryData, setQueriesData });
}
