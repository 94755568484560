// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Legale',
        terms: 'Condizioni d\'uso',
        privacyPolicy: 'Informativa sulla privacy',
        dpa: 'Accordo per l\'elaborazione dei dati',
        optOuts: 'Clausola di dissociazione',
        cookies: 'Informativa sui Cookie',
        gaHeader: 'Disocciazione da Google Analytics',
        gaOptOut: 'Clicca qui per dissociarti da Google Analytics',
        cookieHeader: 'Adeguamenti del consenso ai cookie',
        cookieModify: 'Modifica il consenso ai cookie',
        cookieWithdraw: 'Ritira il consenso ai cookie',
        cookieSuccess: 'Ritiro del consenso riuscito',
    },
};