// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Copié dans le presse-papier.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Vous empruntez l\'identité d\'un autre compte.',
            depersonateButton: 'Arrêter l\'emprunt d\'identité',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Vous n\'empruntez plus l\'identité d\'un autre compte.',
        },
        UserMenu: {
            team: 'Organisation',
            logout: 'Se déconnecter',
            changePassword: 'Changer le mot de passe',
            businessProfile: 'Profil entreprise',
            apiTokens: 'Tokens API',
        },
    },

    common: {
        errors: {
            isNotEmpty: 'Ce champ obligatoire est vide.',
            required: 'Ce champ obligatoire est vide.',
            email: 'La valeur doit correspondre à une adresse e-mail valide.',
            isEmail: 'La valeur doit correspondre à une adresse e-mail valide.',
            min: '{min, plural, one{La valeur doit comporter au moins # caractère.} other{La valeur doit comporter au moins # caractères.}}',
            maxLength: '{max, plural, one{La valeur doit comporter au plus # caractère.} other{La valeur doit comporter au plus # caractères.}}',
            unique: 'La valeur doit être unique.',
            unknown: 'Erreur inconnue : {message}',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Vous devez saisir un mot de passe.',
            errorPasswordIsWrong: 'Mot de passe incorrect.',
            errorPasswordIsTooWeak: 'Le mot de passe est trop faible.',
            errorPasswordContainsPersonalInfo: 'Le mot de passe ne doit contenir aucune information personnelle.',
            errorPasswordTooCommon: 'Ce mot de passe est trop simple.',
            errorPasswordPreviouslyUsed: 'Ce mot de passe a déjà été utilisé par le passé.',
            errorInvalidOtp: 'Le code saisi n\'est pas valide.',
        },
    },

    ConfirmationPopover: {
        cancel: 'Annuler',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'WebPros Account utilise ZendeskChat, une application tierce.',
            description2: 'En poursuivant, j\'accepte d\'utiliser ZendeskChat, une application tierce. Par conséquent, mes données personnelles (par exemple : l\'adresse IP) peuvent être transmises à des tiers en Europe ou hors d\'Europe. Pour en savoir plus, consultez notre <a>Politique de confidentialité</a>.',
            confirmButton: 'Lancer ZendeskChat',
        },
        supportFormPopover: {
            description: 'Aucun agent n\'est disponible pour le moment. Veuillez patienter ou utiliser le formulaire de ticket afin d\'envoyer votre demande.',
            confirmButton: 'Ouvrir le formulaire',
        },
        ChatButton: {
            title: 'Assistance',
        },
    },

    emailRequired: "To log in to WebPros using your Facebook account, access to view the email address must be granted. Make sure that the corresponding permission is granted and try again.",
    userBlocked: "Le compte a été suspendu en raison d'une violation du contrat utilisateur. Si vous pensez qu'il s'agit d'une erreur, envoyez un e-mail à cette adresse : {emailLink}.",
    invalidDomain: 'Accès refusé. L\'adresse e-mail de l\'utilisateur ne figure pas sur la liste d\'autorisation.',

    errorDefault: 'Un problème est survenu. Veuillez réessayer ultérieurement.',
    errorDefaultWithReqId: 'Un problème s\'est produit. Veuillez réessayer ultérieurement. ID de la demande : {requestId}.',
    errorTooManyRequests: 'La limite d\'appels API a été atteinte pour cette adresse API. Veuillez réessayer dans une minute.',
    errorTokenRenewal: 'Votre session a expiré. Veuillez vous reconnecter.',
    errorNetworkIssue: 'Apparemment, vous rencontrez en ce moment des difficultés à vous connecter au réseau.',

    errorCopyToClipboard: 'Une erreur s\'est produite pendant la copie. Essayez de copier manuellement.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH traitera les données que vous avez fournies afin de répondre à votre demande conformément à cette <a>Politique de confidentialité</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'L\'utilisateur n\'existe pas.',
            bounceOrComplaint: 'Cette adresse e-mail n\'existe pas ou a bloqué notre message comme étant du spam. Veuillez <webProsContactLink>contacter</webProsContactLink> l\'équipe d\'assistance WebPros Account.',
            userIsNotVerified: 'L\'utilisateur existe mais n\'a pas été vérifié. Nous vous avons adressé un nouveau mail de vérification. Veuillez vérifier votre boîte mail.',
            userExist: 'L\'utilisateur existe déjà.',
            errorPasswordIsEmpty: 'Le mot de passe est obligatoire.',
            errorPasswordIsWrong: 'Mot de passe incorrect.',
            errorPasswordIsTooWeak: 'Le mot de passe est trop faible.',
        },
        AuthContainer: {
            alreadyHaveAnAccount: 'Vous avez déjà un compte ?',
            loginLink: 'Se connecter',
        },
        SignUp: {
            signUp: 'Créer un compte',
            emailLabel: 'E-mail',
            passwordLabel: 'Mot de passe',
            privacyPolicyAgreementTitle: 'J\'accepte les <termsLink>Conditions d\'utilisation</termsLink> et j\'accepte que WebPros traite les données personnelles que j\'ai fournies dans ses systèmes internes conformément à la <privacyLink>Politique de confidentialité</privacyLink> de WebPros.',
            SignUpByInvitation: {
                emailDescription: 'Vous serez inscrit avec l\'adresse e-mail ci-dessus.',
                invitationSucceed: 'Votre inscription est réussie. Veuillez vous connecter maintenant.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Prénom',
                    lastNamePlaceholder: 'Nom de famille',
                    nameLabel: 'Nom complet',
                },
            },
        },
        ResetPasswordDrawer: {
            title: 'Réinitialiser le mot de passe',
            text: 'Saisissez votre adresse e-mail et nous vous renverrons un lien de réinitialisation du mot de passe.',
            emailLabel: 'E-mail :',
            submitButton: 'Envoyer',
            successTitle: 'E-mail de réinitialisation du mot de passe envoyé',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Renvoyer',
            loginForm: 'Se connecter maintenant',
            retryCountdown: 'Renvoi possible dans {time}',
        },
    },
    Footer: {
        copyright: '© {year} WebPros International GmbH. Tous droits réservés.',
        company: 'Raison sociale',
        knowledgeBase: 'Base de connaissances',
        aboutWebPros: 'À propos de WebPros',
        legal: 'Informations légales',
        cookieSettings: 'Paramètres des cookies',
        privacyPolicy: 'Politique de confidentialité',
        documentation: 'Documentation',
        contactUs: 'Nous contacter',

    },
    Form: {
        requiredLegend: 'Champs requis',
        submitButton: 'OK',
        cancelButton: 'Annuler',
    },

    FormField: {
      addMore: 'Ajouter une adresse',
      remove: 'Supprimer',
      clear: 'Effacer',
    },

    FormFieldPassword: {
        improvePassword: 'Pour définir un mot de passe plus sécurisé :',
        passwordTooShort: 'Utilisez un mot de passe plus long.',
        lettersLowerCase: 'Utilisez au moins une lettre minuscule.',
        lettersUpperCase: 'Utilisez au moins une lettre majuscule.',
        numbers1: 'Utilisez au moins un chiffre.',
        numbers3: 'Utilisez au moins trois chiffres.',
        specialChar1: 'Utilisez au moins un caractère spécial (par exemple : !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Utilisez au moins deux caractères spéciaux (par exemple : !, @, #, $, % ,^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Utilisez des lettres majuscules et minuscules.',
        comboLettersAndNumbers: 'Utilisez des chiffres et des lettres.',
        comboLettersNumbersSpecial: 'Utilisez des chiffres, des lettres minuscules et majuscules et des caractères spéciaux.',
        yourPasswordIsStrong: 'Vous pouvez renforcer le niveau de sécurité en utilisant un mot de passe plus long.',
        passwordStrength: 'Niveau de sécurité du mot de passe : %%strength%%.',
        strengthVeryStrong: 'TRÈS FORT',
        strengthStrong: 'FORT',
        strengthVeryWeak: 'TRÈS FAIBLE',
        strengthWeak: 'FAIBLE',
        strengthMedium: 'MOYEN',
        hidePassword: 'Masquer le mot de passe',
        showPassword: 'Montrer le mot de passe',
        generateButton: 'Générer',
        generateButtonHint: 'Générer un mot de passe fort',
    },

    Pagination: {
        totalShort: 'sur %%total%%',
        itemsPerPage: 'Éléments par page',
        all: 'Tous',
        total: 'Page %%current%% sur %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Aucun élément ne correspond aux critères du filtre',
        emptyTitle: 'Apparemment, il n\'y a rien ici...',
        filteredDescription: 'Affinez votre requête ou supprimez certains critères du filtre et essayez à nouveau.',
    },

    Dialog: {
        cancelButton: 'Annuler',
    },

    GaOptOutLink: {
        gaSuccess: 'Google Analytics a arrêté le suivi.',
    },

    InPlaceText: {
        ok: 'Enregistrer (Ctrl+Enter)',
        cancel: 'Annuler (Esc)',
        edit: 'Éditer',
        add: 'Ajouter',
    },

    Team: {
        Layout: {
            title: 'Organisation',
            members: 'Membres',
            invitations: 'Invitations',
        },
        settings: 'Paramètres',
        Members: {
            email: 'Adresse e-mail',
            fullName: 'Nom complet',
            mfaStatus: 'Statut MFA',
            tags: 'Marques spéciales',
            remove: 'Supprimer',
            removeMemberConfirmation: '<b>{name}</b> ne pourra plus accéder à l\'organisation ni à ses ressources partagées, serveurs, sites web et licences.',
            SpecialMarks: {
                you: 'Vous',
                owner: 'Propriétaire',
            },
            Onboarding: {
                title: 'Créez votre organisation dans notre plate-forme en ajoutant vos collègues.',
                description: 'Notre plate-forme vous propose des fonctionnalités de gestion et de collaboration efficaces pour les organisations. Vous et les membres de votre organisation pouvez travailler sur des serveurs connectés en y accédant directement depuis la plate-forme. Vous n\'avez plus besoin d\'utiliser des mots de passe partagés non sécurisés ou des systèmes accès complexes.',
                addMembersButton: 'Ajouter des membres de l\'organisation',
            },
            MultifactorStatus: {
                enabled: 'MFA activé',
                disabled: 'MFA non activée',
            },
        },
        Invitations: {
            email: 'Adresse e-mail',
            expireAt: 'Expire le',
            expired: 'Expiré',
            add: 'Ajouter',
            revoke: 'Révoquer',
            Onboarding: {
                title: 'Ici, vous pouvez inviter des membres de votre organisation',
                description: 'Ajoutez vos collègues pour partager des serveurs et des sites web avec eux.',
                addButton: 'Inviter un nouveau membre',
            },
            ListActions: {
                resendInvitationTooltip: 'Renvoyez l\'invitation.',
                successMessage: 'L\'invitation par e-mail a été renvoyée.',
            },
            AddInvitations: {
                title: 'Ajouter de nouveaux membres à l\'organisation',
                infoMessage: 'Invitez les membres de votre organisation à vous rejoindre en saisissant leurs adresses e-mails professionnelles ci-dessous.',
                infoDescription: 'Nous vous recommandons de saisir les adresses e-mails professionnelles et non les adresses privées afin de protéger les informations personnelles de l\'utilisateur.',
                emailsLabel: 'Adresses e-mail pour le lien d\'invitation',
                submitButton: 'Envoyer l\'invitation',
                successMessage: '{count, plural, =1{L\'invitation a été envoyée à {email}.} one{# invitation a été envoyée.} other{# invitations ont été envoyées.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Révoquer',
                noSelectedInvitations: 'Sélectionnez une ou plusieurs invitations à révoquer.',
                revokeInvitationConfirmation: '{count, plural, =1{Révoquer <b>{email}</b> ?} one{Révoquer <b>#</b> invitation ?} other{Révoquer <b>#</b> invitations ?}} Vous ne pouvez pas annuler cette action.',
                successRevokeInvitation: '{count, plural, =1{L\'adresse <b>{email}</b> a été révoquée.} one{<b>#</b> invitation a été révoquée.} other{<b>#</b> invitations ont été révoquées.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'WebPros peut m\'envoyer des newsletters et des offres ciblées conformément à la <a>Politique de confidentialité</a> de WebPros.',
            text: 'Je peux révoquer ce consentement en cliquant sur le lien disponible dans toute communication reçue.',
        },
    },

    components: {
        List: {
            selectAll: 'Tout sélectionner',
            deselectAll: 'Ne plus sélectionner',
            selectedRows: '{selectedRows, plural, one{# sur {totalRows, plural, one{# élément} other{# éléments}} sélectionné} other{# sur {totalRows, plural, one{# éléments} other{# éléments}} sélectionnés}}',
            totalRows: '{totalRows, plural, one{# élément en tout} other{# éléments en tout}}',
            foundRows: '{foundRows, plural, one{# élément trouvé} other{# éléments trouvés}}',
        },
        DataList: {
            groupSearch: 'Rechercher',
        },
    },
};