// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useAuth } from '@platform360/libs/shared-web/auth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import qs from 'qs';

const LoginRedirect = () => {
    const location = useLocation();
    const redirectPath = '/auth/login';
    const redirectUrl = `${location.pathname}${location.search}${location.hash}`;

    if (redirectUrl !== '/') {
        return <Navigate to={`${redirectPath}?${qs.stringify({ redirectUrl })}`} />;
    }

    return <Navigate to={redirectPath} />;
};

const PrivateRoutes = () => {
    const { isAuthorized } = useAuth();

    if (!isAuthorized) {
        return <LoginRedirect />;
    }

    return <Outlet />;
};

export default PrivateRoutes;
