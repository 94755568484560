// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useAuth } from '@platform360/libs/shared-web/auth';
import { useEffect } from 'react';

/**
 * Do not display auth page for admin panel.
 * Redirect to the universal login page in case if the user is unauthorized.
 */
export const Login = () => {
    const { login, isAuthorized } = useAuth();

    useEffect(() => {
        if (!isAuthorized) {
            void login();
        }
    }, [isAuthorized, login]);

    return null;
};
