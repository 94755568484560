// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Paragraph } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type AccountRemovalTextProps = {
    email: string;
    isOwner: boolean;
    teamMembersCount: number;
};
export const AccountRemovalText = ({
    email,
    isOwner,
    teamMembersCount,
}: AccountRemovalTextProps) => {
    const translate = useTranslate('accounts.profile.AccountRemoval');
    const removeAccountText =
        isOwner && teamMembersCount > 1
            ? translate('removeAccountWithMembers', {
                  email,
                  teamMembersCount: teamMembersCount - 1,
              })
            : translate('removeAccount', {
                  email,
              });
    return <Paragraph>{removeAccountText}</Paragraph>;
};
