// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';
import { signTermsOfUse } from '@platform360/auth/web/api/auth';

const useSignTermsOfUseMutation = (onSuccess: (continueUrl: string) => void) => {
    const translateValidationErrors = useTranslateValidationErrors();
    const { error, ...rest } = useMutation({ mutationFn: signTermsOfUse, onSuccess });

    return { errors: translateValidationErrors(getValidationErrors(error)), error, ...rest };
};

export default useSignTermsOfUseMutation;
