// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'In Zwischenablage kopiert.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Sie haben die Identität eines anderen Kontos übernommen.',
            depersonateButton: 'Identitätsübernahme beenden',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Sie übernehmen nicht mehr die Identität eines anderen Kontos.',
        },
        UserMenu: {
            team: 'Unternehmen',
            logout: 'Abmelden',
            changePassword: 'Passwort ändern',
            businessProfile: 'Unternehmensprofil',
            apiTokens: 'API-Tokens',
        },
    },

    common: {
        errors: {
            isNotEmpty: 'Dieses erforderliche Feld ist leer.',
            required: 'Dieses erforderliche Feld ist leer.',
            email: 'Der Wert muss eine gültige E-Mail-Adresse sein.',
            isEmail: 'Der Wert muss eine gültige E-Mail-Adresse sein.',
            min: '{min, plural, one{Der Wert muss mindestens # Zeichen enthalten.} other{Der Wert muss mindestens # Zeichen enthalten.}}',
            maxLength: '{max, plural, one{Der Wert darf höchstens # Zeichen enthalten.} other{Der Wert darf höchstens # Zeichen enthalten.}}',
            unique: 'Der Wert muss eindeutig sein.',
            unknown: 'Unbekannter Fehler: {message}',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Bitte geben Sie ein Passwort ein.',
            errorPasswordIsWrong: 'Falsches Passwort.',
            errorPasswordIsTooWeak: 'Das Passwort ist zu schwach.',
            errorPasswordContainsPersonalInfo: 'Das Passwort darf keine persönlichen Informationen enthalten.',
            errorPasswordTooCommon: 'Das Passwort ist zu gewöhnlich.',
            errorPasswordPreviouslyUsed: 'Das Passwort wurde zuvor schon verwendet.',
            errorInvalidOtp: 'Der eingegebene Code ist nicht gültig.',
        },
    },

    ConfirmationPopover: {
        cancel: 'Abbrechen',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'WebPros Account nutzt ZendeskChat, eine Drittanbieteranwendung.',
            description2: 'Indem ich fortfahre, erkläre ich mich damit einverstanden, die Drittanbieteranwendung ZendeskChat zu nutzen. Dabei können meine personenbezogenen Daten (z. B. IP-Adresse) an Dritte innerhalb oder außerhalb Europas übertragen werden. Weitere Informationen finden Sie in unserer <a>Datenschutzrichtlinie</a>.',
            confirmButton: 'ZendeskChat starten',
        },
        supportFormPopover: {
            description: 'Derzeit sind keine Kundenservicemitarbeiter verfügbar. Bitte warten Sie oder verwenden Sie unser Ticketformular, um eine Anfrage zu stellen.',
            confirmButton: 'Formular öffnen',
        },
        ChatButton: {
            title: 'Support',
        },
    },

    emailRequired: "To log in to WebPros using your Facebook account, access to view the email address must be granted. Make sure that the corresponding permission is granted and try again.",
    userBlocked: "Das Konto wurde wegen eines Verstoßes gegen die Nutzungsvereinbarung gesperrt. Wenn Sie glauben, dass dies ein Fehler ist, schreiben Sie bitte an {emailLink}.",
    invalidDomain: 'Zugriff verweigert. Die Benutzer-E-Mail-Adresse ist nicht auf der Zulassungsliste.',

    errorDefault: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
    errorDefaultWithReqId: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal. Anfrage-ID: {requestId}.',
    errorTooManyRequests: 'Limit für API-Aufrufe wurde für diese IP-Adresse erreicht. Bitte versuchen Sie es in Kürze erneut.',
    errorTokenRenewal: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
    errorNetworkIssue: 'Sie scheinen derzeit Probleme mit der Verbindung zum Netzwerk zu haben.',

    errorCopyToClipboard: 'Fehler beim Kopieren. Versuchen Sie, die Elemente manuell zu kopieren.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH verarbeitet die von Ihnen zur Verfügung gestellten Daten, um Ihre Anfrage in Übereinstimmung mit der <a>Datenschutzrichtlinie</a> zu bearbeiten.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'Der Benutzer ist nicht vorhanden.',
            bounceOrComplaint: 'Diese E-Mail-Adresse ist nicht vorhanden oder unsere Nachricht wurde als Spam eingestuft. <webProsContactLink>Bitte wenden Sie sich an das WebPros Account-Supportteam.</webProsContactLink>',
            userIsNotVerified: 'Der Benutzer ist vorhanden, wurde jedoch noch nicht bestätigt. Wir haben Ihnen erneut eine E-Mail zur Bestätigung gesendet. Bitte überprüfen Sie Ihren Posteingang.',
            userExist: 'Der Benutzer ist bereits vorhanden.',
            errorPasswordIsEmpty: 'Es muss ein Passwort angegeben werden.',
            errorPasswordIsWrong: 'Falsches Passwort.',
            errorPasswordIsTooWeak: 'Das Passwort ist zu schwach.',
        },
        AuthContainer: {
            alreadyHaveAnAccount: 'Sie haben bereits ein Konto?',
            loginLink: 'Anmelden',
        },
        SignUp: {
            signUp: 'Konto erstellen',
            emailLabel: 'E-Mail-Adresse',
            passwordLabel: 'Passwort',
            privacyPolicyAgreementTitle: 'Ich stimme den <termsLink>Nutzungsbedingungen</termsLink> zu und bin damit einverstanden, dass WebPros die von mir zur Verfügung gestellten personenbezogenen Daten in seinen internen Systemen gemäß der <privacyLink>Datenschutzrichtlinie</privacyLink> von WebPros verarbeitet.',
            SignUpByInvitation: {
                emailDescription: 'Sie werden mit der oben genannten E-Mail angemeldet',
                invitationSucceed: 'Sie haben sich erfolgreich registriert. Bitte melden Sie sich jetzt an.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Vorname',
                    lastNamePlaceholder: 'Nachname',
                    nameLabel: 'Name',
                },
            },
        },
        ResetPasswordDrawer: {
            title: 'Passwort zurücksetzen',
            text: 'Geben Sie Ihre E-Mail-Adresse ein. Sie erhalten dann eine E-Mail zum Zurücksetzen des Passworts.',
            emailLabel: 'E-Mail-Adresse:',
            submitButton: 'Senden',
            successTitle: 'E-Mail zum Zurücksetzen des Passworts gesendet',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Erneut senden',
            loginForm: 'Jetzt anmelden',
            retryCountdown: 'Kann erneut gesendet werden in {time}',
        },
    },
    Footer: {
        copyright: '© {year} WebPros International GmbH. Alle Rechte vorbehalten.',
        company: 'Unternehmen',
        knowledgeBase: 'Knowledge Base',
        aboutWebPros: 'Über WebPros',
        legal: 'Rechtliches',
        cookieSettings: 'Cookie-Einstellungen',
        privacyPolicy: 'Datenschutzrichtlinie',
        documentation: 'Dokumentation',
        contactUs: 'Kontakt',

    },
    Form: {
        requiredLegend: 'Erforderliche Felder',
        submitButton: 'OK',
        cancelButton: 'Abbrechen',
    },

    FormField: {
      addMore: 'Weitere hinzufügen',
      remove: 'Entfernen',
      clear: 'Löschen',
    },

    FormFieldPassword: {
        improvePassword: 'Tipps zum Festlegen von sicheren Passwörtern:',
        passwordTooShort: 'Wählen Sie ein längeres Passwort aus.',
        lettersLowerCase: 'Verwenden Sie mindestens einen Kleinbuchstaben.',
        lettersUpperCase: 'Verwenden Sie mindestens einen Großbuchstaben.',
        numbers1: 'Verwenden Sie mindestens eine Ziffer.',
        numbers3: 'Verwenden Sie mindestens drei Ziffern.',
        specialChar1: 'Verwenden Sie mindestens ein Sonderzeichen (zum Beispiel: !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Verwenden Sie mindestens zwei Sonderzeichen (zum Beispiel: !, @, #, $, %, ^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Verwenden Sie sowohl Groß- als auch Kleinbuchstaben.',
        comboLettersAndNumbers: 'Verwenden Sie sowohl Ziffern als auch Buchstaben.',
        comboLettersNumbersSpecial: 'Verwenden Sie Ziffern, Buchstaben und Sonderzeichen.',
        yourPasswordIsStrong: 'Längere Passwörter sind noch sicherer.',
        passwordStrength: 'Die Passwortsicherheit ist %%strength%%.',
        strengthVeryStrong: 'SEHR STARK',
        strengthStrong: 'STARK',
        strengthVeryWeak: 'SEHR SCHWACH',
        strengthWeak: 'SCHWACH',
        strengthMedium: 'MITTEL',
        hidePassword: 'Passwort ausblenden',
        showPassword: 'Passwort anzeigen',
        generateButton: 'Generieren',
        generateButtonHint: 'Starkes Passwort generieren',
    },

    Pagination: {
        totalShort: 'von %%total%%',
        itemsPerPage: 'Elemente pro Seite',
        all: 'Alle',
        total: 'Seite %%current%% von %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Keine mit dem Filter übereinstimmende Elemente gefunden',
        emptyTitle: 'Keine Elemente vorhanden',
        filteredDescription: 'Passen Sie Ihre Anfrage an oder entfernen Sie einige der Filterkriterien und versuchen Sie es erneut.',
    },

    Dialog: {
        cancelButton: 'Abbrechen',
    },

    GaOptOutLink: {
        gaSuccess: 'Tracking über Google Analytics wurde angehalten.',
    },

    InPlaceText: {
        ok: 'Speichern (Strg + Eingabetaste)',
        cancel: 'Abbrechen (Esc)',
        edit: 'Bearbeiten',
        add: 'Hinzufügen',
    },

    Team: {
        Layout: {
            title: 'Unternehmen',
            members: 'Mitglieder',
            invitations: 'Einladungen',
        },
        settings: 'Einstellungen',
        Members: {
            email: 'E-Mail-Adresse',
            fullName: 'Vollständiger Name',
            mfaStatus: 'MFA-Status',
            tags: 'Besondere Markierungen',
            remove: 'Entfernen',
            removeMemberConfirmation: '<b>{name}</b> wird nicht länger Zugriff auf die Organisation und die gemeinsam genutzten Ressourcen, Server, Websites und Lizenzen haben.',
            SpecialMarks: {
                you: 'Sie',
                owner: 'Inhaber',
            },
            Onboarding: {
                title: 'Fügen Sie Ihr Team hinzu, um Ihre Organisation auf unserer Plattform zu erstellen',
                description: 'In unserer Plattform finden Sie Funktionen zur Zusammenarbeit und zum effizienten Verwalten von Organisationen. Mitglieder der jeweiligen Organisationen haben direkt Zugriff auf verknüpfte Server. Gemeinsam genutzte Passwörter und komplizierte Zugriffsregelungen gehören damit endgültig der Vergangenheit an.',
                addMembersButton: 'Mitglieder der Organisation hinzufügen',
            },
            MultifactorStatus: {
                enabled: 'MFA aktiviert',
                disabled: 'MFA nicht aktiviert',
            },
        },
        Invitations: {
            email: 'E-Mail-Adresse',
            expireAt: 'Läuft ab am',
            expired: 'Abgelaufen',
            add: 'Hinzufügen',
            revoke: 'Widerrufen',
            Onboarding: {
                title: 'Hier können Sie die Mitglieder Ihrer Organisation einladen',
                description: 'Fügen Sie Ihre Kollegen hinzu, um Server und Websites für sie freizugeben.',
                addButton: 'Neues Mitglied einladen',
            },
            ListActions: {
                resendInvitationTooltip: 'Einladung erneut senden.',
                successMessage: 'Einladungs-E-Mail wurde erneut gesendet.',
            },
            AddInvitations: {
                title: 'Neue Mitglieder zur Organisation hinzufügen',
                infoMessage: 'Laden Sie die Mitglieder Ihrer Organisation ein, indem Sie nachstehend deren E-Mail-Adressen eingeben.',
                infoDescription: 'Berufliche E-Mail-Adressen werden persönlichen E-Mail-Adressen vorgezogen, um die persönlichen Daten der Benutzer zu schützen.',
                emailsLabel: 'E-Mail-Adressen für Einladungslink',
                submitButton: 'Einladung senden',
                successMessage: '{count, plural, =1{Einladung an {email} wurde gesendet.} one{# Einladung wurde gesendet.} other{# Einladungen wurden gesendet.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Widerrufen',
                noSelectedInvitations: 'Wählen Sie die Einladungen aus, die widerrufen werden sollen.',
                revokeInvitationConfirmation: '{count, plural, =1{Einladung an <b>{email}</b> widerrufen?} one{<b>#</b> Einladung widerrufen?} other{<b>#</b> Einladungen widerrufen?}} Dies kann nicht rückgängig gemacht werden.',
                successRevokeInvitation: '{count, plural, =1{Die Einladung an <b>{email}</b> wurde widerrufen.} one{<b>#</b> Einladung wurde widerrufen.} other{<b>#</b> Einladungen wurden widerrufen.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'WebPros darf mir Newsletter und individuelle Angebote gemäß der <a>Datenschutzrichtlinie</a> von WebPros senden.',
            text: 'Ich kann diese Zustimmung widerrufen, indem ich auf den Link in einer erhaltenen Mitteilung klicke',
        },
    },

    components: {
        List: {
            selectAll: 'Alle auswählen',
            deselectAll: 'Auswahl aufheben',
            selectedRows: '{selectedRows, plural, one{# von {totalRows, plural, one{# Element} other{# Elementen}} ausgewählt} other{# von {totalRows, plural, one{# Element} other{# Elementen}} ausgewählt}}',
            totalRows: '{totalRows, plural, one{# Element insgesamt} other{# Elemente insgesamt}}',
            foundRows: '{foundRows, plural, one{# Element gefunden} other{# Elemente gefunden}}',
        },
        DataList: {
            groupSearch: 'Suchen',
        },
    },
};