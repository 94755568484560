// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { confirmEmail } from '@platform360/accounts/web/api/me';
import { UntypedAxiosError } from '@platform360/libs/shared-web/typings/api';

type UseUpdateEmailMutationOptions = {
    onSuccess?: (newEmail: string) => void;
    onError?: (error: UntypedAxiosError) => void;
};

export const useConfirmEmailMutation = (options: UseUpdateEmailMutationOptions = {}) =>
    useMutation({ ...options, mutationFn: confirmEmail });
