// Copyright 2024. WebPros International GmbH. All rights reserved.

import { TranslationProps } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import consents from '@platform360/libs/common/consents';
import { AGREEMENT_TYPE_MARKETING } from '@platform360/libs/common/agreements';

export type ConsentItem = {
    type: string;
    title: TranslationProps;
    text: TranslationProps;

    /** Text without HTML tags, suitable for sending to consent keeper. */
    consentKeeperText: string;
};

const useConsentItems = (baseUrl = ''): ConsentItem[] => {
    const translate = useTranslate('consents');

    return consents.map(({ type, titleLocale, textLocale }) => {
        switch (type) {
            case AGREEMENT_TYPE_MARKETING:
            default: {
                const text = translate(textLocale);

                // Construct consentKeeperText without HTML tags.
                const consentKeeperText = [
                    translate(titleLocale, {
                        a: (chunk) => chunk,
                    }),
                    text,
                ].join('\n');

                return {
                    type,
                    title: translate(titleLocale, {
                        a: (chunk) => (
                            <a
                                href={`${baseUrl}/legal/privacy-policy`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {chunk}
                            </a>
                        ),
                    }),
                    text,
                    consentKeeperText,
                };
            }
        }
    });
};

export default useConsentItems;
