// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'Das Auth-Token fehlt oder ist ungültig.',
        invitationNotExist: 'Einladung existiert nicht.',
        invitationExpired: 'Die Einladung ist abgelaufen.',
        invitationConflict: 'Eingeladener Benutzer ist bereits registriert.',
    },
    SignTermsOfUse: {
        title: 'Datenschutzrichtlinien',
        descriptionText1: 'Um ein WebPros-Konto zu erstellen, müssen Sie zunächst den WebPros Nutzungsbedingungen und Datenschutzrichtlinien zustimmen.',
        descriptionText2: 'Sie können auch unseren Newsletter abonnieren, um Updates zu unseren Produkten und individuelle Angebote zu erhalten.',
        privacyPolicyAgreementTitle: 'Ich stimme den <termsLink>Nutzungsbedingungen</termsLink>. Ich habe zur Kenntnis genommen, dass WebPros meine personenbezogenen Daten gemäß der <privacyLink>Datenschutzrichtlinie</privacyLink> von WebPros verarbeitet',
        continueBtn: 'Weiter',
        errors: {
            invalidToken: 'Ihre Sitzung ist abgelaufen. Bitte <a>melden Sie sich erneut an</a>, um fortzufahren.',
        },
    },
    ChangePassword: {
        successText: 'Ihr Passwort wurde geändert. Klicken Sie auf die Schaltfläche unten, um sich anzumelden.',
        loginBtn: 'Anmelden',
    },
    ChangePasswordForm: {
        setTitle: 'Passwort festlegen',
        passwordLabel: 'Neues Passwort',
        submitButton: 'Bestätigen',
        description: 'Geben Sie Ihr neues Passwort ein und bestätigen Sie es. Es muss mindestens aus 8 Zeichen bestehen.',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'E-Mail zum Zurücksetzen des Passworts gesendet',
        successSendResetText: 'Wir haben eine E-Mail an {email} mit einem Link zum Zurücksetzen Ihres Passworts gesendet.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Abgelaufener Link',
        resetText: 'Der Link zum Zurücksetzen des Passworts ist abgelaufen.',
        setText: 'Der Link zum Einrichten des Passworts ist nicht mehr gültig. Aus Sicherheitsgründen ist der Link nur 4 Stunden lang gültig. Falls Sie in diesem Zeitraum kein neues Passwort festgelegt haben, geben Sie Ihre E-Mail-Adresse unten erneut ein und legen Sie das Passwort über die Vorgehensweise zur Passwortänderung fest.',
        emailLabel: 'E-Mail-Adresse:',
        submitButton: 'Senden',
        bottomText: 'Sie erinnern sich an das Passwort? <a>Melden Sie sich jetzt an.</a>',
    },
    LandingPage: {
        signUpButton: 'WebPros-Konto erstellen',
        signInButton: 'Anmelden',
        title: 'Das Webhosting-Ökosystem von WebPros',
        description: 'Mit Ihrem WebPros-Konto haben Sie Zugriff auf alle Ihre WebPros-Produkte und -Dienste. Melden Sie sich an, um Ihr Konto zu verwalten.',
    },
    ResetPassword: {
        emailLabel: 'E-Mail-Adresse',
        backBtn: 'Zurück',
        nextBtn: 'Weiter',
        description: 'Geben Sie Ihre E-Mail-Adresse ein. Sie erhalten dann eine E-Mail mit dem Link zum Zurücksetzen des Passworts.',
        successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
        successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
        resendButton: 'Erneut senden',
        retryCountdown: 'E-Mail erneut senden in {time}',
    },
};