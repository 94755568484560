// Copyright 2024. WebPros International GmbH. All rights reserved.

import { RouteObject } from 'react-router-dom';
import Callback from '@platform360/auth/web/components/Callback';
import Logout from '@platform360/auth/web/components/Logout';
import ProxyLogout from '@platform360/auth/web/components/ProxyLogout';
import LogoutCallback from '@platform360/auth/web/components/LogoutCallback';
import EmailVerified from '@platform360/auth/web/components/EmailVerified';
import ChangePassword from '@platform360/auth/web/components/ChangePassword';
import SignUp from '@platform360/auth/web/components/SignUpContainer';
import ResetPassword from '@platform360/auth/web/components/ResetPassword';
import SignTermsOfUse from '@platform360/auth/web/components/SignTermsOfUse';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

const LoginPage = () => {
    const { loginPage } = useApplicationSettings();

    return loginPage;
};

export const routes: RouteObject[] = [
    {
        path: 'auth',
        children: [
            {
                path: 'callback',
                element: <Callback />,
            },
            {
                path: 'logout',
                element: <Logout />,
            },
            {
                path: 'proxy-logout',
                element: <ProxyLogout />,
            },
            {
                path: 'logout-callback',
                element: <LogoutCallback />,
            },
            {
                path: 'emailVerified',
                element: <EmailVerified />,
            },
            {
                path: 'resetPassword',
                element: <ChangePassword />,
            },
            {
                path: 'setPassword',
                element: <ChangePassword useCase="set" />,
            },
            {
                path: 'login/reset-password',
                element: <ResetPassword />,
            },
            {
                path: 'login',
                element: <LoginPage />,
            },
            {
                path: 'sign-terms-of-use',
                element: <SignTermsOfUse />,
            },
            {
                path: '*',
                element: <SignUp />,
            },
        ],
    },
];
