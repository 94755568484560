// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export type ChangeableEmailProps = {
    email: string;
    onEdit: () => void;
};

export const ChangeableEmail = ({ email, onEdit }: ChangeableEmailProps) => {
    const translate = useTranslate('accounts.profile.ChangeableEmail');

    return (
        <>
            {email}
            <Button
                icon="pencil"
                data-type="edit-email-button"
                onClick={() => onEdit()}
                title={translate('buttonEdit')}
                ghost
            />
        </>
    );
};
