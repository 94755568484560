// Copyright 2024. WebPros International GmbH. All rights reserved.

import SignUpByInvitation from './SignUpByInvitation';
import { OperationStatus } from '@platform360/auth/web/components/useOperationStatus';

export const getInvitationEmailFromLocation = ({ hash }: Location): string | undefined =>
    hash === '' ? undefined : hash.slice(1);

type SignUpByInvitationContainerProps = {
    setOperationStatus: (s: OperationStatus | null) => void;
};

const SignUpByInvitationContainer = ({ setOperationStatus }: SignUpByInvitationContainerProps) => {
    const invitationEmail = getInvitationEmailFromLocation(window.location);

    if (!invitationEmail) {
        return null;
    }

    return <SignUpByInvitation email={invitationEmail} setOperationStatus={setOperationStatus} />;
};

export default SignUpByInvitationContainer;
