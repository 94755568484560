// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ApplicationSettings } from '@platform360/libs/shared-web/application-settings/context';
import NotFoundPage from '@platform360/admin-panel/web/components/NotFound';
import { Login } from '@platform360/admin-panel/web/components/Login';
import { Navigate } from 'react-router-dom';
import WebprosLogo from '@platform360/libs/shared-web/components/WebprosLogo';
import { cookieModify, cookieWithdraw } from '@platform360/app/web/components/CookieManager';

const adminPanelApplicationSettings: ApplicationSettings = {
    document: {
        title: 'WebPros Account Admin Panel',
        favicon: '/global-sso.ico',
        darkThemeDataAttribute: 'legacy-dark',
    },
    logo: <WebprosLogo />,
    homePage: <Navigate to="/admin-panel" />,
    notFoundPage: <NotFoundPage />,
    header: {
        baseUrl: '/admin-panel',
        userMenu: {
            businessProfile: true,
            changePassword: true,
            apiTokens: false,
            organization: true,
        },
    },
    loginPage: <Login />,
    cookieManager: {
        cookieModify,
        cookieWithdraw,
    },
};

export default adminPanelApplicationSettings;
