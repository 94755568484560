// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { Consent } from '@platform360/libs/common/consents';
import { ApiResponse } from '@platform360/libs/shared-web/typings/api';

export type SignTermsOfUseRequest = {
    privacyPolicyAgreement: true;
    consents: Consent[];
};

export type SignTermsOfUseResponse = {
    // Contains a URL to continue interrupted login flow.
    // @see https://auth0.com/docs/customize/actions/flows-and-triggers/login-flow/redirect-with-actions#resume-the-authentication-flow
    continueUrl: string;
};

export const signTermsOfUse = async (request: SignTermsOfUseRequest) => {
    const {
        data: {
            data: { continueUrl },
        },
    } = await apiClient.post<ApiResponse<SignTermsOfUseResponse>>(
        '/auth/sign-terms-of-use',
        request,
    );

    return continueUrl;
};

export const getConsents = async () => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<Consent[]>>('/auth/consents');

    return data;
};
