// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { useGetModalUrl } from '@platform360/libs/shared-web/helpers/useModal';
import { Link } from 'react-router-dom';
import { useAccountRemoval } from '@platform360/accounts/web/profile/AccountRemoval';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { Section, SectionItem, Grid, GridCol, Action, Button, Text } from '@plesk/ui-library';
import { ChangeableEmail } from '@platform360/accounts/web/profile/ChangeableEmail';
import ChangeableName from '@platform360/accounts/web/profile/ChangeableName/ChangeableNameContainer';
import { Timezone } from '@platform360/accounts/web/profile/Timezone';
import MfaControl from '@platform360/accounts/web/profile/MfaControl';
import { DpaManager } from '@platform360/accounts/web/profile/DpaManager';
import ConsentManager from '@platform360/accounts/web/profile/ConsentManager/ConsentManagerContainer';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import styles from './Profile.module.css';
import { concatUrl } from '@platform360/libs/common/concat-url';
import { useCallback } from 'react';
import { Connections } from '@platform360/libs/common/connection';
import IconGravatar from '@platform360/libs/shared-web/components/UserMenu/IconGravatar';

const GOOGLE_ACCOUNT_BASE_URL = 'https://myaccount.google.com/';
const FACEBOOK_ACCOUNT_CENTER_BASE_URL = 'https://accountscenter.facebook.com/';

type AuthProviderConditionsParams<Result extends unknown> = {
    default: Result;
    plesk360: Result;
    cpanelStore: Result;
    google: Result;
    facebook: Result;
};

const useAuthProviderConditions = () => {
    const { session } = useAuth();
    return useCallback(
        <Result extends unknown>(params: AuthProviderConditionsParams<Result>) => {
            switch (session?.connection) {
                case Connections.platform360:
                    return params.plesk360;
                case Connections.cpanelid:
                    return params.cpanelStore;
                case Connections.googleOauth2:
                    return params.google;
                case Connections.facebook:
                    return params.facebook;
                default:
                    return params.default;
            }
        },
        [session],
    );
};

export const Profile = () => {
    const translate = useTranslate('accounts.HomePage.Profile');

    const { isOwner, name, email, picture } = useCurrentUser();
    const isShowDpa = isOwner;
    const authProviderConditions = useAuthProviderConditions();
    const getModalUrl = useGetModalUrl();
    const changePasswordUrl = getModalUrl('change-password');
    const { showAccountRemoval, accountRemoval } = useAccountRemoval();
    const {
        platform: { baseUrl: platformBaseUrl },
        cpanelStore: { baseUrl: cpanelBaseUrl },
    } = useConfig();

    return (
        <div className={styles.container}>
            <Grid minColumnWidth={600} maxColumnsNumber={2} gap="md">
                <GridCol>
                    <Section title={translate('personalInfoSection')}>
                        <SectionItem title={translate('nameField')}>
                            {authProviderConditions({
                                plesk360: (
                                    <>
                                        <div>{name}</div>
                                        <Text fontSize="sm" intent="muted">
                                            {translate('plesk360ConfigureName', {
                                                link: (chunk) => (
                                                    <a
                                                        href={concatUrl(
                                                            '/?modals%5Bprofile%5D=true',
                                                            platformBaseUrl,
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {chunk}
                                                    </a>
                                                ),
                                            })}
                                        </Text>
                                    </>
                                ),
                                cpanelStore: (
                                    <>
                                        <div>{name}</div>
                                        <Text fontSize="sm" intent="muted">
                                            {translate('cpanelStoreConfigureName', {
                                                link: (chunk) => (
                                                    <a
                                                        href={concatUrl(
                                                            '/user/profile',
                                                            cpanelBaseUrl,
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {chunk}
                                                    </a>
                                                ),
                                            })}
                                        </Text>
                                    </>
                                ),
                                google: (
                                    <>
                                        <div>{name}</div>
                                        <Text fontSize="sm" intent="muted">
                                            {translate('googleConfigureName', {
                                                link: (chunk) => (
                                                    <a
                                                        href={concatUrl(
                                                            'profile/name',
                                                            GOOGLE_ACCOUNT_BASE_URL,
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {chunk}
                                                    </a>
                                                ),
                                            })}
                                        </Text>
                                    </>
                                ),
                                facebook: (
                                    <>
                                        <div>{name}</div>
                                        <Text fontSize="sm" intent="muted">
                                            {translate('facebookConfigureName', {
                                                link: (chunk) => (
                                                    <a
                                                        href={concatUrl(
                                                            'profiles',
                                                            FACEBOOK_ACCOUNT_CENTER_BASE_URL,
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {chunk}
                                                    </a>
                                                ),
                                            })}
                                        </Text>
                                    </>
                                ),
                                default: <ChangeableName />,
                            })}
                        </SectionItem>
                        <SectionItem title={translate('profilePictureSection')}>
                            <IconGravatar
                                className={styles.gravatar}
                                userProfile={{
                                    email,
                                    picture,
                                }}
                            />
                            <Text fontSize="sm" intent="muted">
                                {translate('changeGravatar', {
                                    link: (chunk) => (
                                        <a
                                            href="https://gravatar.com"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {chunk}
                                        </a>
                                    ),
                                })}
                            </Text>
                        </SectionItem>
                        <SectionItem title={translate('emailAddressField')}>
                            {authProviderConditions({
                                plesk360: (
                                    <>
                                        <div>{email}</div>
                                        <Text fontSize="sm" intent="muted">
                                            {translate('plesk360ConfigureEmail', {
                                                link: (chunk) => (
                                                    <a
                                                        href={concatUrl(
                                                            '/?modals%5Bprofile%5D=true',
                                                            platformBaseUrl,
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {chunk}
                                                    </a>
                                                ),
                                            })}
                                        </Text>
                                    </>
                                ),
                                cpanelStore: (
                                    <>
                                        <div>{email}</div>
                                        <Text fontSize="sm" intent="muted">
                                            {translate('cpanelStoreConfigureEmail', {
                                                link: (chunk) => (
                                                    <a
                                                        href={concatUrl(
                                                            '/user/profile',
                                                            cpanelBaseUrl,
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {chunk}
                                                    </a>
                                                ),
                                            })}
                                        </Text>
                                    </>
                                ),
                                google: (
                                    <>
                                        <div>{email}</div>
                                        <Text fontSize="sm" intent="muted">
                                            {translate('googleConfigureEmail', {
                                                link: (chunk) => (
                                                    <a
                                                        href={concatUrl(
                                                            '/email',
                                                            GOOGLE_ACCOUNT_BASE_URL,
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {chunk}
                                                    </a>
                                                ),
                                            })}
                                        </Text>
                                    </>
                                ),
                                facebook: (
                                    <>
                                        <div>{email}</div>
                                        <Text fontSize="sm" intent="muted">
                                            {translate('facebookConfigureEmail', {
                                                link: (chunk) => (
                                                    <a
                                                        href={concatUrl(
                                                            '/personal_info/contact_points',
                                                            FACEBOOK_ACCOUNT_CENTER_BASE_URL,
                                                        )}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {chunk}
                                                    </a>
                                                ),
                                            })}
                                        </Text>
                                    </>
                                ),
                                default: <ChangeableEmail />,
                            })}
                        </SectionItem>
                        <SectionItem title={translate('passwordField')}>
                            {authProviderConditions({
                                plesk360: (
                                    <Text fontSize="sm" intent="muted">
                                        {translate('plesk360ConfigurePassword', {
                                            link: (chunk) => (
                                                <a
                                                    href={concatUrl(
                                                        '/?modals%5Bprofile%5D=true',
                                                        platformBaseUrl,
                                                    )}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {chunk}
                                                </a>
                                            ),
                                        })}
                                    </Text>
                                ),
                                cpanelStore: (
                                    <Text fontSize="sm" intent="muted">
                                        {translate('cpanelStoreConfigurePassword', {
                                            link: (chunk) => (
                                                <a
                                                    href={concatUrl(
                                                        '/user/password',
                                                        cpanelBaseUrl,
                                                    )}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {chunk}
                                                </a>
                                            ),
                                        })}
                                    </Text>
                                ),
                                google: (
                                    <Text fontSize="sm" intent="muted">
                                        {translate('googleConfigurePassword', {
                                            link: (chunk) => (
                                                <a
                                                    href={concatUrl(
                                                        '/signinoptions/password',
                                                        GOOGLE_ACCOUNT_BASE_URL,
                                                    )}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {chunk}
                                                </a>
                                            ),
                                        })}
                                    </Text>
                                ),
                                facebook: (
                                    <Text fontSize="sm" intent="muted">
                                        {translate('facebookConfigurePassword', {
                                            link: (chunk) => (
                                                <a
                                                    href={concatUrl(
                                                        '/password_and_security',
                                                        FACEBOOK_ACCOUNT_CENTER_BASE_URL,
                                                    )}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {chunk}
                                                </a>
                                            ),
                                        })}
                                    </Text>
                                ),
                                default: (
                                    <Action component={Link} to={changePasswordUrl}>
                                        {translate('changePasswordLink')}
                                    </Action>
                                ),
                            })}
                        </SectionItem>
                        <SectionItem title={translate('timezoneField')}>
                            <Timezone />
                        </SectionItem>
                    </Section>
                    {authProviderConditions({
                        plesk360: undefined,
                        cpanelStore: undefined,
                        google: undefined,
                        facebook: undefined,
                        default: (
                            <Section title={translate('securitySection')}>
                                <SectionItem
                                    title={translate('mfaField')}
                                    data-type="mfa-address-field"
                                >
                                    <MfaControl />
                                </SectionItem>
                            </Section>
                        ),
                    })}
                </GridCol>
                <GridCol>
                    <Section title={translate('agreementsSection')}>
                        {isShowDpa ? <DpaManager /> : undefined}
                        <ConsentManager />
                    </Section>
                </GridCol>
            </Grid>
            <Button
                ghost
                icon="recycle"
                onClick={showAccountRemoval}
                className={styles.removeButton}
            >
                {translate('removeAccountButton')}
            </Button>
            {accountRemoval}
        </div>
    );
};
