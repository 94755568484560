// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ContentLoader } from '@plesk/ui-library';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { useNavigate } from 'react-router-dom';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

const LogoutCallback = () => {
    const { processLogout } = useAuth();
    const navigate = useNavigate();

    useEffectOnce(() => {
        processLogout();
        navigate('/');
    });

    return <ContentLoader />;
};

export default LogoutCallback;
