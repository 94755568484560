// Copyright 2024. WebPros International GmbH. All rights reserved.

const JWT_PREFIX = 'https://account.webpros.com/';

// Custom jwt claims that are added to the token by the auth0 action(add-properties-to-access-token).
export type CustomClaims = {
    /* eslint-disable @typescript-eslint/naming-convention */
    'https://account.webpros.com/email': string;
    'https://account.webpros.com/teamId'?: string;
    'https://account.webpros.com/isMfaEnabled': boolean;
    'https://account.webpros.com/connection'?: string;
    /* eslint-enable @typescript-eslint/naming-convention */
};

type JwtNamespace = typeof JWT_PREFIX;

// Extract the possible keys without the prefix.
type ClaimKeys = keyof {
    [K in keyof CustomClaims as K extends `${JwtNamespace}${infer R}` ? R : never]: CustomClaims[K];
};

/**
 * Get the full claim name with the prefix.
 */
export const getCustomClaimName = <T extends ClaimKeys>(claim: T): `${JwtNamespace}${T}` =>
    `${JWT_PREFIX}${claim}`;

/**
 * Get the custom claim value from the payload.
 */
export const getCustomClaim = <T extends ClaimKeys>(
    payload: CustomClaims,
    claim: T,
): CustomClaims[`${JwtNamespace}${T}`] => payload[getCustomClaimName(claim)];
