// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DpaManager } from './DpaManager';
import { useGetConsentsQuery } from '@platform360/accounts/web/queries';
import { AGREEMENT_TYPE_DPA } from '@platform360/libs/common/agreements';
import { useState } from 'react';

export const DpaManagerContainer = () => {
    const [isEditing, setIsEditing] = useState(false);
    // DpaManager uses the same query as ConsentManager which shows loading and error states.
    const { data } = useGetConsentsQuery({
        meta: {
            defaultErrorHandler: false,
        },
    });
    const isAccepted =
        data?.find(({ type, enabled }) => type === AGREEMENT_TYPE_DPA && enabled) !== undefined;
    const handleStartEdit = () => {
        setIsEditing(true);
    };
    const handleStopEdit = () => {
        setIsEditing(false);
    };

    if (!data) {
        return null;
    }

    return (
        <DpaManager
            isAccepted={isAccepted}
            isEditing={isEditing}
            onStartEdit={handleStartEdit}
            onStopEdit={handleStopEdit}
        />
    );
};
