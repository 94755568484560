// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import Loading from '@platform360/libs/shared-web/components/Loading';
import ChangePasswordForm from './ChangePasswordForm';
import ChangePasswordInvalidToken from './ChangePasswordInvalidToken';
import { useResetPassword, useChangePassword } from '@platform360/auth/web/mutations';
import { fetchTokenStatus, logSetPassword } from '@platform360/auth/web/api/passwords';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { SetPasswordUseCase } from '@platform360/auth/web/types';
import AuthLayout from '@platform360/libs/shared-web/components/AuthLayout';
import Header from '@platform360/libs/shared-web/components/Header';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';
import { Button } from '@plesk/ui-library';
import styles from './ChangePassword.module.css';

enum TokenStatus {
    None = 0,
    Valid = 1,
    Invalid = 2,
}

type State = {
    email?: string;
    tokenStatus: TokenStatus;
};

type ChangePasswordProps = {
    useCase?: SetPasswordUseCase;
};

export const ChangePassword = ({ useCase = 'reset' }: ChangePasswordProps) => {
    const translate = useTranslate('auth.ChangePassword');
    const { login } = useAuth();
    const [isComplete, setIsComplete] = useState(false);
    const [resetFinished, setResetFinished] = useState(false);
    const [state, setState] = useState<State>({
        tokenStatus: TokenStatus.None,
    });

    const {
        mutate: resetPassword,
        isPending: isLoading,
        errors,
    } = useResetPassword(() => {
        setIsComplete(true);
    });

    const {
        mutate: changePassword,
        isPending: isPasswordChanging,
        errors: changePasswordErrors,
        isSuccess,
    } = useChangePassword(() => {
        setResetFinished(true);
    });

    useEffectOnce(() => {
        const fetch = async () => {
            try {
                const { email } = await fetchTokenStatus();

                setState({
                    email,
                    tokenStatus: TokenStatus.Valid,
                });

                if (useCase === 'set') {
                    void logSetPassword(false);
                }
            } catch {
                setState({ tokenStatus: TokenStatus.Invalid });

                if (useCase === 'set') {
                    void logSetPassword(true);
                }
            }
        };

        void fetch();
    });

    if (state.tokenStatus === TokenStatus.None) {
        return <Loading />;
    }

    const renderContent = () => {
        if (resetFinished) {
            return (
                <>
                    {translate('successText')}
                    <Button
                        size="md"
                        intent="primary"
                        onClick={() => login()}
                        className={styles.loginBtn}
                        fill
                    >
                        {translate('loginBtn')}
                    </Button>
                </>
            );
        }

        if (state.tokenStatus === TokenStatus.Valid) {
            return (
                <ChangePasswordForm
                    onSubmit={(password) => changePassword({ password, useCase })}
                    isLoading={isPasswordChanging}
                    isSuccess={isSuccess}
                    errors={changePasswordErrors}
                />
            );
        }

        return (
            <ChangePasswordInvalidToken
                errors={errors}
                isLoading={isLoading}
                isComplete={isComplete}
                resetPassword={resetPassword}
                useCase={useCase}
            />
        );
    };

    return (
        <AuthLayout className={styles.root}>
            <Header addon={<LocaleSwitcher />} />
            {renderContent()}
        </AuthLayout>
    );
};

export default ChangePassword;
