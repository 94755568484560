// Copyright 2024. WebPros International GmbH. All rights reserved.

import { IdToken } from '@auth0/auth0-spa-js';
import { Connection } from '@platform360/libs/common/connection';
import { getCustomClaimName } from '@platform360/libs/common/custom-jwt-claims';

export type Session = {
    email: string;
    accessToken: string;
    userId: string;
    teamId: string;
    isMfaEnabled: boolean;
    connection?: Connection;
};

export const toSession = (
    accessToken: string,
    {
        sub,
        [getCustomClaimName('teamId')]: teamId,
        [getCustomClaimName('isMfaEnabled')]: isMfaEnabled,
        [getCustomClaimName('connection')]: connection,
        email,
    }: IdToken,
): Session => {
    if (!email) {
        throw new Error('User email should always present in auth token claims.');
    }

    return {
        accessToken,
        isMfaEnabled,
        teamId,
        connection,
        userId: sub,
        email,
    };
};

/**
 * The auth state which, when combined with the auth methods, make up the return object of the `useAuth` hook.
 */
export type AuthState = {
    session?: Session;
    isAuthorized: boolean;
};

/**
 * The initial auth state.
 */
export const initialAuthState: AuthState = {
    isAuthorized: false,
};
