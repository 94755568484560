// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'No se ha encontrado el token de autenticación o este no es válido.',
        invitationNotExist: 'La invitación no existe.',
        invitationExpired: 'La invitación ha expirado.',
        invitationConflict: 'El usuario invitado ya está registrado.',
    },
    SignTermsOfUse: {
        title: 'Privacidad y términos',
        descriptionText1: 'Para crear una cuenta de WebPros Account, debe aceptar los Términos de uso y la Política de privacidad de WebPros.',
        descriptionText2: 'También puede suscribirse a nuestra newsletter para recibir actualizaciones de productos y ofertas individuales.',
        privacyPolicyAgreementTitle: 'Acepto los <termsLink>Términos de uso</termsLink>. Entiendo que WebPros procesará mis datos personales de acuerdo con la <privacyLink>Política de privacidad</privacyLink> de WebPros',
        continueBtn: 'Continuar',
        errors: {
            invalidToken: 'Su sesión actual ha expirado. <a>Vuelva a iniciar sesión</a> para continuar.',
        },
    },
    ChangePassword: {
        successText: 'Su contraseña se ha modificado. Haga clic en el botón de abajo para iniciar sesión.',
        loginBtn: 'Iniciar sesión',
    },
    ChangePasswordForm: {
        setTitle: 'Establecer contraseña',
        passwordLabel: 'Contraseña nueva',
        submitButton: 'Confirmar',
        description: 'Introduzca la nueva contraseña y confírmela. Debe tener al menos 8 caracteres.',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'El email de restablecimiento de contraseña ha sido enviado',
        successSendResetText: 'Le hemos enviado un email a {email} con un enlace para restablecer su contraseña.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Enlace expirado',
        resetText: 'El enlace para el restablecimiento de la contraseña ha expirado.',
        setText: 'El enlace para establecer la contraseña ya no es válido. Por su seguridad, el enlace solo es válido durante 4 horas. Si no le ha dado tiempo a utilizarlo, puede volver a proporcionar su dirección email en el espacio de abajo para establecer la contraseña mediante el proceso estándar de cambio de contraseña.',
        emailLabel: 'Email:',
        submitButton: 'Enviar',
        bottomText: '¿Recuerda la contraseña? Puede <a>iniciar sesión ahora</a>.',
    },
    LandingPage: {
        signUpButton: 'Crear cuenta de WebPros Account',
        signInButton: 'Iniciar sesión',
        title: 'Ecosistema de facilitación con WebPros',
        description: 'Su WebPros Account vincula todos sus productos y servicios de WebPros. Inicie sesión para gestionar su cuenta.',
    },
    ResetPassword: {
        emailLabel: 'Email',
        backBtn: 'Atrás',
        nextBtn: 'Siguiente',
        description: 'Introduzca su dirección de email y le enviaremos un email con un enlace para restablecer su contraseña.',
        successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
        successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
        resendButton: 'Reenviar',
        retryCountdown: 'Reenviar email en {time}',
    },
};