// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Скопировано в буфер обмена.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Вы работаете от имени другого пользователя.',
            depersonateButton: 'Прекратить имперсонацию',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Вы больше не работаете от имени другого пользователя.',
        },
        UserMenu: {
            team: 'Организация',
            logout: 'Выйти',
            changePassword: 'Изменить пароль',
            businessProfile: 'Бизнес-профиль',
            apiTokens: 'Токены API',
        },
    },

    common: {
        errors: {
            isNotEmpty: 'Необходимое для заполнения поле.',
            required: 'Необходимое для заполнения поле.',
            email: 'Неверный формат электронной почты.',
            isEmail: 'Неверный формат электронной почты.',
            min: '{min, plural, one{Значение должно содержать не менее # симв.} few {Значение должно содержать не менее # симв.} many {Значение должно содержать не менее # симв.} other{Значение должно содержать не менее # симв.}}',
            maxLength: '{max, plural, one{Значение должно содержать не более # симв.} few {Значение должно содержать не более # симв.} many {Значение должно содержать не более # симв.} other{Значение должно содержать не более # симв.}}',
            unique: 'Значение должно быть уникальным.',
            unknown: 'Неизвестная ошибка: {message}',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Необходимо указать пароль.',
            errorPasswordIsWrong: 'Неверный пароль.',
            errorPasswordIsTooWeak: 'Этот пароль слишком слабый.',
            errorPasswordContainsPersonalInfo: 'Пароль не должен содержать личную информацию.',
            errorPasswordTooCommon: 'Этот пароль слишком распространенный.',
            errorPasswordPreviouslyUsed: 'Пароль использовался раньше.',
            errorInvalidOtp: 'Код указан неверно.',
        },
    },

    ConfirmationPopover: {
        cancel: 'Отмена',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'Учетная запись WebPros использует стороннее приложение ZendeskChat.',
            description2: 'Продолжая, я выражаю согласие на использование стороннего приложения ZendeskChat. В процессе мои персональные данные (например, IP-адрес) могут передаваться третьим лицам в Европе или за ее пределами. Дополнительная информация: <a>Политика конфиденциальности</a>.',
            confirmButton: 'Запустить ZendeskChat',
        },
        supportFormPopover: {
            description: 'В настоящий момент все агенты заняты. Пожалуйста, подождите или воспользуйтесь нашей формой создания запросов на поддержку.',
            confirmButton: 'Открыть форму',
        },
        ChatButton: {
            title: 'Поддержка',
        },
    },

    emailRequired: "To log in to WebPros using your Facebook account, access to view the email address must be granted. Make sure that the corresponding permission is granted and try again.",
    userBlocked: "Учетная запись приостановлена из-за нарушения пользовательского соглашения. Если вы считаете, что это ошибка, напишите на адрес {emailLink}.",
    invalidDomain: 'Доступ запрещен. Адрес электронной почты пользователя не включен в белый список.',

    errorDefault: 'Что-то пошло не так. Пожалуйста, повторите попытку позже.',
    errorDefaultWithReqId: 'Что-то пошло не так. Пожалуйста, повторите попытку позже. ID запроса: {requestId}.',
    errorTooManyRequests: 'Достигнут лимит вызовов API для этого IP-адреса. Повторите попытку через минуту.',
    errorTokenRenewal: 'Срок действия вашего сеанса истек. Пожалуйста, войдите еще раз.',
    errorNetworkIssue: 'Кажется, у вас проблемы с подключением к сети.',

    errorCopyToClipboard: 'Произошла ошибка копирования, попробуйте скопировать вручную.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH обработает предоставленные вами данные для обработки вашего запроса в соответствии с <a>«Политикой конфиденциальности»</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'Такого пользователя не существует.',
            bounceOrComplaint: 'Этот почтовый адрес не существует или заблокировал наше сообщение как спам. Пожалуйста, <webProsContactLink>обратитесь</webProsContactLink> в службу поддержки WebPros Account.',
            userIsNotVerified: 'Такой пользователь существует, но еще не подтвержден. Мы отправили вам новое письмо для подтверждения. Проверьте свой почтовый ящик.',
            userExist: 'Такой пользователь уже существует.',
            errorPasswordIsEmpty: 'Необходимо ввести пароль.',
            errorPasswordIsWrong: 'Неверный пароль.',
            errorPasswordIsTooWeak: 'Этот пароль слишком слабый.',
        },
        AuthContainer: {
            alreadyHaveAnAccount: 'У вас уже есть учетная запись?',
            loginLink: 'Войти',
        },
        SignUp: {
            signUp: 'Создать учетную запись',
            emailLabel: 'Электронная почта',
            passwordLabel: 'Пароль',
            privacyPolicyAgreementTitle: 'Я принимаю <termsLink>Условия использования</termsLink> и разрешаю компании WebPros обрабатывать предоставленные мной персональные данные в своей внутренней системе в соответствии с <privacyLink>Политикой конфиденциальности</privacyLink> WebPros',
            SignUpByInvitation: {
                emailDescription: 'Для регистрации будет использован адрес электронной почты, указанный выше',
                invitationSucceed: 'Вы зарегистрировались. Теперь войдите в систему.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Имя',
                    lastNamePlaceholder: 'Фамилия',
                    nameLabel: 'Имя',
                },
            },
        },
        ResetPasswordDrawer: {
            title: 'Сбросить пароль',
            text: 'Введите свой адрес электронной почты, и мы отправим вам письмо со ссылкой для сброса пароля.',
            emailLabel: 'Электронная почта:',
            submitButton: 'Отправить',
            successTitle: 'Письмо сброса пароля отправлено',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Отправить повторно',
            loginForm: 'Войти сейчас',
            retryCountdown: 'Письмо может быть отправлено повторно через {time}',
        },
    },
    Footer: {
        copyright: '© {year} WebPros International GmbH. Все права защищены.',
        company: 'Компания',
        knowledgeBase: 'База знаний',
        aboutWebPros: 'О WebPros',
        legal: 'Правовая информация',
        cookieSettings: 'Настройки файлов cookie',
        privacyPolicy: 'Политика конфиденциальности',
        documentation: 'Документация',
        contactUs: 'Свяжитесь с нами',

    },
    Form: {
        requiredLegend: 'Обязательные поля',
        submitButton: 'OK',
        cancelButton: 'Отмена',
    },

    FormField: {
      addMore: 'Добавить еще один',
      remove: 'Удалить',
      clear: 'Очистить',
    },

    FormFieldPassword: {
        improvePassword: 'Чтобы создать надежный пароль:',
        passwordTooShort: 'Сделайте пароль длиннее.',
        lettersLowerCase: 'Используйте как минимум одну букву в нижнем регистре.',
        lettersUpperCase: 'Используйте как минимум одну букву в верхнем регистре.',
        numbers1: 'Используйте как минимум одну цифру.',
        numbers3: 'Используйте как минимум три цифры.',
        specialChar1: 'Используйте как минимум один специальный символ (например: !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Используйте как минимум два специальных символа (например: !, @, #, $, %, ^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Используйте буквы как в верхнем, так и в нижнем регистре.',
        comboLettersAndNumbers: 'Используйте и цифры, и буквы.',
        comboLettersNumbersSpecial: 'Используйте цифры, буквы и специальные символы.',
        yourPasswordIsStrong: 'Вы можете сделать его еще более надежным, увеличив его длину.',
        passwordStrength: 'Надежность пароля ― %%strength%%.',
        strengthVeryStrong: 'ОЧЕНЬ НАДЕЖНЫЙ',
        strengthStrong: 'НАДЕЖНЫЙ',
        strengthVeryWeak: 'ОЧЕНЬ НЕНАДЕЖНЫЙ',
        strengthWeak: 'НЕНАДЕЖНЫЙ',
        strengthMedium: 'СРЕДНИЙ',
        hidePassword: 'Скрыть пароль',
        showPassword: 'Показать пароль',
        generateButton: 'Сгенерировать',
        generateButtonHint: 'Сгенерировать надежный пароль',
    },

    Pagination: {
        totalShort: 'из %%total%%',
        itemsPerPage: 'Элементов на странице',
        all: 'Все',
        total: 'Страница %%current%% из %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Не удалось найти элементы, соответствующие фильтру',
        emptyTitle: 'Похоже, список пуст',
        filteredDescription: 'Уточните запрос или удалите некоторые критерии из фильтра и повторите попытку.',
    },

    Dialog: {
        cancelButton: 'Отмена',
    },

    GaOptOutLink: {
        gaSuccess: 'Отслеживание Google Analytics прекращено.',
    },

    InPlaceText: {
        ok: 'Сохранить (Ctrl+Enter)',
        cancel: 'Отменить (Esc)',
        edit: 'Редактировать',
        add: 'Добавить',
    },

    Team: {
        Layout: {
            title: 'Организация',
            members: 'Участники',
            invitations: 'Приглашения',
        },
        settings: 'Настройки',
        Members: {
            email: 'Электронная почта',
            fullName: 'Полное имя',
            mfaStatus: 'Статус MFA',
            tags: 'Особые отметки',
            remove: 'Удалить',
            removeMemberConfirmation: '<b>{name}</b> больше не будет иметь доступ к организации и ее общим ресурсам, серверам, сайтам и лицензиям.',
            SpecialMarks: {
                you: 'Вы',
                owner: 'Владелец',
            },
            Onboarding: {
                title: 'Создайте организацию на нашей платформе, добавив своих коллег',
                description: 'Наша платформа предлагает инструменты для эффективного управления организацией и совместной работы. Участники вашей организации могут работать совместно с вами на подключенных серверах, получая доступ к ним прямо с платформы. Вам больше не нужно использовать небезопасные открытые пароли или сложные схемы доступа.',
                addMembersButton: 'Добавить участников организации',
            },
            MultifactorStatus: {
                enabled: 'MFA включена',
                disabled: 'MFA не включена',
            },
        },
        Invitations: {
            email: 'Электронная почта',
            expireAt: 'Истекает',
            expired: 'Просрочен',
            add: 'Добавить',
            revoke: 'Отменить',
            Onboarding: {
                title: 'Здесь вы можете пригласить участников своей организации',
                description: 'Добавьте своих коллег, чтобы поделиться с ними серверами и сайтами.',
                addButton: 'Пригласить нового участника',
            },
            ListActions: {
                resendInvitationTooltip: 'Пригласить повторно.',
                successMessage: 'Письмо с приглашением отправлено повторно.',
            },
            AddInvitations: {
                title: 'Добавить новых участников в организацию',
                infoMessage: 'Введите рабочие адреса электронной почты участников организации, чтобы пригласить их.',
                infoDescription: 'Предпочтительно использовать рабочие адреса вместо персональных, чтобы защитить личную информацию пользователей.',
                emailsLabel: 'Адреса электронной почты для приглашения',
                submitButton: 'Отправить приглашение',
                successMessage: '{count, plural, =1{Приглашение было отправлено на адрес {email}.} one{Было отправлено # приглашение.} few {Было отправлено # приглашения.} many {Было отправлено # приглашений.} other{Было отправлено # приглашений.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Отменить',
                noSelectedInvitations: 'Выберите одно или несколько приглашений для отмены.',
                revokeInvitationConfirmation: '{count, plural, =1{Отозвать: <b>{email}</b>?} one{Отозвать <b>#</b> приглашение?} few {Отозвать <b>#</b> приглашения?} many {Отозвать <b>#</b> приглашений?} other{Отозвать <b>#</b> приглашений?}} Вы не сможете отменить это действие.',
                successRevokeInvitation: '{count, plural, =1{Приглашение на адрес <b>{email}</b> было отозвано.} one{<b>#</b> приглашение было отозвано.} few {<b>#</b> приглашения были отозваны.} many {<b>#</b> приглашений было отозвано.} other{<b>#</b> приглашений было отозвано.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'WebPros может создавать для меня информационные сообщения и индивидуальные предложения в соответствии с <a>Политикой конфиденциальности</a> WebPros.',
            text: 'Я могу отозвать это согласие, нажав на ссылку в любом полученном сообщении',
        },
    },

    components: {
        List: {
            selectAll: 'Выбрать все',
            deselectAll: 'Отменить выделение',
            selectedRows: '{selectedRows, plural, one{выбрано элементов: # из {totalRows, plural, one{#} other{#}}} other{выбрано элементов: # из {totalRows, plural, one{#} other{#}}}}',
            totalRows: '{totalRows, plural, one{всего # элемент} few {всего # элемента} many {всего # элементов} other{всего # элементов}}',
            foundRows: '{foundRows, plural, one{найден # элемент} few {найдено # элемента} many {найдено # элементов} other{найдено # элементов}}',
        },
        DataList: {
            groupSearch: 'Поиск',
        },
    },
};