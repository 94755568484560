// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useConfirmEmailMutation } from '@platform360/accounts/web/mutations';
import usePersistReducer from '@platform360/libs/shared-web/auth/usePersistReducer';
import { UntypedAxiosError } from '@platform360/libs/shared-web/typings/api';
import { captureException } from '@sentry/browser';
import { ContentLoader } from '@plesk/ui-library';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ErrorMessage from './ErrorMessage';
import { SimpleLayout } from '@platform360/libs/shared-web/components/SimpleLayout';

const errors = ['forbidden', 'changingEmailConflict'];
const UNEXPECTED_ERROR = 'unexpected';
const getErrorType = (error: UntypedAxiosError): string =>
    errors.includes(error.response?.data.type) ? error.response?.data.type : UNEXPECTED_ERROR;

export const UpdateEmailContainer = () => {
    const [, dispatch] = usePersistReducer();
    const { isAuthorized, login } = useAuth();
    const translate = useTranslate('accounts.UpdateEmail');

    const { mutate, error } = useConfirmEmailMutation({
        onSuccess: (email) => {
            dispatch({ type: 'LOGOUT' });

            void login({
                success: 'true',
                message: 'email-changed',
                email,
            });
        },
        onError: (error) => {
            if (getErrorType(error) === UNEXPECTED_ERROR) {
                captureException(error);
            }
        },
    });

    const errorType = error ? getErrorType(error) : undefined;

    useEffectOnce(() => {
        if (isAuthorized) {
            mutate();
        } else {
            void login({
                success: 'true',
                message: 'email-change-requested',
                redirectUrl: '/accounts/update-email',
            });
        }
    });

    return (
        <SimpleLayout>
            {errorType ? (
                <ErrorMessage type={errorType} />
            ) : (
                <ContentLoader text={translate('loadingText')} />
            )}
        </SimpleLayout>
    );
};
