// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'クリップボードにコピーしました。',
    },
    Page: {
        ImpersonationBanner: {
            title: '別のアカウントをインパーソネートしています。',
            depersonateButton: 'インパーソネートをやめる',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: '別のアカウントをもうインパーソネートしていません。',
        },
        UserMenu: {
            team: '組織',
            logout: 'ログアウト',
            changePassword: 'パスワード変更',
            businessProfile: 'ビジネスプロフィール',
            apiTokens: 'API トークン',
        },
    },

    common: {
        errors: {
            isNotEmpty: '必須フィールドが空欄です。',
            required: '必須フィールドが空欄です。',
            email: '有効な形式のメールアドレスを入力してください。',
            isEmail: '有効な形式のメールアドレスを入力してください。',
            min: '{min, plural, other{値は # 文字以上でなければなりません。}}',
            maxLength: '{max, plural, other{値は # 文字以下でなければなりません。}}',
            unique: '値は一意でなければなりません。',
            unknown: '不明なエラー：{message}',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'パスワードを入力する必要があります。',
            errorPasswordIsWrong: 'パスワードが正しくありません。',
            errorPasswordIsTooWeak: 'パスワードが弱すぎます。',
            errorPasswordContainsPersonalInfo: 'パスワードに個人情報を含めないでください。',
            errorPasswordTooCommon: 'パスワードが一般的すぎます。',
            errorPasswordPreviouslyUsed: '以前に使用したことがあるパスワードです。',
            errorInvalidOtp: '入力したコードが有効ではありません。',
        },
    },

    ConfirmationPopover: {
        cancel: 'キャンセル',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'WebPros Account では、サードパーティアプリケーションの ZendeskChat を使用しています。',
            description2: '続行することにより、サードパーティアプリケーションの ZendeskChat を使用することに同意します。これには、欧州内外のサードパーティへの私の個人データ（IP アドレスなど）の送信が含まれます。詳細：<a>プライバシーポリシー</a>',
            confirmButton: 'ZendeskChat を起動',
        },
        supportFormPopover: {
            description: '現在、対応できる担当者がいません。お待ちいただくか、チケットフォームからリクエストを送信してください。',
            confirmButton: 'フォームを開く',
        },
        ChatButton: {
            title: 'サポート',
        },
    },

    emailRequired: "To log in to WebPros using your Facebook account, access to view the email address must be granted. Make sure that the corresponding permission is granted and try again.",
    userBlocked: "アカウントはユーザの契約違反により一時停止されました。これが誤りだと思われる場合は、{emailLink} までメールにてお知らせください。",
    invalidDomain: 'アクセスが拒否されました。ユーザーのメールアドレスがホワイトリストにありません。',

    errorDefault: '問題が発生しました。後でやり直してください。',
    errorDefaultWithReqId: '何らかの問題が発生しているようです。リクエスト ID：{requestId}。',
    errorTooManyRequests: 'この IP アドレスで API コールの上限に達しました。1 分後にやり直してください。',
    errorTokenRenewal: 'セッションの期限が切れました。再度ログインしてください。',
    errorNetworkIssue: '現在ネットワーク接続で問題が発生しているようです。',

    errorCopyToClipboard: 'コピーエラーが発生しました。手作業でコピーしてください。',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH は、<a>プライバシーポリシー</a>に従ってお客様のリクエストに対応するために、お客様から提供されたデータを処理します。',
    },

    Auth: {
        errors: {
            userDoesntExist: 'ユーザが存在しません。',
            bounceOrComplaint: 'このメールアドレスは存在しないか、当社からのメッセージをスパムとしてブロックしています。WebPros Account サポートチームまで<webProsContactLink>お問い合わせ</webProsContactLink>ください。',
            userIsNotVerified: 'このユーザは存在しますが、まだ確認されていません。新しい確認メールを送信しましたので、メールボックスをチェックしてください。',
            userExist: 'ユーザが既に存在します。',
            errorPasswordIsEmpty: 'パスワードは必須です。',
            errorPasswordIsWrong: 'パスワードが正しくありません。',
            errorPasswordIsTooWeak: 'パスワードが弱すぎます。',
        },
        AuthContainer: {
            alreadyHaveAnAccount: '既にアカウントをお持ちですか？',
            loginLink: 'ログイン',
        },
        SignUp: {
            signUp: 'アカウントを作成',
            emailLabel: 'メールアドレス',
            passwordLabel: 'パスワード',
            privacyPolicyAgreementTitle: '<termsLink>利用規約</termsLink>と、WebPros が私の提供した個人データを内部システムで WebPros <privacyLink>プライバシーポリシー</privacyLink>に従って処理することに同意します。',
            SignUpByInvitation: {
                emailDescription: '上のメールアドレスで登録します',
                invitationSucceed: '正常に登録されました。ログインしてください。',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: '名',
                    lastNamePlaceholder: '姓',
                    nameLabel: '名前',
                },
            },
        },
        ResetPasswordDrawer: {
            title: 'パスワードをリセット',
            text: '入力いただいたメールアドレス宛てに、パスワードのリセット用リンクをメールでお届けします。',
            emailLabel: 'メールアドレス：',
            submitButton: '送信',
            successTitle: 'パスワードリセット用のメールが送信されました',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: '再送',
            loginForm: '今すぐログイン',
            retryCountdown: 'あと {time} で再送できます',
        },
    },
    Footer: {
        copyright: '© {year} WebPros International GmbH. All rights reserved.',
        company: '会社情報',
        knowledgeBase: 'ナレッジベース',
        aboutWebPros: 'WebPros について',
        legal: '法的情報',
        cookieSettings: 'Cookie 設定',
        privacyPolicy: 'プライバシーポリシー',
        documentation: 'ドキュメント',
        contactUs: 'お問い合わせ',

    },
    Form: {
        requiredLegend: '必須入力フィールド',
        submitButton: 'OK',
        cancelButton: 'キャンセル',
    },

    FormField: {
      addMore: 'もう 1 つ追加',
      remove: '削除',
      clear: 'クリア',
    },

    FormFieldPassword: {
        improvePassword: 'パスワードのセキュリティを強化するには：',
        passwordTooShort: 'パスワードを長くしてください。',
        lettersLowerCase: '小文字を 1 つ以上使用してください。',
        lettersUpperCase: '大文字を 1 つ以上使用してください。',
        numbers1: '数字を 1 つ以上使用してください。',
        numbers3: '数字を 3 つ以上使用してください。',
        specialChar1: '特殊文字を 1 つ以上使用してください（例：!。@、#、$、%、^、&、*、?、_、~）。',
        specialChar2: '特殊文字を 2 つ以上使用してください（例：!。@、#、$、%、^、&、*、?、_、~）。',
        comboUpperAndLower: '大文字と小文字の両方を使用してください。',
        comboLettersAndNumbers: '数字と文字を両方使用してください。',
        comboLettersNumbersSpecial: '英数字と特殊文字を使用します。',
        yourPasswordIsStrong: 'もっと長くすると強度が上がります。',
        passwordStrength: 'パスワードの強度は「%%strength%%」です。',
        strengthVeryStrong: '非常に強い',
        strengthStrong: '強い',
        strengthVeryWeak: '非常に弱い',
        strengthWeak: '弱い',
        strengthMedium: '普通',
        hidePassword: 'パスワードを隠す',
        showPassword: 'パスワードを表示',
        generateButton: '生成',
        generateButtonHint: '強度の高いパスワードを生成',
    },

    Pagination: {
        totalShort: '/ %%total%%',
        itemsPerPage: 'ページあたりのアイテム数',
        all: 'すべて',
        total: '%%current%% / %%total%% ページ',
    },

    ListEmptyView: {
        filteredTitle: 'フィルタと一致するアイテムは見つかりませんでした',
        emptyTitle: 'ここには何もないようです',
        filteredDescription: 'クエリを絞り込むかフィルタ条件の一部を削除して、やり直してください。',
    },

    Dialog: {
        cancelButton: 'キャンセル',
    },

    GaOptOutLink: {
        gaSuccess: 'Google アナリティクスによるトラッキングが停止しました。',
    },

    InPlaceText: {
        ok: '保存（Ctrl+Enter）',
        cancel: 'キャンセル（Esc）',
        edit: '編集',
        add: '追加',
    },

    Team: {
        Layout: {
            title: '組織',
            members: 'メンバー',
            invitations: '招待',
        },
        settings: '設定',
        Members: {
            email: 'メールアドレス',
            fullName: 'フルネーム',
            mfaStatus: 'MFA ステータス',
            tags: '特別なマーク',
            remove: '削除',
            removeMemberConfirmation: '<b>{name}</b> は組織および内部で共有されるリソース、サーバ、ウェブサイト、ライセンスへのアクセス権を失います。',
            SpecialMarks: {
                you: 'あなた',
                owner: '所有者',
            },
            Onboarding: {
                title: '同僚を追加して、プラットフォーム上に組織を作成します',
                description: '当社プラットフォームは、効率的な組織管理とコラボレーションのための機能を備えています。組織のメンバーは、接続されたサーバにここから直接アクセスしてあなたとコラボレーションできるようになり、安全でない共有パスワードや複雑なアクセス手順を使用する必要がなくなります。',
                addMembersButton: '組織のメンバーを追加',
            },
            MultifactorStatus: {
                enabled: 'MFA が有効',
                disabled: 'MFA が有効ではない',
            },
        },
        Invitations: {
            email: 'メールアドレス',
            expireAt: '期限日',
            expired: '期限切れ',
            add: '追加',
            revoke: '取り消す',
            Onboarding: {
                title: 'ここで組織のメンバーを招待できます',
                description: '同僚を追加してサーバとウェブサイトを共有できます。',
                addButton: '新規メンバーを招待',
            },
            ListActions: {
                resendInvitationTooltip: '招待状を再送します。',
                successMessage: '招待メールをもう一度送信しました。',
            },
            AddInvitations: {
                title: '組織に新規メンバーを追加',
                infoMessage: '組織のメンバーに参加の招待を送るには、以下にこのメンバーの仕事用メールアドレスを入力してください。',
                infoDescription: 'ユーザの個人情報を守るためには、個人のメールアドレスより仕事用メールアドレスが推奨されます。',
                emailsLabel: '招待リンク用メールアドレス',
                submitButton: '招待を送信',
                successMessage: '{count, plural, =1{{email} への招待が送信されました。} other{# 件の招待が送信されました。}}',
            },
            RevokeInvitationsOperation: {
                revoke: '取り消す',
                noSelectedInvitations: '取り消す招待を 1 つ以上選択してください。',
                revokeInvitationConfirmation: '{count, plural, =1{<b>{email}</b> を取り消しますか？} other{<b>#</b> 件の招待を取り消しますか？}}このアクションを取り消すことはできません。',
                successRevokeInvitation: '{count, plural, =1{メールアドレス <b>{email}</b> が取り消されました。} other{<b>#</b> 件の招待が取り消されました。}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'WebPros は WebPros の<a>プライバシーポリシー</a>に従ってニュースレターと個別オファーを提供することができます。',
            text: '私は、受信した任意のコミュニケーションでリンクをクリックすることにより、この同意を撤回できるものとします。',
        },
    },

    components: {
        List: {
            selectAll: 'すべて選択',
            deselectAll: '選択を解除',
            selectedRows: '{selectedRows, plural, other{# / {totalRows, plural, other{# 件}}が選択されました}}',
            totalRows: '{totalRows, plural, other{合計 # 件}}',
            foundRows: '{foundRows, plural, other{# 件が見つかりました}}',
        },
        DataList: {
            groupSearch: '検索',
        },
    },
};