// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'Token di autorizzazione mancante o non valido.',
        invitationNotExist: 'L\'invito non esiste.',
        invitationExpired: 'L\'invito è scaduto.',
        invitationConflict: 'L\'utente invitato è già registrato.',
    },
    SignTermsOfUse: {
        title: 'Privacy e termini',
        descriptionText1: 'Per creare un account WebPros, accetta le Termini di utilizzo e l\'Informativa sulla privacy di WebPros.',
        descriptionText2: 'Puoi anche iscriverti alla nostra newsletter per ricevere aggiornamenti sui prodotti e offerte individuali.',
        privacyPolicyAgreementTitle: 'Accetto i <termsLink>Termini di utilizzo</termsLink>. WebPros elaborerà i miei dati personali in conformità alla sua <privacyLink>Informativa sulla privacy</privacyLink>',
        continueBtn: 'Continua',
        errors: {
            invalidToken: 'La tua sessione attuale è scaduta. <a>Esegui di nuovo l\'accesso</a> per continuare.',
        },
    },
    ChangePassword: {
        successText: 'La tua password è stata modificata correttamente. Fai clic sul pulsante in basso per accedere.',
        loginBtn: 'Accedi',
    },
    ChangePasswordForm: {
        setTitle: 'Impostare la password',
        passwordLabel: 'Nuova password',
        submitButton: 'Conferma',
        description: 'Digita e conferma la tua nuova password. La lunghezza minima è di 8 caratteri.',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'E-mail di reimpostazione password inviata',
        successSendResetText: 'Abbiamo inviato un\'e-mail all\'indirizzo {email} con un link per reimpostare la password.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Link scaduto.',
        resetText: 'Il link di reimpostazione della password è scaduto.',
        setText: 'Il link di reimpostazione della password non è più valido. Per la tua sicurezza, il link scade dopo 4 ore. Se non hai tempo per usare il link, puoi digitare nuovamente la tua e-mail qui sotto e impostare la password seguendo la relativa procedura di modifica.',
        emailLabel: 'E-mail:',
        submitButton: 'Invia',
        bottomText: 'Ricordi la password? Puoi <a>accedere subito</a>.',
    },
    LandingPage: {
        signUpButton: 'Crea account WebPros',
        signInButton: 'Accedi',
        title: 'Ecosistema di abilitazione con WebPros',
        description: 'Il tuo account WebPros può connettersi a tutti i tuoi prodotti e servizi offerti da WebPros. Esegui l\'accesso per gestire il tuo account.',
    },
    ResetPassword: {
        emailLabel: 'E-mail',
        backBtn: 'Indietro',
        nextBtn: 'Avanti',
        description: 'Inserisci il tuo indirizzo e-mail e ti invieremo un messaggio con un link per reimpostare la password.',
        successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
        successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
        resendButton: 'Rinvio',
        retryCountdown: 'Invia di nuovo l\'e-mail tra {time}',
    },
};