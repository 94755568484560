// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Branding, brandings, isBranding } from '@platform360/libs/common/branding';
import { UnionOf, makeEnum } from '@platform360/libs/common/make-enum';
import { useState } from 'react';

export const { socialLoginButtonIdValues } = makeEnum(
    'socialLoginButtonId',
    'webpros',
    'cpanel-id',
    'platform',
    'google',
    'facebook',
);

export type SocialLoginButtonIds = UnionOf<typeof socialLoginButtonIdValues>;

const zendeskBrandingParams: Record<string, BrandingParams> = {
    // Staging.
    '1500000528161': {
        branding: brandings.platform,
        hideLoginForm: true,
        socialLoginButtonIds: ['platform', 'webpros', 'cpanel-id'],
    },
    '1500000510241': {
        branding: brandings.cpanel,
        hideLoginForm: true,
    },
    '1500000528181': {
        branding: brandings.solusvm,
        hideLoginForm: false,
    },
    '21377801081239': {
        branding: brandings.sitejet,
        hideLoginForm: false,
        socialLoginButtonIds: ['platform', 'webpros', 'cpanel-id'],
    },

    // Production.
    '11466551751319': {
        branding: brandings.platform,
        hideLoginForm: true,
        socialLoginButtonIds: ['platform', 'webpros', 'cpanel-id'],
    },
    '360002489294': {
        branding: brandings.cpanel,
        hideLoginForm: true,
        socialLoginButtonIds: ['cpanel-id'],
    },
    '11466624813463': {
        branding: brandings.solusvm,
        hideLoginForm: false,
    },
    '360001160051': {
        branding: brandings.solusvm,
        hideLoginForm: false,
    },
    '8023609722647': {
        branding: brandings.xovi,
        hideLoginForm: false,
    },
    '23242318967703': {
        branding: brandings.sitejet,
        hideLoginForm: false,
        socialLoginButtonIds: ['platform', 'webpros', 'cpanel-id'],
    },
};

// Controls the order and visibility of social login buttons for the specific brands.
const socialLoginButtonsOrder: Record<string, SocialLoginButtonIds[]> = {
    [brandings.platform]: ['platform', 'webpros', 'cpanel-id'],
    [brandings.cpanel]: ['cpanel-id', 'webpros', 'platform'],
    [brandings.sitejet]: ['google', 'facebook', 'platform', 'webpros', 'cpanel-id'],
};

type BrandingParams = {
    branding?: Branding;
    hideLoginForm: boolean;
    socialLoginButtonIds?: SocialLoginButtonIds[];
};

export const useBranding = (): BrandingParams => {
    const [params] = useState<BrandingParams>(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const zendeskBrandId = searchParams.get('brand_id') ?? undefined;
        const branding = searchParams.get('branding') ?? undefined;
        const zendeskBranding = zendeskBrandId ? zendeskBrandingParams[zendeskBrandId] : undefined;
        const hideLoginForm = !!searchParams.get('hideLoginForm');

        if (zendeskBranding) {
            return zendeskBranding;
        }

        return {
            branding: isBranding(branding) ? branding : undefined,
            socialLoginButtonIds: branding ? socialLoginButtonsOrder[branding] : undefined,
            hideLoginForm,
        };
    });

    return params;
};
