// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Drawer } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import useModal from '@platform360/libs/shared-web/helpers/useModal';
import ApiTokensContainer from './ApiTokensContainer';

const ApiTokensDrawer = () => {
    const translate = useTranslate('accounts.ApiTokens');
    const [isOpen, setIsOpen] = useModal('api-tokens');

    return (
        <Drawer
            title={translate('title')}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            data-type="api-tokens-drawer"
            size="sm"
        >
            <ApiTokensContainer />
        </Drawer>
    );
};

export default ApiTokensDrawer;
