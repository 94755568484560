// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ChatButton from './ChatButton';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { Paragraph } from '@plesk/ui-library';
import { Link } from 'react-router-dom';
import { useLocale } from '@platform360/libs/shared-web/locale';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { concatUrl } from '@platform360/libs/common/concat-url';
import { ZendeskChatOptions } from '@platform360/libs/shared-web/application-settings/context';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

const SCRIPT_ID = 'ze-snippet';

export type ZendeskChatProps = {
    customer: {
        name: string;
        email: string;
    };
    options: ZendeskChatOptions;
};

const ZendeskChat = ({
    customer: { name, email },
    options: {
        department,
        apiKey,
        title,
        welcomeMessage,
        concierge: { avatar, title: conciergeTitle },
    },
}: ZendeskChatProps) => {
    const [enabled, setEnabled] = useLocalStorage<boolean>('zendesk-chat', false);
    const firstInitialization = useRef(enabled);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [operatorOnline, setOperatorOnline] = useState(false);
    const translate = useTranslate('ZendeskChat');
    const [locale] = useLocale();
    const {
        platform: { baseUrl },
    } = useConfig();

    const setWidgetLocale = useCallback(
        () => window.zE?.('webWidget', 'setLocale', locale),
        [locale],
    );

    const hideWidget = () => {
        if (!window.zE) {
            return;
        }

        window.zE('webWidget', 'updateSettings', {
            webWidget: {
                chat: {
                    suppress: true,
                },
            },
        });
    };

    const showWidget = () => {
        if (!window.zE) {
            return;
        }

        window.zE('webWidget', 'updateSettings', {
            webWidget: {
                chat: {
                    departments: {
                        enabled: [department],
                        select: [department],
                    },
                    suppress: false,
                },
            },
        });
    };

    const updateSettings = () => {
        if (!window.zE) {
            return;
        }

        window.zE('webWidget', 'updateSettings', {
            webWidget: {
                hideOnClose: true,
                chat: {
                    departments: {
                        enabled: [department],
                        select: [department],
                    },
                    title: {
                        '*': title,
                    },
                    concierge: {
                        avatarPath: concatUrl(`/zendesk-avatars/${avatar}`, baseUrl),
                        name: 'Elvis Plesky',
                        title: { '*': conciergeTitle },
                    },
                },
                color: { theme: '#53BCE6', launcher: '#53BCE6', launcherText: '#FFFFFF' },
            },
        });
    };

    const initWidget = () => {
        // Check if the script is already loaded
        if (document.getElementById(SCRIPT_ID)) {
            return;
        }

        const script = document.createElement('script');

        script.defer = true;
        script.id = SCRIPT_ID;
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;

        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (!window.zE) {
                return;
            }

            window.zE(() => {
                window.zE?.identify({
                    name,
                    email,
                });
            });

            window.zE('webWidget:on', 'open', () => {
                if (!window.zE) {
                    return;
                }

                if (!window.zE('webWidget:get', 'chat:isChatting')) {
                    window.zE('webWidget', 'chat:send', welcomeMessage(name));
                }
            });

            window.zE(
                'webWidget:on',
                'chat:departmentStatus',
                ({ name, status }: { name: string; status: string }) => {
                    if (!window.zE) {
                        return;
                    }

                    if (name.trim() !== department) {
                        return;
                    }

                    const isChatting: boolean = window.zE('webWidget:get', 'chat:isChatting');

                    if (status === 'online' || isChatting) {
                        updateSettings();

                        if (!firstInitialization.current) {
                            firstInitialization.current = true;

                            // Wait zendesk widget to be initialized.
                            // Just trust me, we can't do better.
                            setTimeout(() => {
                                if (!window.zE) {
                                    return;
                                }

                                window.zE('webWidget', 'open');
                            }, 300);
                        }

                        setOperatorOnline(true);
                        showWidget();
                    } else {
                        if (!firstInitialization.current) {
                            buttonRef.current?.click();
                            firstInitialization.current = true;
                        }

                        setOperatorOnline(false);
                        hideWidget();
                    }
                },
            );

            updateSettings();
            setWidgetLocale();
        });
    };

    useEffect(() => {
        setWidgetLocale();
    }, [locale, setWidgetLocale]);

    useEffectOnce(() => {
        if (enabled) {
            initWidget();
        }

        return () => {
            delete window.zE;
            delete window.zESettings;
        };
    });

    const handleConfirm = () => {
        setEnabled(true);
        initWidget();
    };

    if (!enabled) {
        return (
            <ConfirmationPopover
                onClick={handleConfirm}
                target={<ChatButton />}
                actionButtonText={translate('confirmationPopover.confirmButton')}
                placement="top-left"
            >
                <Paragraph>{translate('confirmationPopover.description1')}</Paragraph>
                <Paragraph>
                    {translate('confirmationPopover.description2', {
                        a: (chunk) => <Link to="/legal/privacy-policy">{chunk}</Link>,
                    })}
                </Paragraph>
            </ConfirmationPopover>
        );
    }

    if (!operatorOnline) {
        return (
            <ConfirmationPopover
                onClick={() => {
                    window.open('https://support.plesk.com/hc/en-us/requests/new', '_blank');
                }}
                target={<ChatButton btnRef={buttonRef} />}
                actionButtonText={translate('supportFormPopover.confirmButton')}
                placement="top-left"
            >
                <Paragraph>{translate('supportFormPopover.description')}</Paragraph>
            </ConfirmationPopover>
        );
    }

    return null;
};

export default ZendeskChat;
