// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useEffect, useState, useRef, ReactNode } from 'react';

// 1 minute.
const DEFAULT_RETRY_INTERVAL = 60 * 1000;

export const formatSeconds = (seconds: number): string => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().slice(14, 19);
};

const calcTimeLeft = (ms: number): number => Math.round((ms - Date.now()) / 1000);

type RetryTimerProps = {
    children: (props: { seconds: number }) => ReactNode;
    onTimeEnd?: () => void;
    retryInterval?: number;
};

const RetryTimer = ({
    children,
    onTimeEnd,
    retryInterval = DEFAULT_RETRY_INTERVAL,
}: RetryTimerProps) => {
    const retryTimeRef = useRef<number>(Date.now() + retryInterval);
    const [seconds, setSeconds] = useState<number>(calcTimeLeft(retryTimeRef.current));

    useEffect(() => {
        const timerId = setInterval(() => {
            const secondsLeft = calcTimeLeft(retryTimeRef.current);
            if (secondsLeft <= 0) {
                clearInterval(timerId);
                setSeconds(0);
                onTimeEnd?.();
            } else {
                setSeconds(secondsLeft);
            }
        }, 500);

        return () => {
            clearInterval(timerId);
        };
    }, [onTimeEnd]);

    return children({ seconds });
};

export default RetryTimer;
