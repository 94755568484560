// Copyright 2024. WebPros International GmbH. All rights reserved.

import { createContext, ReactElement } from 'react';
import { Application } from '@platform360/libs/common/application';

type DarkThemeDataAttribute = 'dark' | 'legacy-dark';

export type ZendeskChatOptions = {
    apiKey: string;
    department: string;
    title: string;
    welcomeMessage: (name: string) => string;
    concierge: {
        avatar: string;
        title: string;
    };
};

/**
 * Represents the settings for a given Application type,
 * which includes customizations such as layout and UI components.
 */
export type ApplicationSettings = {
    logo: ReactElement;
    homePage: ReactElement;
    notFoundPage: ReactElement;
    document: {
        darkThemeDataAttribute: DarkThemeDataAttribute;
        title: string;
        favicon: string;
    };
    header: {
        baseUrl: string;
        userMenu: {
            businessProfile: boolean;
            changePassword: boolean;
            apiTokens: boolean;
            organization: boolean;
        };
    };
    zendeskChat?: ZendeskChatOptions;
    loginPage: ReactElement;
    cookieManager: {
        cookieModify: () => void;
        cookieWithdraw: () => Promise<void>;
    };
};

export const ApplicationSettingsContext = createContext<
    Record<Application, ApplicationSettings> | undefined
>(undefined);
