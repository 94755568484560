// Copyright 2024. WebPros International GmbH. All rights reserved.

import logo from './logo.svg';
import logoInverse from './logo-inverse.svg';

type WebprosLogoProps = {
    inverse?: boolean;
    width?: number;
    className?: string;
};
export const WebprosLogo = ({ inverse, ...props }: WebprosLogoProps) => (
    <img width={160} src={inverse ? logoInverse : logo} alt="WebPros Logo" {...props} />
);

export default WebprosLogo;
