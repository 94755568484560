// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useFormatDateTime } from '@platform360/libs/shared-web/helpers/useFormatDateTime';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import styles from './UserCard.module.css';
import { UserResponse } from '@platform360/admin-panel/web/api/users';
import useHasPermission from '@platform360/admin-panel/web/hooks/useHasPermission';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import CopyText from '@platform360/libs/shared-web/components/CopyText';
import ConfirmationPopover from '@platform360/libs/shared-web/components/ConfirmationPopover';
import { useState } from 'react';
import UserBlockDialog from './UserBlockDialog';
import {
    useDeleteUserMutation,
    useImpersonateMutation,
    useUpdateUserMutation,
} from '@platform360/admin-panel/web/mutations';
import {
    Button,
    Heading,
    Panel,
    Section,
    SectionItem,
    Status,
    Switch,
    ToolbarGroup,
    Label,
} from '@plesk/ui-library';
import { PERMISSION_ALL_USERS_DELETE } from '@platform360/libs/common/permissions';

type UserCardProps = {
    user: UserResponse;
};

export const UserCard = ({
    user: {
        id,
        blocked,
        blockingComment,
        auth0Id,
        userGuid,
        emailVerified,
        webprosUser,
        createdAt,
        lastLogin,
        email,
        name,
        signupSource,
        isDpaAccepted,
        isMarketingConsentAccepted,
        auth0Connection,
    },
}: UserCardProps) => {
    const [isDialogOpened, setIsDialogOpened] = useState(false);
    const formatDateTime = useFormatDateTime();
    const { successToast } = useToaster();
    const { permissions, refetchCurrentUser } = useCurrentUser();
    const { platform } = useConfig();
    const translate = useTranslate('admin-panel.Users.UserCard');
    const canImpersonate = useHasPermission('all:users:impersonate');
    const canDeleteUser = permissions?.includes(PERMISSION_ALL_USERS_DELETE);

    const { mutate } = useUpdateUserMutation({
        onSuccess: () => {
            successToast(translate('successToast'));
        },
    });

    const [isDeleted, setIsDeleted] = useState(false);

    const { mutate: impersonate, isPending: isImpersonating } = useImpersonateMutation({
        onSuccess: () => {
            void refetchCurrentUser();
            successToast(
                <>
                    {translate('impersonation.successToast')}
                    <div className={styles.links}>
                        <a href={platform.baseUrl} target="_blank" rel="noreferrer">
                            {translate('impersonation.navigateToPlesk360')}
                        </a>
                    </div>
                </>,
            );
        },
    });

    const { mutate: deleteUser, isPending: isDeletingUser } = useDeleteUserMutation({
        onSuccess: () => {
            successToast(translate('delete.successToast'));
            setIsDeleted(true);
        },
    });

    const handleSaveWebprosUser = (checked: boolean) => {
        mutate({
            userId: id,
            data: {
                webprosUser: checked,
            },
        });
    };

    const handleUnblockUser = () => {
        mutate({
            userId: id,
            data: {
                blocked: false,
            },
        });
    };

    const handleOpen = () => setIsDialogOpened(true);
    const handleClose = () => setIsDialogOpened(false);

    return isDeleted ? null : (
        <Panel
            title={
                <>
                    <Heading level={3}>
                        {email ?? '—'}{' '}
                        {auth0Connection && (
                            <Label intent="info" className={styles.label}>
                                {auth0Connection}
                            </Label>
                        )}
                    </Heading>
                    <ToolbarGroup title={translate('actions')}>
                        {canImpersonate && (
                            <ConfirmationPopover
                                onClick={() => impersonate(id)}
                                target={
                                    <Button
                                        icon="arrow-right-in"
                                        intent="primary"
                                        state={isImpersonating ? 'loading' : undefined}
                                    >
                                        {translate('impersonation.buttonText')}
                                    </Button>
                                }
                                actionButtonText={translate('impersonation.confirmButton')}
                                cancelButtonText={translate('impersonation.cancelButton')}
                            >
                                {translate('impersonation.description')}
                            </ConfirmationPopover>
                        )}
                        <Button
                            icon="cross-mark"
                            intent="primary"
                            onClick={blocked ? handleUnblockUser : handleOpen}
                        >
                            {translate(blocked ? 'unblockUserButton' : 'blockUserButton')}
                        </Button>
                        {canDeleteUser && (
                            <ConfirmationPopover
                                onClick={() => deleteUser(id)}
                                target={
                                    <Button
                                        icon="recycle"
                                        intent="primary"
                                        state={isDeletingUser ? 'loading' : undefined}
                                    >
                                        {translate('delete.buttonText')}
                                    </Button>
                                }
                                actionButtonText={translate('delete.confirmButton')}
                                cancelButtonText={translate('delete.cancelButton')}
                            >
                                {translate('delete.description')}
                            </ConfirmationPopover>
                        )}
                    </ToolbarGroup>
                </>
            }
        >
            <Section vertical={false}>
                <SectionItem title={translate('id')}>{id}</SectionItem>
                <SectionItem title={translate('name')}>{name ?? '—'}</SectionItem>
                <SectionItem title={translate('auth0Id')}>
                    <CopyText text={auth0Id} />
                </SectionItem>
                <SectionItem title={translate('userGuid')}>
                    <CopyText text={userGuid} />
                </SectionItem>
                <SectionItem title={translate('signupSource')}>{signupSource}</SectionItem>
                <SectionItem title={translate('isDpaAccepted')}>
                    <Status intent={isDpaAccepted ? 'success' : 'inactive'} />
                </SectionItem>
                <SectionItem title={translate('isMarketingConsentAccepted')}>
                    <Status intent={isMarketingConsentAccepted ? 'success' : 'inactive'} />
                </SectionItem>
                <SectionItem title={translate('emailVerified')}>
                    <Status intent={emailVerified ? 'success' : 'inactive'} />
                </SectionItem>
                <SectionItem title={translate('blocked')}>
                    <Status intent={blocked ? 'success' : 'inactive'} />
                </SectionItem>
                {blocked && (
                    <SectionItem title={translate('blockingComment')}>
                        {blockingComment}
                    </SectionItem>
                )}
                <SectionItem title={translate('webprosUser')}>
                    <Switch defaultChecked={webprosUser} onChange={handleSaveWebprosUser} />
                </SectionItem>
                <SectionItem title={translate('lastLogin')}>
                    {lastLogin ? formatDateTime(lastLogin) : '—'}
                </SectionItem>
                <SectionItem title={translate('createdAt')}>
                    {formatDateTime(createdAt)}
                </SectionItem>
            </Section>
            <UserBlockDialog userId={id} isOpen={isDialogOpened} onClose={handleClose} />
        </Panel>
    );
};
