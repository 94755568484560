// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import RetryTimer, { formatSeconds } from '@platform360/libs/shared-web/components/RetryTimer';
import ResetPasswordForm from './ResetPasswordForm';
import { ClosableProps } from '@platform360/libs/shared-web/components/RoutableModal';
import styles from './ResetPasswordDrawer.module.css';
import { useResetPassword } from '@platform360/auth/web/mutations';
import { Button, Paragraph, Drawer } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type ResetPasswordProps = {
    email?: string;
    isUserLoggedIn?: boolean;
} & ClosableProps;

const DataTestIds = {
    RESEND_BUTTON: 'ResetPassword--resendButton',
    LOGIN_LINK: 'ResetPassword--loginLink',
};

export const ResetPasswordDrawer = ({
    email: userEmail,
    onClose,
    isOpen,
    isUserLoggedIn,
}: ResetPasswordProps) => {
    const translate = useTranslate('Auth.ResetPasswordDrawer');
    const [email, setEmail] = useState<string>(userEmail || '');
    const [{ resendAvailable, complete }, setState] = useState({
        complete: false,
        resendAvailable: true,
    });

    const {
        mutate: resetPassword,
        isPending: isLoading,
        errors,
    } = useResetPassword(() => {
        setState((prevState) => {
            const state = {
                complete: true,
                resendAvailable: true,
            };

            if (prevState.complete) {
                state.resendAvailable = false;
            }

            return state;
        });
    });

    const handleResend = () => resetPassword(email);

    const handleClose = () => {
        setState({
            complete: false,
            resendAvailable: true,
        });
        onClose();
    };

    const handleResendEnable = () =>
        setState((prevState) => ({
            ...prevState,
            resendAvailable: true,
        }));

    const renderForm = () => (
        <ResetPasswordForm
            errors={errors}
            isLoading={isLoading}
            email={email}
            onEmailChange={setEmail}
            onSubmit={resetPassword}
            isUserLoggedIn={isUserLoggedIn}
        />
    );

    const renderSuccess = () => (
        <>
            <Paragraph>{translate('successText1', { email })}</Paragraph>
            <Paragraph>{translate('successText2')}</Paragraph>
            <div className={styles.form}>
                <Button
                    intent="primary"
                    onClick={handleResend}
                    state={isLoading ? 'loading' : undefined}
                    data-type={DataTestIds.RESEND_BUTTON}
                    size="lg"
                    disabled={!resendAvailable}
                >
                    {translate('resendButton')}
                </Button>
                {!resendAvailable && (
                    <RetryTimer onTimeEnd={handleResendEnable}>
                        {({ seconds }: { seconds: number }) => (
                            <span className={styles.retryCountdown}>
                                {translate('retryCountdown', {
                                    time: formatSeconds(seconds),
                                })}
                            </span>
                        )}
                    </RetryTimer>
                )}
            </div>
        </>
    );

    return (
        <Drawer
            isOpen={isOpen}
            size="xs"
            title={translate(complete ? 'successTitle' : 'title')}
            onClose={handleClose}
            data-type="reset-password"
        >
            {complete ? renderSuccess() : renderForm()}
        </Drawer>
    );
};

export default ResetPasswordDrawer;
