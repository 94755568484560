// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        newInvitation: 'Une invitation correspondant à cette adresse e-mail existe déjà.',
        invitationMemberExist: 'Un utilisateur avec cette adresse e-mail est déjà enregistré en tant que membre de l\'équipe.',
        bounceOrComplaint: 'Cette adresse e-mail n\'existe pas ou a bloqué notre message comme étant du spam. Veuillez contacter l\'équipe d\'assistance Plesk 360.',
        emailsAreEqual: 'Vous devez utiliser une adresse e-mail différente de l\'adresse actuelle.',
        consentKeeperNotAvailable: 'Impossible de vérifier si le Data Processing Agreement est signé. Veuillez réessayer ultérieurement.',
    },
    profile: {
        MfaControl: {
            mfaEnable: 'Envoyer le mail d\'inscription',
            mfaCheckEmail: 'Vérifiez l\'e-mail reçu pour terminer l\'inscription.',
            mfaDisable: 'Désactiver MFA',
            mfaDisableSuccessToast: 'L\'authentification multifacteur (MFA) a été désactivée.',
            MfaDisableConfirmationDialog: {
                dialogTitle: 'Désactiver MFA',
                description: 'Voulez-vous désactiver l\'authentification multifacteur (MFA) ?',
                currentPassword: 'Mot de passe actuel',
                otp: 'Code à usage unique',
                otpDescription: 'Code à usage unique pour l\'authentification à deux facteurs fourni par votre application d\'authentification.',
                buttonSave: 'Confirmer',
                buttonCancel: 'Annuler',
            },
        },
        AccountRemoval: {
            removeAccountButton: 'Supprimer le compte',
            removeAccount: 'Voulez-vous vraiment supprimer votre compte <b>{email}</b> du service WebPros Account ?',
            removeAccountWithMembers: '{teamMembersCount, plural, one{Voulez-vous vraiment supprimer votre compte <b>{email}</b> et # membre de l\'organisation du service WebPros Account ?} other{Voulez-vous vraiment supprimer votre compte <b>{email}</b> et # membres de l\'organisation du service WebPros Account ?}}',
            removingAccount: 'Suppression du compte...',
            loading: 'Chargement...',
        },
        ChangePassword: {
            title: 'Changement de mot de passe',
            submit: 'Changer le mot de passe',
            labelCurrentPassword: 'Mot de passe actuel',
            labelNewPassword: 'Nouveau mot de passe',
            labelRepeatPassword: 'Confirmer le mot de passe',
            forgotPassword: 'Mot de passe oublié ?',
            successMessage: 'Votre mot de passe a été changé.',
            passwordMatch: 'Les mots de passe que vous avez saisis ne sont pas identiques.',
        },
        ChangeableEmail: {
            buttonEdit: 'Éditer',
            dialogTitle: 'Changer l\'adresse e-mail',
            description: 'Nous vous enverrons un e-mail avec les instructions pour la mise à jour de votre adresse.',
            email: 'Nouvelle adresse e-mail',
            currentPassword: 'Mot de passe actuel',
            otp: 'Code à usage unique',
            otpDescription: 'Un code à usage unique fait partie de l\'authentification à deux facteurs. Vous pouvez le trouver dans votre application d\'authentification.',
            consentsTitle: 'Gestion du consentement',
            buttonSave: 'Envoyer',
            buttonCancel: 'Annuler',
            successMessage: 'Nous avons envoyé un e-mail de vérification à l\'adresse <b>{email}</b>. Suivez les instructions pour vérifier votre nouvelle adresse e-mail.',
        },
        DpaManager: {
            title: 'Contrat relatif au traitement des données',
            accepted: 'Accepté',
            notAccepted: 'Non accepté',
            buttonEdit: 'Éditer',
        },
        DpaDialog: {
            title: 'Contrat relatif au traitement des données',
            subtitle: 'Pour utiliser certaines de nos fonctionnalités, vous devez accepter le Data Processing Agreement (DPA, Accord relatif au traitement des données).',
            dpaLinkText: 'Contrat relatif au traitement des données',
            buttonAccept: 'J\'accepte',
            buttonDecline: 'Je refuse',
            successMessage: 'Le contrat Data Processing Agreement (DPA) a bien été mis à jour.',
        },
        ConsentManager: {
            error: 'Une erreur s\'est produite lors du chargement des données liées au consentement.',
            successMessage: 'Les données liées au consentement ont été mises à jour.',
            subscribed: 'Abonné(e)',
            notSubscribed: 'Non abonné(e)',
            buttonEdit: 'Éditer',
            dialogTitle: 'Gestion du consentement',
            buttonSave: 'Enregistrer',
            consentTitles: {
                announce: 'Mises à jour du projet et communication commerciale',
            },
        },
        ChangeableName: {
            successMessage: 'Mise à jour du nom réussie',
            updating: 'Mise à jour en cours',
            firstName: 'Prénom',
            lastName: 'Nom de famille',
        },
        Timezone: {
            buttonEdit: 'Éditer',
            timezone: 'Fuseau horaire',
            dialogTitle: 'Modifier le fuseau horaire',
            buttonCancel: 'Annuler',
            buttonSave: 'Enregistrer',
            successMessage: 'Le fuseau horaire a bien été mis à jour.',
        },
    },
    BusinessProfile: {
        title: 'Parlez-nous un peu de vous !',
        save: 'Enregistrer',
        done: 'Effectué',
        answerLater: 'Répondre ultérieurement',
        tour: {
            gotIt: 'J\'ai compris.',
            text: 'Vous pourrez mettre à jour ultérieurement votre profil Entreprise depuis le menu du compte.',
        },
        firstSectionTitle: 'Quel est le profil de votre entreprise ?',
        secondSectionTitle: 'Quel usage de Plesk faites-vous ?',
        whoDoYouCreateWebsitesFor: 'Pour qui créez-vous des sites Web ?',
        clients: 'Clients',
        ownPurposes: 'Pour votre propre usage',
        whatKindOfBusinessDoYouDo: 'Quelles sont les activités de votre entreprise ?',
        hosting: 'Hébergement',
        agency: 'Agence',
        isTheWebsiteForBusinessOrPrivatePurposes: 'Votre site Web est-il destiné à des fins professionnelles ou à un usage personnel ?',
        business: 'Entreprise',
        private: 'Privé',
        whatIsYourCoreBusiness: 'Quelle est votre activité principale ?',
        webHosting: 'Hébergement Web',
        vpsAndServerHosting: 'Hébergement VPS et serveurs',
        iaas: 'Fournisseur Infrastructure as a Service (IaaS)',
        saas: 'Fournisseur Software as a Service (SaaS)',
        other: 'Autre',
        webDevelopment: 'Développement Web',
        webDesignOrManagement: 'Design et gestion Web',
        marketingOrMedia: 'Marketing et médias',
        whichDepartmentDoYouBelongTo: 'À quel département appartenez-vous ?',
        itAndSystemAdministration: 'IT et administration système',
        systemsIntegration: 'Intégration de système',
        softwareDevelopment: 'Développement logiciel',
        marketingOrCommunication: 'Marketing et communication',
        forWhichKindOfOrganisationIsTheWebsite: 'À qui s\'adresse le site Web ?',
        privatePersonOrFamily: 'Personnes privées ou familles',
        association: 'Association',
        nonProfitOrganisation: 'Organisation à but non lucratif',
        howManyEmployeesDoesYourCompanyHave: 'Combien y a-t-il d\'employés dans votre entreprise ?',
        selfEmployed: 'Travailleur indépendant',
        howManyMembersDoesYourOrganisationHave: 'Combien y a-t-il de membres dans votre organisation ?',
        howManyServersDoYouManage: 'Combien de serveurs gérez-vous ?',
        howManyServersDoYouManageDescription: 'avec et sans Plesk',
        areTheyYourServers: 'Êtes-vous le propriétaire de ces serveurs ?',
        yes: 'Oui, je suis le propriétaire de ces serveurs.',
        no: 'Non, je gère ces serveurs pour d\'autres personnes.',
        partly: 'Les deux. Je possède certains serveurs et je gère des serveurs pour d\'autres personnes.',
        whatIsYourMainPurposeOfPlesk: 'Pour quoi utilisez-vous Plesk ?',
        whatIsYourMainPurposeOfPleskDescription: 'Choisissez les réponses qui correspondent.',
        webhosting: 'Hébergement Web',
        emailHosting: 'Hébergement de messagerie',
        eCommerceHosting: 'Hébergement e-commerce',
        wordPressManagement: 'Gestion WordPress',
        systemAdministration: 'Administration système',
        collaboration: 'Collaboration',
        automation: 'Automatisation',
        reselling: 'Revente',
        howWouldYouDescribeYourTechnicalKnowledge: 'Comment évaluez-vous vos compétences techniques ?',
        notTechnicalAtAll: 'Je n\'ai aucune compétence technique.',
        basicKnowledge: 'J\'ai des notions basiques de Linux ou Windows.',
        expert: 'Je suis un expert et j\'utilise Plesk à des fins d\'automatisation.',
    },
    ApiTokens: {
        title: 'Tokens API',
        name: 'Nom complet',
        creationDate: 'Heure de génération',
        lastUsageDate: 'Dernière utilisation',
        tokenNeverUsed: 'Jamais',
        addButton: 'Générer un token API',
        addButtonLimitExceededTooltip: 'La limite des tokens API est atteinte. Supprimez des tokens existants pour en créer de nouveaux.',
        countTooltip: '{max, plural, one{# jeton maximum par compte.} other{# jetons maximums par compte.}}',
        totalAmount: 'total {itemsAmount}/{maxAmount} <infoIcon></infoIcon>',
        tokenAddingSuccess: 'Le token <info>{name}</info> a bien été généré. Veillez à le conserver en lieu sûr. Vous ne pourrez pas le revoir !',
        emptyListTitle: 'Générer les jetons API pour vos applications ou scripts pour accéder à l\'API WebPros Account',
        emptyListDescription: 'Vos tokens API s\'afficheront ici.',
        createToken: 'Générer un token API',
        successCopied: 'Copié dans le presse-papier.',
        successTokenDelete: 'Token supprimé',
        expireAtTooltip: 'Le jeton expire à : {expireAt}.',
        AddTokenDialog: {
            title: 'Nouveau token API',
            nameLabel: 'Nom du token',
            nameDescription: 'Pour quoi voulez-vous utiliser ce token ?',
            addButton: 'Générer',
            closeButton: 'Fermer',
            tokenInfo: 'Veillez à le conserver en lieu sûr. Vous ne pourrez pas le revoir. Si un token est compromis, générez-en un nouveau immédiatement. Votre nouveau token :',
            close: 'Fermer',
            tokenSuccessCopied: 'Token copié dans le presse-papier.',
        },
        DeleteButton: {
            deleteButton: 'Supprimer le token',
            deleteConfirmation: 'Voulez-vous vraiment supprimer le jeton <tooltip>{name}</tooltip> ? Toute application ou tout script qui utilise ce jeton ne pourra plus accéder à l\'API WebPros Account.',
            copy: 'Copier dans le presse-papier',
            deleteConfirmationInputDesc: 'Saisissez le nom du token pour confirmer sa suppression.',
            deleteConfirmationButton: 'Supprimer le token',
        },
    },
    UpdateEmail: {
        loadingText: 'Changement de l\'adresse e-mail...',
        ErrorMessage: {
            tryAgainButton: 'Réessayer',
            errors: {
                forbidden: 'Le lien de changement d\'adresse e-mail a expiré. Essayez à nouveau.',
                changingEmailConflict: 'Un utilisateur avec cette adresse e-mail existe déjà.',
                unexpected: 'Service temporairement indisponible. Veuillez réessayer ultérieurement.',
            },
        },
    },
    HomePage: {
        WelcomeBanner: {
            title: 'Bienvenue dans WebPros Account !',
        },
        Profile: {
            nameField: 'Nom complet',
            emailAddressField: 'Adresse e-mail',
            passwordField: 'Mot de passe',
            changePasswordLink: 'Changer le mot de passe',
            timezoneField: 'Fuseau horaire',
            personalInfoSection: 'Infos personnelles',
            profilePictureSection: 'Photo de profil',
            securitySection: 'Sécurité',
            mfaField: 'Authentification mutifacteur',
            agreementsSection: 'Accords',
            changeGravatar: 'Configurez la photo de votre profil sur <link>Gravatar</link>.',
            removeAccountButton: 'Supprimer mon compte',
            plesk360ConfigureName: 'Configurez votre nom sur la <link>page de votre profil Plesk 360</link>.',
            plesk360ConfigureEmail: 'Configurez votre e-mail sur la <link>page de votre profil Plesk 360</link>.',
            plesk360ConfigurePassword: 'Configurez votre mot de passe sur la <link>page de votre profil Plesk 360</link>.',
            cpanelStoreConfigureName: 'Configurez votre nom sur la <link>page de votre profil cPanel</link>.',
            cpanelStoreConfigureEmail: 'Configurez votre e-mail sur la <link>page de votre profil cPanel</link>.',
            cpanelStoreConfigurePassword: 'Configurez votre mot de passe sur la <link>page de votre profil cPanel</link>.',
            googleConfigureName: 'Configurez votre nom sur <link>la page de votre compte Google</link>.',
            googleConfigureEmail: 'Configurez votre adresse e-mail sur <link>la page de votre compte Google</link>.',
            googleConfigurePassword: 'Configurez votre mot de passe sur <link>la page de votre compte Google</link>.',
            facebookConfigureName: 'Configurez votre nom sur <link>la page de votre compte Facebook</link>.',
            facebookConfigureEmail: 'Configurez votre adresse e-mail sur <link>la page de votre compte Facebook</link>.',
            facebookConfigurePassword: 'Configurez votre mot de passe sur <link>la page de votre compte Facebook</link>.',
        },
    },
};