// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import {
    Form,
    FormProps,
    FormFieldPassword,
    Button,
    FormErrors,
    generatePassword,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Link } from 'react-router-dom';
import useModal, { useGetModalUrl } from '@platform360/libs/shared-web/helpers/useModal';
import ResetPasswordDrawer from '@platform360/auth/web/components/ResetPasswordDrawer';

export type FormValues = {
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
};

export type ChangePasswordProps = {
    email: string;
    errors?: FormErrors;
    onSubmit: (values: FormValues) => void;
    isLoading: boolean;
    isUserLoggedIn?: boolean;
} & FormProps<FormValues>;

const ChangePassword = ({
    errors,
    isLoading,
    email,
    onSubmit,
    cancelButton,
    isUserLoggedIn,
    ...props
}: ChangePasswordProps) => {
    const translate = useTranslate('accounts.profile.ChangePassword');
    const getModalUrl = useGetModalUrl();
    const [values, setValues] = useState<FormValues>({
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
    });
    const [isOpenResetPassword, setIsOpenResetPassword] = useModal('reset-password');
    const resetPasswordUrl = getModalUrl('reset-password');

    const cancelButtonProps = { 'data-type': 'cancel-button' };

    return (
        <Form
            state={isLoading ? 'submit' : undefined}
            values={values}
            errors={errors}
            onSubmit={onSubmit}
            submitButton={{ children: translate('submit') }}
            applyButton={false}
            onFieldChange={(name, value) => {
                setValues({
                    ...values,
                    [name]: value,
                });
            }}
            cancelButton={
                typeof cancelButton === 'object'
                    ? { ...cancelButton, ...cancelButtonProps }
                    : cancelButtonProps
            }
            {...props}
        >
            <FormFieldPassword
                name="currentPassword"
                label={translate('labelCurrentPassword')}
                description={<Link to={resetPasswordUrl}>{translate('forgotPassword')}</Link>}
                required
                hideShowButton
                hideGenerateButton
                hidePasswordMeter
            />
            <FormFieldPassword
                name="newPassword"
                label={translate('labelNewPassword')}
                required
                generateButton={(props: object) => (
                    <Button
                        {...props}
                        onClick={() => {
                            const newPassword = generatePassword();
                            setValues({
                                ...values,
                                newPassword,
                                repeatPassword: newPassword,
                            });
                        }}
                    />
                )}
            />
            <FormFieldPassword
                name="repeatPassword"
                label={translate('labelRepeatPassword')}
                required
                hideGenerateButton
                hidePasswordMeter
            />
            <ResetPasswordDrawer
                isUserLoggedIn={isUserLoggedIn}
                isOpen={isOpenResetPassword}
                email={email}
                onClose={() => setIsOpenResetPassword(false)}
            />
        </Form>
    );
};

export default ChangePassword;
