// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: '認証トークンがないか、無効です。',
        invitationNotExist: '招待が存在しません。',
        invitationExpired: 'この招待は期限が切れています。',
        invitationConflict: '招待されたユーザは既に登録済みです。',
    },
    SignTermsOfUse: {
        title: 'プライバシーと規約',
        descriptionText1: 'WebPros アカウントを作成するには、WebPros の利用規約とプライバシーポリシーに同意する必要があります。',
        descriptionText2: 'また、ニュースレターを購読いただくと、製品の最新情報や個別のオファーを受け取ることができます。',
        privacyPolicyAgreementTitle: '私は<termsLink>利用規約</termsLink>に同意します。また、WebPros が WebPros <privacyLink>プライバシーポリシー</privacyLink>に従って私の個人データを内部システムで処理することを承知します。',
        continueBtn: '続行する',
        errors: {
            invalidToken: '現在のセッションの有効期限が切れました。続行するには<a>再度ログイン</a>してください。',
        },
    },
    ChangePassword: {
        successText: 'パスワードが正常に変更されました。下のボタンをクリックしてログインしてください。',
        loginBtn: 'ログイン',
    },
    ChangePasswordForm: {
        setTitle: 'パスワードを設定',
        passwordLabel: '新規パスワード',
        submitButton: '確認',
        description: '新しいパスワードを入力し、確認してください。パスワードは 8 文字以上含まれる必要があります。',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'パスワードリセット用のメールが送信されました',
        successSendResetText: 'パスワードをリセットするためのリンクを {email} に送信しました。',
    },
    ChangePasswordInvalidTokenForm: {
        title: '期限切れのリンク',
        resetText: 'パスワードリセットリンクは期限が切れています。',
        setText: 'パスワードをリセットするためのリンクが有効ではなくなりました。セキュリティのため、パスワード設定用のリンクの有効期間は 4 時間となっています。このリンクを使用してパスワードを設定する時間がなかった場合は、以下にメールアドレスをもう一度入力して、パスワードの変更手続きに従ってパスワードを設定することができます。',
        emailLabel: 'メールアドレス：',
        submitButton: '送信',
        bottomText: 'パスワードを覚えていれば、<a>今すぐログイン</a>できます。',
    },
    LandingPage: {
        signUpButton: 'WebPros のアカウントを作成',
        signInButton: 'ログイン',
        title: 'WebPros のイネーブルメントエコシステム',
        description: 'WebPros アカウントは、WebPros のすべての製品とサービスをつなぎます。ログインしてアカウントを管理してください。',
    },
    ResetPassword: {
        emailLabel: 'メールアドレス',
        backBtn: '戻る',
        nextBtn: '次へ',
        description: 'メールアドレスを入力してください。このメールアドレス宛てにパスワードのリセット用リンクをメールで送信いたします。',
        successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
        successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
        resendButton: '再送',
        retryCountdown: 'メールの再送まで：{time}',
    },
};