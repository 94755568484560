// Copyright 2024. WebPros International GmbH. All rights reserved.

import { cloneElement, ReactElement, useState, MouseEvent, ReactNode } from 'react';
import {
    Button,
    ButtonProps,
    FormFieldText,
    Popover,
    TranslationProps,
    PopoverProps,
} from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

import styles from './ConfirmationPopover.module.css';

export type ConfirmationPopoverProps = {
    target: ReactElement;
    popoverClassName?: string;
    onClick: (e: MouseEvent) => void;
    onShow?: () => void;
    confirmationButtonIntent?: 'primary' | 'danger';
    actionButtonText: TranslationProps;
    actionButtonState?: ButtonProps['state'];
    cancelButtonText?: TranslationProps;
    children: ReactNode;
    placement?: PopoverProps['placement'];
    confirmString?: string;
    confirmStringDescription?: TranslationProps;
    actionButtonDisabled?: boolean;
    buttonsSize?: 'lg' | 'md';
    ghostCancelButton?: boolean;
};

const ConfirmationPopover = ({
    target,
    popoverClassName,
    onClick,
    onShow,
    actionButtonText,
    actionButtonState,
    cancelButtonText,
    children,
    placement = 'top',
    confirmString,
    confirmStringDescription,
    confirmationButtonIntent = 'danger',
    actionButtonDisabled = false,
    buttonsSize = 'lg',
    ghostCancelButton = true,
    ...props
}: ConfirmationPopoverProps) => {
    const translate = useTranslate('ConfirmationPopover');
    const [visible, setVisible] = useState(false);
    const [isDisabledActionButton, setIsDisabledActionButton] = useState(Boolean(confirmString));
    const handleShow = () => {
        if (onShow) {
            onShow();
        }
        setVisible(true);
    };
    const handleCancel = () => {
        setIsDisabledActionButton(Boolean(confirmString));
        setVisible(false);
    };
    const handleAction = (e: MouseEvent) => {
        setIsDisabledActionButton(Boolean(confirmString));
        setVisible(false);
        onClick(e);
    };
    const handleChangeConfirmString = (value: string) =>
        setIsDisabledActionButton(value !== confirmString);

    return (
        <Popover
            target={cloneElement(target, {
                ...props,
                onClick: handleShow,
            })}
            className={popoverClassName}
            placement={placement}
            visible={visible}
            onClose={handleCancel}
            data-type="confirmation-popover"
        >
            {children}
            {confirmString && (
                <FormFieldText
                    size="fill"
                    label={confirmStringDescription}
                    onChange={handleChangeConfirmString}
                    vertical
                />
            )}
            <div className={styles.buttons}>
                <Button
                    onClick={handleAction}
                    size={buttonsSize}
                    disabled={isDisabledActionButton || actionButtonDisabled}
                    intent={confirmationButtonIntent}
                    state={actionButtonState}
                    data-type="confirmation-button"
                >
                    {actionButtonText}
                </Button>{' '}
                <Button ghost={ghostCancelButton} onClick={handleCancel} size={buttonsSize}>
                    {cancelButtonText || translate('cancel')}
                </Button>
            </div>
        </Popover>
    );
};

export default ConfirmationPopover;
