// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button } from '@plesk/ui-library';
import MfaDisableConfirmationDialog from './MfaDisableConfirmationDialog';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useDisableMfaMutation, useEnableMfaMutation } from '@platform360/accounts/web/mutations';
import { useState } from 'react';

const MfaControl = () => {
    const translate = useTranslate('accounts.profile.MfaControl');
    const { isMfaEnabled } = useCurrentUser();
    const { successToast } = useToaster();
    const [isMfaConfirmationDialogOpen, setIsMfaConfirmationDialogOpen] = useState(false);
    const {
        mutate: disableMfa,
        isPending: isDisableMfaLoading,
        errors,
    } = useDisableMfaMutation({
        onSuccess: () => {
            successToast(translate('mfaDisableSuccessToast'));
        },
    });

    const { mutate: enableMfa, isPending: isEnableLoading } = useEnableMfaMutation({
        onSuccess: () => {
            successToast(translate('mfaCheckEmail'));
        },
    });

    if (isMfaEnabled) {
        return (
            <>
                <MfaDisableConfirmationDialog
                    isOpen={isMfaConfirmationDialogOpen}
                    isLoading={isDisableMfaLoading}
                    errors={errors}
                    onSubmit={disableMfa}
                    onCancel={() => setIsMfaConfirmationDialogOpen(false)}
                />
                <Button
                    onClick={() => setIsMfaConfirmationDialogOpen(true)}
                    data-type="disable-mfa-link"
                    ghost
                >
                    {translate('mfaDisable')}
                </Button>
            </>
        );
    }

    return (
        <Button
            ghost
            onClick={() => enableMfa()}
            disabled={isEnableLoading}
            state={isEnableLoading ? 'loading' : undefined}
            data-type="enable-mfa-link"
        >
            {translate('mfaEnable')}
        </Button>
    );
};

export default MfaControl;
