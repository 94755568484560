// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Legal',
        terms: 'Terms of use',
        privacyPolicy: 'Privacy Policy',
        dpa: 'Data Processing Agreement',
        optOuts: 'Opt-Outs',
        cookies: 'Cookie Statement',
        gaHeader: 'Google Analytics Opt-out',
        gaOptOut: 'Click here to opt out of Google Analytics',
        cookieHeader: 'Cookie Consent Adjustments',
        cookieModify: 'Modify your cookie consent',
        cookieWithdraw: 'Withdraw your cookie consent',
        cookieSuccess: 'Successful consent withdraw',
    },
};
