// Copyright 2024. WebPros International GmbH. All rights reserved.

import { memo } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import UserMenu from '@platform360/libs/shared-web/components/UserMenu';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';
import { Button } from '@plesk/ui-library';
import useFeatureFlagEnabled from '@platform360/libs/shared-web/helpers/useFeatureFlagEnabled';
import useDarkTheme from '@platform360/libs/shared-web/helpers/useDarkTheme';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

export const Header = () => {
    const darkThemeEnabled = useFeatureFlagEnabled('darkTheme');
    const { toggle, isDarkMode, isSupported } = useDarkTheme();
    const {
        header: { baseUrl },
        logo,
    } = useApplicationSettings();

    return (
        <div className={styles.root}>
            <Link className={styles.brand} to={baseUrl} data-type="Page--logo">
                {logo}
            </Link>
            <div className={styles.options}>
                <UserMenu />
                <LocaleSwitcher />
                {darkThemeEnabled && isSupported && (
                    <Button ghost icon={isDarkMode ? 'lightbulb' : 'sleep'} onClick={toggle} />
                )}
            </div>
        </div>
    );
};

export default memo(Header);
