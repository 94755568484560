// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu, MenuItem, Text, MenuDivider } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useMediaQuery } from 'usehooks-ts';
import { useUserMenuModals } from './useUserMenuModals';
import GhostButton from '@platform360/libs/shared-web/components/GhostButton';
import IconGravatar from './IconGravatar';
import styles from './UserMenu.module.css';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useAuth } from '@platform360/libs/shared-web/auth';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';

export const DATA_TYPE_USER_MENU = 'user-menu';

type UserMenuProps = {
    showBusinessProfile?: boolean;
};

export const UserMenu = ({ showBusinessProfile = true }: UserMenuProps) => {
    const {
        header: { userMenu },
    } = useApplicationSettings();
    const isHideText = useMediaQuery('(max-width: 767px)');
    const currentUser = useCurrentUser();
    const { isAuthorized } = useAuth();
    const getModal = useUserMenuModals();
    const showOrganization = currentUser.isOwner && userMenu.organization;
    const translate = useTranslate('Page.UserMenu');
    const [changePasswordUrl, ChangePassword] = getModal('change-password');
    const [businessProfileUrl, BusinessProfile] = getModal('business-profile');
    const [apiTokensUrl, ApiTokens] = getModal('api-tokens');
    const isBusinessProfileShown =
        userMenu.businessProfile && showBusinessProfile && currentUser.isOwner;

    if (!isAuthorized) {
        return null;
    }

    const items = [];

    if (isBusinessProfileShown) {
        items.push(
            <MenuItem
                component={Link}
                to={businessProfileUrl}
                data-type="business-profile"
                key="business-profile"
            >
                {translate('businessProfile')}
            </MenuItem>,
        );
    }

    if (userMenu.changePassword) {
        items.push(
            <MenuItem
                component={Link}
                to={changePasswordUrl}
                data-type="change-password"
                key="change-password"
            >
                {translate('changePassword')}
            </MenuItem>,
        );
    }

    if (userMenu.apiTokens) {
        items.push(
            <MenuItem component={Link} to={apiTokensUrl} data-type="api-tokens" key="api-tokens">
                {translate('apiTokens')}
            </MenuItem>,
        );
    }

    if (showOrganization) {
        if (items.length > 0) {
            items.push(<MenuDivider key="team-divider" />);
        }

        items.push(
            <MenuItem component={Link} to="/organization" data-type="team" key="team">
                {translate('team')}
            </MenuItem>,
        );
    }

    if (items.length > 0) {
        items.push(<MenuDivider key="logout-divider" />);
    }

    items.push(
        <MenuItem
            component={Link}
            to="/auth/logout"
            icon="cross-mark"
            data-type="logout"
            key="logout"
        >
            {translate('logout')}
        </MenuItem>,
    );

    return (
        <Fragment>
            <Dropdown
                menu={<Menu>{items}</Menu>}
                menuPlacement="bottom-end"
                data-type={DATA_TYPE_USER_MENU}
            >
                <GhostButton caret>
                    <IconGravatar userProfile={currentUser} />
                    {!isHideText && (
                        <Text truncate className={styles.userName} data-type="Page--userName">
                            {currentUser.name}
                        </Text>
                    )}
                </GhostButton>
            </Dropdown>
            <ChangePassword />
            {userMenu.apiTokens && <ApiTokens />}
            {isBusinessProfileShown && <BusinessProfile />}
        </Fragment>
    );
};

export default UserMenu;
