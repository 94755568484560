// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Правовая информация',
        terms: 'Условия использования',
        privacyPolicy: 'Политика конфиденциальности',
        dpa: 'Соглашение на обработку данных',
        optOuts: 'Исключения',
        cookies: 'Сообщение о файлах cookie',
        gaHeader: 'Отказ от использования Google Analytics',
        gaOptOut: 'Нажмите здесь, чтобы отказаться от использования Google Analytics',
        cookieHeader: 'Изменение согласия на использование файлов cookie',
        cookieModify: 'Измените ваше согласие на использование файлов cookie',
        cookieWithdraw: 'Аннулировать согласие на использование файлов cookie',
        cookieSuccess: 'Согласие аннулировано успешно',
    },
};