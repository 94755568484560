// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        newInvitation: 'An invitation for this email already exists.',
        invitationMemberExist: 'User for this email is already registered as team member.',
        bounceOrComplaint: 'This email address does not exist or has blocked our message as spam. Please contact the Plesk 360 support team.',
        emailsAreEqual: 'Email should be different from the current one.',
        consentKeeperNotAvailable: 'Cannot check if the Data Processing Agreement is signed. Please try again later.',
    },
    profile: {
        MfaControl: {
            mfaEnable: 'Send an enrollment email',
            mfaCheckEmail: 'Check the received email to complete enrollment.',
            mfaDisable: 'Disable MFA',
            mfaDisableSuccessToast: 'MFA has been disabled.',
            MfaDisableConfirmationDialog: {
                dialogTitle: 'Disable MFA',
                description: 'Disable multi-factor authentication?',
                currentPassword: 'Current password',
                otp: 'One-time code',
                otpDescription: 'One-time code for two-factor authentication provided by your authenticator app.',
                buttonSave: 'Confirm',
                buttonCancel: 'Cancel',
            },
        },
        AccountRemoval: {
            removeAccountButton: 'Delete Account',
            removeAccount: 'Delete your <b>{email}</b> account from WebPros Account?',
            removeAccountWithMembers: '{teamMembersCount, plural, one{Delete your <b>{email}</b> account and # organization member from WebPros Account?} other{Delete your <b>{email}</b> account and # organization members from WebPros Account?}}',
            removingAccount: 'Removing account...',
            loading: 'Loading...',
        },
        ChangePassword: {
            title: 'Password change',
            submit: 'Change password',
            labelCurrentPassword: 'Current Password',
            labelNewPassword: 'New Password',
            labelRepeatPassword: 'Confirm Password',
            forgotPassword: 'Forgot password?',
            successMessage: 'Your password has been changed',
            passwordMatch: 'The passwords you entered do not match each other.',
        },
        ChangeableEmail: {
            buttonEdit: 'Edit',
            dialogTitle: 'Change email',
            description: 'We will send you an email with instructions on how to update your address.',
            email: 'New email',
            currentPassword: 'Current password',
            otp: 'One-time code',
            otpDescription: 'One-time code used as part of two-factor authentication. You can find it in your authenticator application.',
            consentsTitle: 'Consent management',
            buttonSave: 'Submit',
            buttonCancel: 'Cancel',
            successMessage: 'We sent a verification email to <b>{email}</b>. Please follow the instruction to verify your new email.',
        },
        DpaManager: {
            title: 'Data Processing Agreement',
            accepted: 'Accepted',
            notAccepted: 'Not accepted',
            buttonEdit: 'Edit',
        },
        DpaDialog: {
            title: 'Data Processing Agreement',
            subtitle: 'To use some of our features, you need to accept the terms of the Data Processing Agreement (DPA).',
            dpaLinkText: 'Data Processing Agreement',
            buttonAccept: 'I Accept',
            buttonDecline: 'I Decline',
            successMessage: 'Data Processing Agreement has been successfully updated.',
        },
        ConsentManager: {
            error: 'An error has occurred while loading the consent data',
            successMessage: 'The consent data have been successfully updated',
            subscribed: 'Subscribed',
            notSubscribed: 'Not subscribed',
            buttonEdit: 'Edit',
            dialogTitle: 'Consent management',
            buttonSave: 'Save',
            consentTitles: {
                announce: 'Project updates and commercial communication',
            },
        },
        ChangeableName: {
            successMessage: 'Name successfully updated',
            updating: 'Updating',
            firstName: 'First name',
            lastName: 'Last name',
        },
        Timezone: {
            buttonEdit: 'Edit',
            timezone: 'Time Zone',
            dialogTitle: 'Change time zone',
            buttonCancel: 'Cancel',
            buttonSave: 'Save',
            successMessage: 'Time Zone successfully updated.',
        },
    },
    BusinessProfile: {
        title: 'Tell us a few things about yourself',
        save: 'Save',
        done: 'Done',
        answerLater: 'Answer later',
        tour: {
            gotIt: 'Got it',
            text: 'You can update the Business Profile later from the account menu.',
        },
        firstSectionTitle: 'Tell us about your business',
        secondSectionTitle: 'Tell us how you use Plesk',
        whoDoYouCreateWebsitesFor: 'Who do you create websites for?',
        clients: 'Clients',
        ownPurposes: 'Own purposes',
        whatKindOfBusinessDoYouDo: 'What does your business do?',
        hosting: 'Hosting',
        agency: 'Agency',
        isTheWebsiteForBusinessOrPrivatePurposes: 'Is your website for business or private use?',
        business: 'Business',
        private: 'Private',
        whatIsYourCoreBusiness: 'What is your core business?',
        webHosting: 'Web Hosting',
        vpsAndServerHosting: 'VPS & Server Hosting',
        iaas: 'Infrastructure as a Service Provider (IaaS)',
        saas: 'Software as a Service Provider (SaaS)',
        other: 'Other',
        webDevelopment: 'Web Development',
        webDesignOrManagement: 'Web Design and Management',
        marketingOrMedia: 'Marketing and Media',
        whichDepartmentDoYouBelongTo: 'Which department do you belong to?',
        itAndSystemAdministration: 'IT and System Administration',
        systemsIntegration: 'Systems Integration',
        softwareDevelopment: 'Software Development',
        marketingOrCommunication: 'Marketing and Communications',
        forWhichKindOfOrganisationIsTheWebsite: 'Who is the website for?',
        privatePersonOrFamily: 'Private Individual or Family',
        association: 'Association',
        nonProfitOrganisation: 'Non-Profit Organization',
        howManyEmployeesDoesYourCompanyHave: 'How many employees are in your company?',
        selfEmployed: 'Self-employed',
        howManyMembersDoesYourOrganisationHave: 'How many members are in your organization?',
        howManyServersDoYouManage: 'How many servers do you manage?',
        howManyServersDoYouManageDescription: 'with and without Plesk',
        areTheyYourServers: 'Do you own these servers?',
        yes: 'Yes, I own these servers.',
        no: 'No, I manage these servers for others.',
        partly: 'Both, I own some servers and manage servers for others.',
        whatIsYourMainPurposeOfPlesk: 'What do you use Plesk for?',
        whatIsYourMainPurposeOfPleskDescription: 'Choose any that apply',
        webhosting: 'Webhosting',
        emailHosting: 'Email Hosting',
        eCommerceHosting: 'E-Commerce Hosting',
        wordPressManagement: 'WordPress Management',
        systemAdministration: 'System Administration',
        collaboration: 'Collaboration',
        automation: 'Automation',
        reselling: 'Reselling',
        howWouldYouDescribeYourTechnicalKnowledge: 'How would you describe your technical knowledge?',
        notTechnicalAtAll: 'I am not technical at all.',
        basicKnowledge: 'I have basic Linux or Windows skills.',
        expert: 'I am an expert and I use Plesk for automation.',
    },
    ApiTokens: {
        title: 'API tokens',
        name: 'Name',
        creationDate: 'Generation time',
        lastUsageDate: 'Last used',
        tokenNeverUsed: 'Never',
        addButton: 'Generate API token',
        addButtonLimitExceededTooltip: 'The limit of API tokens is reached. Delete existing tokens to create new ones.',
        countTooltip: '{max, plural, one{Maximum # token per account.} other{Maximum # tokens per account.}}',
        totalAmount: 'total {itemsAmount}/{maxAmount} <infoIcon></infoIcon>',
        tokenAddingSuccess: 'Token <info>{name}</info> successfully generated. Be sure to save it now in a safe place. You will not be able to see it again!',
        emptyListTitle: 'Generate API tokens for your applications or scripts to access the WebPros Account API',
        emptyListDescription: 'Your API tokens will appear here.',
        createToken: 'Generate API token',
        successCopied: 'Copied to clipboard.',
        successTokenDelete: 'Token successfully deleted',
        expireAtTooltip: 'The token will expire at: {expireAt}.',
        AddTokenDialog: {
            title: 'New API token',
            nameLabel: 'Token name',
            nameDescription: 'What will this token be used for?',
            addButton: 'Generate',
            closeButton: 'Close',
            tokenInfo: 'Be sure to save it now in a safe place. You will not be able to see it again. If a token is compromised, generate a new one immediately. Your new token:',
            close: 'Close',
            tokenSuccessCopied: 'Token copied to clipboard.',
        },
        DeleteButton: {
            deleteButton: 'Delete token',
            deleteConfirmation: 'Are you sure you want to delete token <tooltip>{name}</tooltip>? Any application or script using this token will no longer be able to access the WebPros Account API.',
            copy: 'Copy to clipboard',
            deleteConfirmationInputDesc: 'Enter the name of the token to confirm the deletion.',
            deleteConfirmationButton: 'Delete token',
        },
    },
    UpdateEmail: {
        loadingText: 'Changing email...',
        ErrorMessage: {
            tryAgainButton: 'Try again',
            errors: {
                forbidden: 'The change email link has expired. Please try again.',
                changingEmailConflict: 'User with this email is already registered.',
                unexpected: 'The service is temporarily unavailable. Please try again later.',
            },
        },
    },
    HomePage: {
        WelcomeBanner: {
            title: 'Welcome to your WebPros Account!',
        },
        Profile: {
            nameField: 'Name',
            emailAddressField: 'Email address',
            passwordField: 'Password',
            changePasswordLink: 'Change password',
            timezoneField: 'Time zone',
            personalInfoSection: 'Personal Info',
            profilePictureSection: 'Profile picture',
            securitySection: 'Security',
            mfaField: 'Multi-factor authentication',
            agreementsSection: 'Agreements',
            changeGravatar: 'Configure your profile picture on <link>Gravatar</link>.',
            removeAccountButton: 'Remove my account',
            plesk360ConfigureName: 'Configure your name on your <link>Plesk 360 profile page</link>.',
            plesk360ConfigureEmail: 'Configure your email on your <link>Plesk 360 profile page</link>.',
            plesk360ConfigurePassword: 'Configure your password on your <link>Plesk 360 profile page</link>.',
            cpanelStoreConfigureName: 'Configure your name on your <link>cPanel profile page</link>.',
            cpanelStoreConfigureEmail: 'Configure your email on your <link>cPanel profile page</link>.',
            cpanelStoreConfigurePassword: 'Configure your password on your <link>cPanel profile page</link>.',
            googleConfigureName: 'Configure your name on your <link>Google account page</link>.',
            googleConfigureEmail: 'Configure your email on your <link>Google account page</link>.',
            googleConfigurePassword: 'Configure your password on your <link>Google account page</link>.',
            facebookConfigureName: 'Configure your name on your <link>Facebook account page</link>.',
            facebookConfigureEmail: 'Configure your email on your <link>Facebook account page</link>.',
            facebookConfigurePassword: 'Configure your password on your <link>Facebook account page</link>.',
        },
    },
};