// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        newInvitation: 'Приглашение для этого адреса электронной почты уже существует.',
        invitationMemberExist: 'Пользователь с таким адресом уже зарегистрирован как участник команды.',
        bounceOrComplaint: 'Этот почтовый адрес не существует или заблокировал наше сообщение как спам. Пожалуйста, обратитесь в службу поддержки Plesk 360.',
        emailsAreEqual: 'Адрес электронной почты должен отличаться от текущего.',
        consentKeeperNotAvailable: 'Не удалось проверить, подписано ли Соглашение на обработку данных. Повторите попытку позже.',
    },
    profile: {
        MfaControl: {
            mfaEnable: 'Отправить письмо для включения MFA',
            mfaCheckEmail: 'Проверьте почту для завершения включения MFA.',
            mfaDisable: 'Отключить MFA',
            mfaDisableSuccessToast: 'MFA отключена.',
            MfaDisableConfirmationDialog: {
                dialogTitle: 'Отключить MFA',
                description: 'Отключить многофакторную аутентификацию?',
                currentPassword: 'Текущий пароль',
                otp: 'Одноразовый код',
                otpDescription: 'Одноразовый код для двухфакторной аутентификации, сгенерированный вашим приложением-аутентификатором.',
                buttonSave: 'Подтвердить',
                buttonCancel: 'Отмена',
            },
        },
        AccountRemoval: {
            removeAccountButton: 'Удалить учетную запись',
            removeAccount: 'Удалить вашу учетную запись <b>{email}</b> из WebPros Account?',
            removeAccountWithMembers: '{teamMembersCount, plural, one{Удалить вашу учетную запись <b>{email}</b> и # участника организации из WebPros Account?} few {Удалить вашу учетную запись <b>{email}</b> и # участников организации из WebPros Account?} many {Удалить вашу учетную запись <b>{email}</b> и # участников организации из WebPros Account?} other{Удалить вашу учетную запись <b>{email}</b> и # участника организации из WebPros Account?}}',
            removingAccount: 'Удаление учетной записи...',
            loading: 'Загрузка...',
        },
        ChangePassword: {
            title: 'Изменение пароля',
            submit: 'Изменить пароль',
            labelCurrentPassword: 'Текущий пароль',
            labelNewPassword: 'Новый пароль',
            labelRepeatPassword: 'Подтвердите пароль',
            forgotPassword: 'Забыли пароль?',
            successMessage: 'Ваш пароль изменен',
            passwordMatch: 'Введенные пароли не совпадают.',
        },
        ChangeableEmail: {
            buttonEdit: 'Редактировать',
            dialogTitle: 'Изменить адрес электронной почты',
            description: 'Мы отправим вам письмо с инструкцией о том, как обновить адрес.',
            email: 'Новый адрес электронной почты',
            currentPassword: 'Текущий пароль',
            otp: 'Одноразовый код',
            otpDescription: 'Одноразовый код используется для двухфакторной аутентификации. Его можно найти в приложении-аутентификаторе.',
            consentsTitle: 'Управление подтверждениями',
            buttonSave: 'Отправить',
            buttonCancel: 'Отмена',
            successMessage: 'Мы отправили письмо с подтверждением на адрес <b>{email}</b>. Воспользуйтесь инструкцией, чтобы подтвердить новый адрес электронной почты.',
        },
        DpaManager: {
            title: 'Соглашение на обработку данных',
            accepted: 'Принято',
            notAccepted: 'Не принято',
            buttonEdit: 'Редактировать',
        },
        DpaDialog: {
            title: 'Соглашение на обработку данных',
            subtitle: 'Чтобы получить доступ к некоторым функциям, вы должны принять условия Соглашения на обработку данных (DPA).',
            dpaLinkText: 'Соглашение на обработку данных',
            buttonAccept: 'Принимаю',
            buttonDecline: 'Не принимаю',
            successMessage: 'Соглашение на обработку данных успешно обновлено.',
        },
        ConsentManager: {
            error: 'При попытке загрузить данные подтверждения произошла ошибка',
            successMessage: 'Данные подтверждения успешно обновлены',
            subscribed: 'Есть подписка',
            notSubscribed: 'Нет подписки',
            buttonEdit: 'Редактировать',
            dialogTitle: 'Управление подтверждениями',
            buttonSave: 'Сохранить',
            consentTitles: {
                announce: 'Получение обновлений и новостей проекта',
            },
        },
        ChangeableName: {
            successMessage: 'Имя успешно обновлено',
            updating: 'Обновление',
            firstName: 'Имя',
            lastName: 'Фамилия',
        },
        Timezone: {
            buttonEdit: 'Редактировать',
            timezone: 'Часовой пояс',
            dialogTitle: 'Изменить часовой пояс',
            buttonCancel: 'Отмена',
            buttonSave: 'Сохранить',
            successMessage: 'Часовой пояс обновлен.',
        },
    },
    BusinessProfile: {
        title: 'Расскажите нам немного о себе',
        save: 'Сохранить',
        done: 'Выполнено',
        answerLater: 'Ответить позже',
        tour: {
            gotIt: 'Понятно',
            text: 'Вы можете обновить ваш бизнес-профиль позже из меню учетной записи.',
        },
        firstSectionTitle: 'Расскажите нам о вашем бизнесе',
        secondSectionTitle: 'Расскажите нам, как вы используете Plesk',
        whoDoYouCreateWebsitesFor: 'Для чего вы создаете сайты?',
        clients: 'Клиенты',
        ownPurposes: 'Личные цели',
        whatKindOfBusinessDoYouDo: 'Какой бизнес вы ведете?',
        hosting: 'Хостинг',
        agency: 'Агентство',
        isTheWebsiteForBusinessOrPrivatePurposes: 'Этот сайт предназначен для бизнеса или персонального использования?',
        business: 'Бизнес',
        private: 'Частный',
        whatIsYourCoreBusiness: 'Каков ваш основной вид деятельности?',
        webHosting: 'Веб-хостинг',
        vpsAndServerHosting: 'Хостинг VPS и серверов',
        iaas: 'Поставщик инфраструктуры как услуги (IaaS)',
        saas: 'Поставщик программного обеспечения как услуги (SaaS)',
        other: 'Другое',
        webDevelopment: 'Веб-разработка',
        webDesignOrManagement: 'Веб-дизайн и управление сайтами',
        marketingOrMedia: 'Маркетинг и средства массовой информации',
        whichDepartmentDoYouBelongTo: 'В каком подразделении вы состоите?',
        itAndSystemAdministration: 'ИТ и системное администрирование',
        systemsIntegration: 'Системная интеграция',
        softwareDevelopment: 'Разработка ПО',
        marketingOrCommunication: 'Маркетинг и коммуникации',
        forWhichKindOfOrganisationIsTheWebsite: 'Для кого предназначен сайт?',
        privatePersonOrFamily: 'Частное лицо или семья',
        association: 'Ассоциация',
        nonProfitOrganisation: 'Некоммерческая организация',
        howManyEmployeesDoesYourCompanyHave: 'Сколько сотрудников в вашей компании?',
        selfEmployed: 'Самозанятый',
        howManyMembersDoesYourOrganisationHave: 'Сколько участников в вашей организации?',
        howManyServersDoYouManage: 'Каким количеством серверов вы управляете?',
        howManyServersDoYouManageDescription: 'с Plesk и без него',
        areTheyYourServers: 'Эти серверы принадлежат вам?',
        yes: 'Да, эти серверы принадлежат мне.',
        no: 'Нет, я управляю этими серверами для других.',
        partly: 'Некоторые серверы принадлежат мне, некоторыми я управляю для других.',
        whatIsYourMainPurposeOfPlesk: 'Для чего вы используете Plesk?',
        whatIsYourMainPurposeOfPleskDescription: 'Выберите любой подходящий вариант',
        webhosting: 'Веб-хостинг',
        emailHosting: 'Хостинг электронной почты',
        eCommerceHosting: 'Хостинг для электронной торговли',
        wordPressManagement: 'Управление WordPress',
        systemAdministration: 'Системное администрирование',
        collaboration: 'Совместная работа',
        automation: 'Автоматизация',
        reselling: 'Перепродажа',
        howWouldYouDescribeYourTechnicalKnowledge: 'Как бы вы описали свой уровень технических знаний?',
        notTechnicalAtAll: 'У меня минимум технических знаний.',
        basicKnowledge: 'Я обладаю базовыми навыками работы в Linux или Windows.',
        expert: 'Я специалист и использую Plesk для автоматизации.',
    },
    ApiTokens: {
        title: 'Токены API',
        name: 'Имя',
        creationDate: 'Время генерации',
        lastUsageDate: 'Последнее использование',
        tokenNeverUsed: 'Никогда',
        addButton: 'Создать токен API',
        addButtonLimitExceededTooltip: 'Достигнут лимит токенов API. Удалите существующие токены, чтобы создать новые.',
        countTooltip: '{max, plural, one{Макс. # токен на учетную запись.} few {Макс. # токена на учетную запись.} many {Макс. # токенов на учетную запись.} other{Макс. # токенов на учетную запись.}}',
        totalAmount: 'всего {itemsAmount}/{maxAmount} <infoIcon></infoIcon>',
        tokenAddingSuccess: 'Токен <info>{name}</info> успешно создан. Обязательно сохраните его сейчас в надежном месте. Вы больше не сможете его увидеть!',
        emptyListTitle: 'Создавайте токены API для своих приложений или скриптов для доступа к API WebPros Account',
        emptyListDescription: 'Ваши токены API появятся здесь.',
        createToken: 'Создать токен API',
        successCopied: 'Скопировано в буфер обмена.',
        successTokenDelete: 'Токен успешно удален',
        expireAtTooltip: 'Срок действия токена закончится: {expireAt}.',
        AddTokenDialog: {
            title: 'Новый токен API',
            nameLabel: 'Имя токена',
            nameDescription: 'Для чего будет использоваться этот токен?',
            addButton: 'Создать',
            closeButton: 'Закрыть',
            tokenInfo: 'Обязательно сохраните его сейчас в надежном месте. Вы больше не сможете его увидеть. Если токен скомпрометирован, немедленно создайте новый. Ваш новый токен:',
            close: 'Закрыть',
            tokenSuccessCopied: 'Токен скопирован в буфер обмена.',
        },
        DeleteButton: {
            deleteButton: 'Удалить токен',
            deleteConfirmation: 'Вы уверены, что хотите удалить токен <tooltip>{name}</tooltip>? Любое приложение или скрипт, использующие этот токен, больше не смогут получить доступ к API WebPros Account.',
            copy: 'Скопировать в буфер обмена',
            deleteConfirmationInputDesc: 'Введите имя токена, чтобы подтвердить удаление.',
            deleteConfirmationButton: 'Удалить токен',
        },
    },
    UpdateEmail: {
        loadingText: 'Изменение адреса электронной почты...',
        ErrorMessage: {
            tryAgainButton: 'Попробуйте еще раз',
            errors: {
                forbidden: 'Срок действия ссылки для смены адреса истек. Попробуйте еще раз.',
                changingEmailConflict: 'Пользователь с таким почтовым адресом уже зарегистрирован.',
                unexpected: 'Эта служба сейчас недоступна. Пожалуйста, повторите попытку позже.',
            },
        },
    },
    HomePage: {
        WelcomeBanner: {
            title: 'Добро пожаловать в WebPros Account!',
        },
        Profile: {
            nameField: 'Имя',
            emailAddressField: 'Адрес электронной почты',
            passwordField: 'Пароль',
            changePasswordLink: 'Изменить пароль',
            timezoneField: 'Часовой пояс',
            personalInfoSection: 'Личная информация',
            profilePictureSection: 'Изображение профиля',
            securitySection: 'Безопасность',
            mfaField: 'Многофакторная аутентификация',
            agreementsSection: 'Соглашения',
            changeGravatar: 'Настройте изображение профиля на <link>Gravatar</link>.',
            removeAccountButton: 'Удалить мою учетную запись',
            plesk360ConfigureName: 'Укажите свое имя на <link>странице профиля Plesk 360</link>.',
            plesk360ConfigureEmail: 'Укажите свою электронную почту на <link>странице профиля Plesk 360</link>.',
            plesk360ConfigurePassword: 'Настройте свой пароль на <link>странице профиля Plesk 360</link>.',
            cpanelStoreConfigureName: 'Укажите свое имя на <link>странице профиля cPanel</link>.',
            cpanelStoreConfigureEmail: 'Укажите свою электронную почту на <link>странице профиля cPanel</link>.',
            cpanelStoreConfigurePassword: 'Настройте свой пароль на <link>странице профиля cPanel</link>.',
            googleConfigureName: 'Укажите свое имя на <link>странице учетной записи Google</link>.',
            googleConfigureEmail: 'Укажите свою электронную почту на <link>странице учетной записи Google</link>.',
            googleConfigurePassword: 'Настройте свой пароль на <link>странице учетной записи Google</link>.',
            facebookConfigureName: 'Укажите свое имя на <link>странице учетной записи Facebook</link>.',
            facebookConfigureEmail: 'Укажите свою электронную почту на <link>странице учетной записи Facebook</link>.',
            facebookConfigurePassword: 'Настройте свой пароль на <link>странице учетной записи Facebook</link>.',
        },
    },
};