// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './WelcomeBanner.module.css';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export const WelcomeBanner = () => {
    const translate = useTranslate('accounts.HomePage.WelcomeBanner');

    return (
        <div className={styles.root}>
            <div className={styles.title}>{translate('title')}</div>
        </div>
    );
};
