// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useAuth } from '@platform360/libs/shared-web/auth';
import LandingPage from '@platform360/auth/web/components/LandingPage';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useEffect } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import withGuest from '@platform360/auth/web/components/withGuest';
import { TranslationProps } from '@plesk/ui-library';

const DPA_EMAIL = 'dpa@plesk.com';

export const Login = () => {
    const { login, error } = useAuth();
    const { errorToast } = useToaster();
    const translate = useTranslate();

    useEffect(() => {
        const errorMessagesMap: Record<string, TranslationProps> = {
            'email is required': translate('emailRequired'),
            'user is blocked': translate('userBlocked', {
                emailLink: <a href={`mailto:${DPA_EMAIL}`}>{DPA_EMAIL}</a>,
            }),
            'Sandbox Error: invalid_domain': translate('invalidDomain'),
        };

        if (error) {
            const defaultErrorMessage = translate(error.message, undefined, error.message);

            errorToast(errorMessagesMap[error.message] || defaultErrorMessage, {
                merge: true,
            });
        }
    }, [error, errorToast, translate]);

    return (
        <LandingPage
            onLogin={login}
            onSignUp={() =>
                login({
                    page: 'sign-up',
                })
            }
        />
    );
};

export default withGuest(Login);
