// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Jurídico',
        terms: 'Termos de uso',
        privacyPolicy: 'Política de Privacidade',
        dpa: 'Acordo de Processamento de Dados',
        optOuts: 'Desativações',
        cookies: 'Declaração de cookies',
        gaHeader: 'Desativação do Google Analytics',
        gaOptOut: 'Clique aqui para desativar o Google Analytics',
        cookieHeader: 'Ajustes de Consentimento de Cookies',
        cookieModify: 'Modifique seu consentimento de cookies',
        cookieWithdraw: 'Retirar seu consentimento de cookies',
        cookieSuccess: 'Retirada de consentimento bem-sucedida',
    },
};