// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';
import classNames from 'classnames';
import styles from './Public.module.less';
import ScrollToTop from './ScrollToTop';
import UserMenu from '@platform360/libs/shared-web/components/UserMenu';
import useApplicationSettings from '@platform360/libs/shared-web/application-settings/useApplicationSettings';
import Footer from '@platform360/libs/shared-web/components/Footer';

type PublicProps = {
    children: ReactNode;
    isWide?: boolean;
};

const Public = ({ children, isWide }: PublicProps) => {
    const { logo } = useApplicationSettings();

    return (
        <div className={styles.root}>
            <ScrollToTop />
            <div className={styles.head}>
                <Link to="/">{logo}</Link>
                <div className={styles.options}>
                    <UserMenu />
                    <LocaleSwitcher />
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.bodyOverlay}>
                    <div
                        className={classNames(styles.bodyI, {
                            [styles.bodyINarrow ?? '']: !isWide,
                        })}
                    >
                        {children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Public;
