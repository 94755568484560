// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './Header.module.css';
import WebprosLogo from '@platform360/libs/shared-web/components/WebprosLogo';
import { ReactNode } from 'react';

type HeaderProps = {
    addon?: ReactNode;
};

export const Header = ({ addon }: HeaderProps) => (
    <div className={styles.header}>
        <div className={styles.logo}>{<WebprosLogo width={120} />}</div>
        {addon}
    </div>
);
