// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        newInvitation: 'このメールへの招待は既に存在します。',
        invitationMemberExist: 'このメールアドレスを持つユーザは既にチームメンバーとして登録されています。',
        bounceOrComplaint: 'このメールアドレスは存在しないか、当社からのメッセージをスパムとしてブロックしています。Plesk 360 チームまでお問い合わせください。',
        emailsAreEqual: 'メールアドレスが現在のものと異なります。',
        consentKeeperNotAvailable: 'データ処理契約に署名したかどうかを確認できません。後でやり直してください。',
    },
    profile: {
        MfaControl: {
            mfaEnable: '登録メールを送信',
            mfaCheckEmail: '受信したメールを確認して登録を完了してください。',
            mfaDisable: 'MFA を無効にする',
            mfaDisableSuccessToast: 'MFA は無効化されました。',
            MfaDisableConfirmationDialog: {
                dialogTitle: 'MFA を無効にする',
                description: '多要素認証を無効化しますか？',
                currentPassword: '現在のパスワード',
                otp: 'ワンタイムコード',
                otpDescription: '認証アプリから提供された 2 要素認証用のワンタイムコード。',
                buttonSave: '確認',
                buttonCancel: 'キャンセル',
            },
        },
        AccountRemoval: {
            removeAccountButton: 'アカウントを削除',
            removeAccount: 'WebPros Account から <b>{email}</b> アカウントを削除しますか？',
            removeAccountWithMembers: '{teamMembersCount, plural, other{<b>{email}</b> アカウントと # 人の組織メンバーを WebPros Account から削除しますか？}}',
            removingAccount: 'アカウントを削除中...',
            loading: 'ロード中...',
        },
        ChangePassword: {
            title: 'パスワード変更',
            submit: 'パスワード変更',
            labelCurrentPassword: '現在のパスワード',
            labelNewPassword: '新しいパスワード',
            labelRepeatPassword: 'パスワード確認',
            forgotPassword: 'パスワードを忘れた場合',
            successMessage: 'パスワードが変更されました',
            passwordMatch: '入力されたパスワードが一致しません。',
        },
        ChangeableEmail: {
            buttonEdit: '編集',
            dialogTitle: 'メールアドレスを変更',
            description: 'メールアドレスの変更方法を説明するメールをお届けします。',
            email: '新しいメールアドレス',
            currentPassword: '現在のパスワード',
            otp: 'ワンタイムコード',
            otpDescription: '二段階認証の一部として使用されるワンタイムコードです。認証アプリケーションから取得できます。',
            consentsTitle: '同意の管理',
            buttonSave: '送信',
            buttonCancel: 'キャンセル',
            successMessage: '<b>{email}</b> 宛てに確認メールを送信しました。メールの指示に従って新しいメールアドレスを確認してください。',
        },
        DpaManager: {
            title: 'データ処理契約',
            accepted: '受諾しています',
            notAccepted: '受諾していません',
            buttonEdit: '編集',
        },
        DpaDialog: {
            title: 'データ処理契約',
            subtitle: '一部の機能を使用するためには、データ処理契約（DPA）の規約を受諾いただく必要があります',
            dpaLinkText: 'データ処理契約',
            buttonAccept: '受諾する',
            buttonDecline: '受諾しない',
            successMessage: 'データ処理契約が正常に更新されました。',
        },
        ConsentManager: {
            error: '同意データの読み込み中にエラーが発生しました',
            successMessage: '同意データが正常に更新されました',
            subscribed: '同意しています',
            notSubscribed: '同意していません',
            buttonEdit: '編集',
            dialogTitle: '同意の管理',
            buttonSave: '保存',
            consentTitles: {
                announce: 'プロジェクトのアップデートとプロモーション',
            },
        },
        ChangeableName: {
            successMessage: '名前が正常にアップデートされました',
            updating: 'アップデート中',
            firstName: '名',
            lastName: '姓',
        },
        Timezone: {
            buttonEdit: '編集',
            timezone: 'タイムゾーン',
            dialogTitle: 'タイムゾーンを変更',
            buttonCancel: 'キャンセル',
            buttonSave: '保存',
            successMessage: 'タイムゾーンが正常に更新されました。',
        },
    },
    BusinessProfile: {
        title: 'ご自分について少しだけ教えてください',
        save: '保存',
        done: '完了',
        answerLater: '後で答える',
        tour: {
            gotIt: '了解しました',
            text: 'アカウントメニューからビジネスプロフィールを更新できます。',
        },
        firstSectionTitle: 'ビジネスについて教えてください',
        secondSectionTitle: 'Plesk をどのように使用しているか教えてください',
        whoDoYouCreateWebsitesFor: 'ウェブサイトを作成する目的はなんですか？',
        clients: '顧客',
        ownPurposes: '独自の目的',
        whatKindOfBusinessDoYouDo: 'どのような事業を行っていますか？',
        hosting: 'ホスティング',
        agency: 'エージェンシー',
        isTheWebsiteForBusinessOrPrivatePurposes: 'ウェブサイトはビジネス用ですか、個人用ですか？',
        business: 'ビジネス',
        private: 'プライベート',
        whatIsYourCoreBusiness: '主な事業は何ですか？',
        webHosting: 'ウェブホスティング',
        vpsAndServerHosting: 'VPS とサーバホスティング',
        iaas: 'Infrastructure as a Service（IaaS）プロバイダ',
        saas: 'Software as a Service（SaaS）プロバイダ',
        other: 'その他',
        webDevelopment: 'ウェブ開発',
        webDesignOrManagement: 'ウェブデザインおよび管理',
        marketingOrMedia: 'マーケティングおよびメディア',
        whichDepartmentDoYouBelongTo: '所属部門はどちらですか？',
        itAndSystemAdministration: 'IT およびシステム管理',
        systemsIntegration: 'システムインテグレーション',
        softwareDevelopment: 'ソフトウェア開発',
        marketingOrCommunication: 'マーケティングおよびコミュニケーション',
        forWhichKindOfOrganisationIsTheWebsite: 'ウェブサイトはどのような人を対象にしていますか？',
        privatePersonOrFamily: '個人または家族',
        association: '協会',
        nonProfitOrganisation: '非営利団体',
        howManyEmployeesDoesYourCompanyHave: '社員は何人いますか？',
        selfEmployed: 'フリーランス',
        howManyMembersDoesYourOrganisationHave: '組織のメンバーは何人いますか？',
        howManyServersDoYouManage: '管理しているサーバの数はいくつですか？',
        howManyServersDoYouManageDescription: 'Plesk あり/なし',
        areTheyYourServers: 'あなたはこれらのサーバの所有者ですか？',
        yes: 'はい。私が所有者です。',
        no: 'いいえ。他の人のために管理しています。',
        partly: '両方。私はこれらのサーバの所有者であり、他の人のためにサーバの管理もしています。',
        whatIsYourMainPurposeOfPlesk: 'Plesk を何に使用していますか？',
        whatIsYourMainPurposeOfPleskDescription: '該当するものをすべて選択してください。',
        webhosting: 'ウェブホスティング',
        emailHosting: 'メールのホスティング',
        eCommerceHosting: 'EC サイトのホスティング',
        wordPressManagement: 'WordPress 管理',
        systemAdministration: 'システム管理',
        collaboration: 'コラボレーション',
        automation: '自動化',
        reselling: '再販',
        howWouldYouDescribeYourTechnicalKnowledge: 'ご自分の技術的な知識について説明してください。',
        notTechnicalAtAll: '技術的知識はまったくない。',
        basicKnowledge: 'Linux または Windows の基本スキルはある。',
        expert: '私はエキスパートであり、Plesk を自動化に使用している。',
    },
    ApiTokens: {
        title: 'API トークン',
        name: '名前',
        creationDate: '生成時間',
        lastUsageDate: '前回の使用',
        tokenNeverUsed: 'なし',
        addButton: 'API トークンを生成',
        addButtonLimitExceededTooltip: 'API トークンの上限に達しました。新しいトークンを作成するには、既存のトークンを削除してください。',
        countTooltip: '{max, plural, other{1 アカウントにつきトークンは最大 # 件です。}}',
        totalAmount: '合計 {itemsAmount}/{maxAmount} <infoIcon></infoIcon>',
        tokenAddingSuccess: 'トークン <info>{name}</info> が正常に生成されました。今すぐ安全な場所に保存しておいてください。もう一度表示されることはありません。',
        emptyListTitle: 'WebPros Account API にアクセスするための API トークンをアプリケーションまたはスクリプト用に生成します',
        emptyListDescription: 'API トークンはここに表示されます。',
        createToken: 'API トークンを生成',
        successCopied: 'クリップボードにコピーしました。',
        successTokenDelete: 'トークンが正常に削除されました',
        expireAtTooltip: 'トークンの有効期限: {expireAt}。',
        AddTokenDialog: {
            title: '新しい API トークン',
            nameLabel: 'トークン名',
            nameDescription: 'このトークンの使用目的は何ですか？',
            addButton: '生成',
            closeButton: '終了',
            tokenInfo: 'トークンは一度しか表示されないため、今すぐ安全な場所に保存しておいてください。トークンが流出した場合、すぐに新しいトークンを生成してください。新しいトークンは以下のとおりです：',
            close: '終了',
            tokenSuccessCopied: 'トークンがクリップボードにコピーされました。',
        },
        DeleteButton: {
            deleteButton: 'トークンを削除',
            deleteConfirmation: 'トークン <tooltip>{name}</tooltip> を削除してよろしいですか？このトークンを使用するすべてのアプリケーションまたはスクリプトが WebPros Account API にアクセスできなくなります。',
            copy: 'クリップボードにコピー',
            deleteConfirmationInputDesc: '削除を確定するには、トークンの名前を入力してください。',
            deleteConfirmationButton: 'トークンを削除',
        },
    },
    UpdateEmail: {
        loadingText: 'メールアドレスを変更中...',
        ErrorMessage: {
            tryAgainButton: 'やり直してください',
            errors: {
                forbidden: 'メールアドレスの変更リンクの有効期限が切れました。やり直してください。',
                changingEmailConflict: 'このメールアドレスのユーザーは既に登録されています。',
                unexpected: 'サービスが一時的に使用できません。後でやり直してください。',
            },
        },
    },
    HomePage: {
        WelcomeBanner: {
            title: 'WebPros Account へようこそ！',
        },
        Profile: {
            nameField: '名前',
            emailAddressField: 'メールアドレス',
            passwordField: 'パスワード',
            changePasswordLink: 'パスワード変更',
            timezoneField: 'タイムゾーン',
            personalInfoSection: '個人情報',
            profilePictureSection: 'プロフィール写真',
            securitySection: 'セキュリティ',
            mfaField: '多要素認証',
            agreementsSection: '同意',
            changeGravatar: '<link>Gravatar</link> でプロフィール写真を設定してください。',
            removeAccountButton: 'アカウントを削除',
            plesk360ConfigureName: '<link>Plesk 360 プロフィールページ</link>で名前を設定してください。',
            plesk360ConfigureEmail: '<link>Plesk 360 プロフィールページ</link>でメールアドレスを設定してください。',
            plesk360ConfigurePassword: '<link>Plesk 360 プロフィールページ</link>でパスワードを設定してください。',
            cpanelStoreConfigureName: '<link>cPanel プロフィールページ</link>で名前を設定してください。',
            cpanelStoreConfigureEmail: '<link>cPanel プロフィールページ</link>でメールアドレスを設定してください。',
            cpanelStoreConfigurePassword: '<link>cPanel プロフィールページ</link>でパスワードを設定してください。',
            googleConfigureName: '<link>Google アカウントページ</link>で名前を設定してください。',
            googleConfigureEmail: '<link>Google アカウントページ</link>でメールアドレスを設定してください。',
            googleConfigurePassword: '<link>Google アカウントページ</link>でパスワードを設定してください。',
            facebookConfigureName: '<link>Facebook アカウントページ</link>で名前を設定してください。',
            facebookConfigureEmail: '<link>Facebook アカウントページ</link>でメールアドレスを設定してください。',
            facebookConfigurePassword: '<link>Facebook アカウントページ</link>でパスワードを設定してください。',
        },
    },
};