// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'O token de autenticação está ausente ou não é válido.',
        invitationNotExist: 'O convite não existe.',
        invitationExpired: 'O convite expirou.',
        invitationConflict: 'O usuário convidado já está registrado.',
    },
    SignTermsOfUse: {
        title: 'Privacidade e Termos',
        descriptionText1: 'Para criar uma conta WebPros, você deve concordar com os Termos de Uso e a Política de Privacidade da WebPros.',
        descriptionText2: 'Você também pode assinar nosso boletim informativo para receber atualizações de produtos e ofertas individuais.',
        privacyPolicyAgreementTitle: 'Concordo com os <termsLink>Termos de Uso</termsLink>. Entendo que a WebPros processará meus dados pessoais de acordo com a <privacyLink>Política de Privacidade</privacyLink> da WebPros',
        continueBtn: 'Continuar',
        errors: {
            invalidToken: 'Sua sessão atual expirou. Por favor, <a>faça login novamente</a> para continuar.',
        },
    },
    ChangePassword: {
        successText: 'Sua senha foi alterada com sucesso. Clique no botão abaixo para fazer login.',
        loginBtn: 'Log in',
    },
    ChangePasswordForm: {
        setTitle: 'Definir senha',
        passwordLabel: 'Nova senha',
        submitButton: 'Confirmar',
        description: 'Digite sua nova senha e confirme-a. Deve conter pelo menos 8 caracteres.',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'E-mail de redefinição de senha enviado',
        successSendResetText: 'Enviamos um e-mail para {email} com um link para redefinir sua senha.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Link expirado',
        resetText: 'O link de redefinição de senha expirou.',
        setText: 'O link para definir a senha não está mais válido. Para sua segurança, o link para definir uma senha é válido por 4 horas. Se você não teve tempo de usar o link para definir sua senha, você pode inserir seu e-mail novamente abaixo e definir a senha por meio do procedimento de alteração de senha.',
        emailLabel: 'E-mail:',
        submitButton: 'Enviar',
        bottomText: 'Você se lembra da senha? Você pode <a>Faça o login agora</a>.',
    },
    LandingPage: {
        signUpButton: 'Crie uma conta WebPros',
        signInButton: 'Log in',
        title: 'Ecossistema de Habilitação com WebPros',
        description: 'Sua conta WebPros conecta todos os seus produtos e serviços WebPros. Faça o login para gerenciar sua conta.',
    },
    ResetPassword: {
        emailLabel: 'E-mail',
        backBtn: 'Voltar',
        nextBtn: 'Próximo',
        description: 'Insira seu endereço de e-mail e nós lhe enviaremos um e-mail com um link para redefinir sua senha.',
        successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
        successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
        resendButton: 'Reenviar',
        retryCountdown: 'Reenviar e-mail em {time}',
    },
};