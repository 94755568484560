// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Form, FormErrors, FormFieldPassword } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type ChangePasswordFormProps = {
    onSubmit: (password: string) => void;
    errors?: FormErrors;
    isLoading: boolean;
    isSuccess: boolean;
};

type FormValues = {
    password: string;
};

const DataTestIds = {
    FORM: 'ChangePassword--form',
};

export const ChangePasswordForm = ({
    onSubmit,
    isLoading,
    isSuccess,
    errors,
}: ChangePasswordFormProps) => {
    const translate = useTranslate('auth.ChangePasswordForm');

    const handleSubmit = (values: FormValues) => onSubmit(values.password);

    return (
        <Form
            onSubmit={handleSubmit}
            state={isLoading ? 'submit' : undefined}
            errors={errors}
            applyButton={false}
            cancelButton={false}
            submitButton={{
                children: translate('submitButton'),
                type: 'submit',
                disabled: isSuccess,
                fill: true,
            }}
            vertical
            data-type={DataTestIds.FORM}
        >
            {translate('description')}
            <FormFieldPassword
                name="password"
                label={translate('passwordLabel')}
                size="fill"
                autoFocus
            />
        </Form>
    );
};

export default ChangePasswordForm;
