// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'Jeton d\'authentification absent ou non valide.',
        invitationNotExist: 'Invitation inexistante.',
        invitationExpired: 'L\'invitation a expiré.',
        invitationConflict: 'L\'utilisateur invité est déjà enregistré.',
    },
    SignTermsOfUse: {
        title: 'Confidentialité et Conditions',
        descriptionText1: 'Pour créer un WebPros Account, vous devez accepter les Conditions d\'utilisation et la Politique de confidentialité de WebPros.',
        descriptionText2: 'Vous pouvez aussi vous abonner à notre newsletter pour recevoir les informations concernant les mises à jour de produits et des offres personnalisées.',
        privacyPolicyAgreementTitle: 'J\'accepte les <termsLink>Conditions d\'utilisation</termsLink>. Je comprends que WebPros traitera mes données personnelles conformément à la <privacyLink>Politique de confidentialité</privacyLink> de WebPros.',
        continueBtn: 'Continuer',
        errors: {
            invalidToken: 'Votre session actuelle a expiré. Veuillez vous <a>connecter à nouveau</a> pour continuer.',
        },
    },
    ChangePassword: {
        successText: 'Votre mot de passe a bien été modifié. Cliquez sur le bouton ci-dessous pour vous connecter.',
        loginBtn: 'Se connecter',
    },
    ChangePasswordForm: {
        setTitle: 'Définir le mot de passe',
        passwordLabel: 'Nouveau mot de passe',
        submitButton: 'Confirmer',
        description: 'Saisissez votre nouveau mot de passe et confirmez-le. Il doit comporter au moins 8 caractères.',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'E-mail de réinitialisation du mot de passe envoyé',
        successSendResetText: 'Nous avons envoyé un e-mail avec un lien de réinitialisation pour votre mot de passe à l\'adresse {email}.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Lien obsolète',
        resetText: 'Le lien de réinitialisation du mot de passe a expiré.',
        setText: 'Le lien de réinitialisation du mot de passe n\'est plus valide. Pour votre sécurité, le lien de réinitialisation du mot de passe envoyé par e-mail est valide pendant 4 heures. Si vous n\'avez pas eu le temps d\'utiliser le lien pour définir votre mot de passe, vous pouvez à nouveau saisir votre adresse e-mail ci-dessous et définir le mot de passe en suivant la procédure de modification du mot de passe.',
        emailLabel: 'E-mail :',
        submitButton: 'Envoyer',
        bottomText: 'Vous souvenez-vous de votre mot de passe ? <a>Connectez-vous</a>.',
    },
    LandingPage: {
        signUpButton: 'Créer un compte WebPros',
        signInButton: 'Se connecter',
        title: 'Écosystème d\'intégration web avec WebPros',
        description: 'Votre compte WebPros connecte l\'ensemble de vos produits et services. Connectez-vous pour gérer votre compte.',
    },
    ResetPassword: {
        emailLabel: 'E-mail',
        backBtn: 'Retour',
        nextBtn: 'Suivant',
        description: 'Saisissez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
        successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
        successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
        resendButton: 'Renvoyer',
        retryCountdown: 'Renvoyer l\'e-mail dans {time}',
    },
};