// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { Dialog, Paragraph, FormFieldPassword, FormFieldText, FormErrors } from '@plesk/ui-library';
import styles from './MfaDisableConfirmationDialog.module.css';

type FormValues = {
    currentPassword: string;
    otp: string;
};

type MfaDisableConfirmationDialogProps = {
    isOpen: boolean;
    isLoading: boolean;
    errors?: FormErrors;
    onCancel: () => void;
    onSubmit: (values: FormValues) => void;
};

const MfaDisableConfirmationDialog = ({
    isOpen,
    isLoading,
    errors,
    onSubmit,
    onCancel,
}: MfaDisableConfirmationDialogProps) => {
    const translate = useTranslate('accounts.profile.MfaControl.MfaDisableConfirmationDialog');

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onCancel}
            title={translate('dialogTitle')}
            size="xs"
            data-type="email-edit-dialog"
            form={{
                state: isLoading ? 'submit' : undefined,
                errors,
                onSubmit,
                submitButton: {
                    children: translate('buttonSave'),
                },
                cancelButton: {
                    children: translate('buttonCancel'),
                },
            }}
        >
            <Paragraph>{translate('description')}</Paragraph>
            <FormFieldPassword
                label={translate('currentPassword')}
                name="currentPassword"
                size="lg"
                required
                hideGenerateButton
                hidePasswordMeter
            />
            <FormFieldText
                className={styles.otp}
                label={translate('otp')}
                name="otp"
                size="lg"
                required
                description={translate('otpDescription')}
                inputProps={{
                    type: 'number',
                }}
            />
        </Dialog>
    );
};

export default MfaDisableConfirmationDialog;
