// Copyright 2024. WebPros International GmbH. All rights reserved.

import styles from './ResetPassword.module.css';
import AuthLayout from '@platform360/libs/shared-web/components/AuthLayout';
import Header from '@platform360/libs/shared-web/components/Header';
import RetryTimer, { formatSeconds } from '@platform360/libs/shared-web/components/RetryTimer';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';
import { Button, Form, FormFieldText, Paragraph } from '@plesk/ui-library';
import { useResetPassword } from '@platform360/auth/web/mutations';
import { useState } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { RESET_PASSWORD_TTL } from '@platform360/libs/common/throttle-limits';

export const ResetPassword = () => {
    const { login } = useAuth();
    const translate = useTranslate('auth.ResetPassword');
    const [email, setEmail] = useState<string>('');
    const [complete, setComplete] = useState(false);
    const [resendAvailable, setResendAvailable] = useState(false);

    const {
        mutate: resetPassword,
        isPending: isLoading,
        errors,
    } = useResetPassword(() => {
        setComplete(true);
        setResendAvailable(false);
    });

    const handleSubmit = ({ email }: { email: string }) => resetPassword(email);
    const handleResend = () => resetPassword(email);

    const handleLogin = () => {
        void login();
    };

    const handleResendEnable = () => setResendAvailable(true);

    const renderForm = () => (
        <>
            {translate('description')}
            <Form
                footerClassName={styles.footer}
                onSubmit={handleSubmit}
                errors={errors}
                state={isLoading ? 'submit' : undefined}
                additionalButtons={[
                    <Button key="back-btn" size="md" onClick={handleLogin}>
                        {'⟵'}&nbsp;
                        {translate('backBtn')}
                    </Button>,
                    <Button key="next-btn" intent="primary" size="md" type="submit">
                        {translate('nextBtn')}
                    </Button>,
                ]}
                cancelButton={false}
                submitButton={false}
                applyButton={false}
                vertical
            >
                <FormFieldText
                    name="email"
                    label={translate('emailLabel')}
                    size="fill"
                    onChange={setEmail}
                    autoFocus
                />
            </Form>
        </>
    );

    const renderSuccess = () => (
        <>
            <Paragraph>{translate('successText1', { email })}</Paragraph>
            <Paragraph>{translate('successText2')}</Paragraph>
            <Button
                intent="primary"
                onClick={handleResend}
                state={isLoading ? 'loading' : undefined}
                disabled={!resendAvailable}
                className={styles.resendBtn}
                fill
            >
                {translate('resendButton')}
            </Button>
            {!resendAvailable && (
                <RetryTimer onTimeEnd={handleResendEnable} retryInterval={RESET_PASSWORD_TTL}>
                    {({ seconds }: { seconds: number }) => (
                        <span className={styles.retryCountdown}>
                            {translate('retryCountdown', {
                                time: formatSeconds(seconds),
                            })}
                        </span>
                    )}
                </RetryTimer>
            )}
        </>
    );

    return (
        <AuthLayout className={styles.root}>
            <Header addon={<LocaleSwitcher />} />
            {complete ? renderSuccess() : renderForm()}
        </AuthLayout>
    );
};
