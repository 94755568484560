// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Copiato negli appunti.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Stai impersonando un altro account.',
            depersonateButton: 'Smetti d\'impersonare',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Non stai più impersonando un altro account.',
        },
        UserMenu: {
            team: 'Organizzazione',
            logout: 'Disconnetti',
            changePassword: 'Cambiare password',
            businessProfile: 'Profilo aziendale',
            apiTokens: 'Token API',
        },
    },

    common: {
        errors: {
            isNotEmpty: 'Questo campo obbligatorio è vuoto.',
            required: 'Questo campo obbligatorio è vuoto.',
            email: 'Il valore deve essere un indirizzo e-mail valido.',
            isEmail: 'Il valore deve essere un indirizzo e-mail valido.',
            min: '{min, plural, one{Deve contenere almeno # carattere.} other{Deve contenere almeno # caratteri.}}',
            maxLength: '{max, plural, one{Deve contenere al massimo # carattere.} other{Deve contenere al massimo # caratteri.}}',
            unique: 'Il valore deve essere unico.',
            unknown: 'Errore sconosciuto: {message}',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Devi inserire una password.',
            errorPasswordIsWrong: 'Password errata.',
            errorPasswordIsTooWeak: 'La password è troppo debole.',
            errorPasswordContainsPersonalInfo: 'La password non può contenere dati personali.',
            errorPasswordTooCommon: 'La password è troppo comune.',
            errorPasswordPreviouslyUsed: 'La password è stata già usata in precedenza.',
            errorInvalidOtp: 'Il codice inserito non è valido.',
        },
    },

    ConfirmationPopover: {
        cancel: 'Annulla',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'WebPros Account fa uso di ZendeskChat, un\'applicazione di terze parti.',
            description2: 'Procedendo, acconsento all\'utilizzo di ZendeskChat, un\'applicazione di terze parti. Ciò potrebbe comportare il trasferimento dei miei dati personali (ad esempio l\'indirizzo IP) a terzi all\'interno o all\'esterno dell\'Europa. È possibile consultare l\'<a>Informativa sulla privacy</a> per maggiori informazioni.',
            confirmButton: 'Avvia ZendeskChat',
        },
        supportFormPopover: {
            description: 'Non ci sono operatori disponibili al momento. Ti preghiamo di attendere o usare il modulo ticket per inviare una richiesta.',
            confirmButton: 'Apri modulo',
        },
        ChatButton: {
            title: 'Supporto',
        },
    },

    emailRequired: "To log in to WebPros using your Facebook account, access to view the email address must be granted. Make sure that the corresponding permission is granted and try again.",
    userBlocked: "L'account è stato sospeso a causa di una violazione dell'Accordo con l'utente. Se pensi che si tratti di un errore, scrivici a {emailLink}.",
    invalidDomain: 'Accesso negato. L\'e-mail dell\'utente non è presente nella whitelist.',

    errorDefault: 'Si è verificato un problema. Riprova più tardi.',
    errorDefaultWithReqId: 'Qualcosa non ha funzionato. Riprova più tardi. ID richiesta: {requestId}.',
    errorTooManyRequests: 'Il limite di chiamate API è stato raggiunto per questo indirizzo IP. Riprova tra un minuto.',
    errorTokenRenewal: 'Sessione scaduta. Effettua nuovamente l\'accesso.',
    errorNetworkIssue: 'Sembra che tu stia avendo problemi a collegarti alla rete.',

    errorCopyToClipboard: 'Si è verificato un errore di copia, prova a copiare manualmente.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH tratterà i dati da te forniti per elaborare la tua richiesta in conformità con la sua <a>Informativa sulla privacy</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'L\'utente non esiste.',
            bounceOrComplaint: 'Questo indirizzo e-mail non esiste o il messaggio è stato bloccato come spam. <webProsContactLink>Contatta</webProsContactLink> il team di supporto di WebPros Account.',
            userIsNotVerified: 'L\'utente esiste ma non è stato verificato. Abbiamo appena inviato una nuova e-mail di verifica. Controlla la tua casella di posta elettronica.',
            userExist: 'L\'utente esiste già.',
            errorPasswordIsEmpty: 'La password è obbligatoria.',
            errorPasswordIsWrong: 'Password errata.',
            errorPasswordIsTooWeak: 'La password è troppo debole.',
        },
        AuthContainer: {
            alreadyHaveAnAccount: 'Hai già un account?',
            loginLink: 'Accedi',
        },
        SignUp: {
            signUp: 'Crea un account',
            emailLabel: 'E-mail',
            passwordLabel: 'Password',
            privacyPolicyAgreementTitle: 'Accetto i <termsLink>Termini di utilizzo</termsLink> e autorizzo WebPros a elaborare i dati personali da me forniti nei suoi sistemi interni, in conformità con la sua <privacyLink>Informativa sulla privacy</privacyLink>',
            SignUpByInvitation: {
                emailDescription: 'Ti registrerai utilizzando l\'indirizzo email qui sopra',
                invitationSucceed: 'Registrazione effettuata con successo. Accedi.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Nome',
                    lastNamePlaceholder: 'Cognome',
                    nameLabel: 'Nome',
                },
            },
        },
        ResetPasswordDrawer: {
            title: 'Reimposta password',
            text: 'Inserisci il tuo indirizzo e-mail e ti invieremo un\'e-mail con un link di reimpostazione password.',
            emailLabel: 'E-mail:',
            submitButton: 'Invia',
            successTitle: 'E-mail di reimpostazione password inviata',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Rinvio',
            loginForm: 'Accedi ora',
            retryCountdown: 'La ripetizione dell’invio sarà possibile tra {time}',
        },
    },
    Footer: {
        copyright: '© {year} WebPros International GmbH. Tutti i diritti riservati.',
        company: 'Società',
        knowledgeBase: 'Base di Conoscenza',
        aboutWebPros: 'Informazioni su WebPros',
        legal: 'Legale',
        cookieSettings: 'Impostazioni cookie',
        privacyPolicy: 'Informativa sulla privacy',
        documentation: 'Documentazione',
        contactUs: 'Contattaci',

    },
    Form: {
        requiredLegend: 'Campi richiesti',
        submitButton: 'OK',
        cancelButton: 'Annulla',
    },

    FormField: {
      addMore: 'Aggiungine un altro',
      remove: 'Eliminare',
      clear: 'Cancella',
    },

    FormFieldPassword: {
        improvePassword: 'Per una password più sicura:',
        passwordTooShort: 'Aggiungi caratteri alla password.',
        lettersLowerCase: 'Usa almeno un carattere minuscolo.',
        lettersUpperCase: 'Usa almeno un carattere maiuscolo.',
        numbers1: 'Usa almeno un numero.',
        numbers3: 'Usa almeno tre numeri.',
        specialChar1: 'Usa almeno un carattere speciale (per esempio: !,@,#,$,%,^,&,*,?,_,~).',
        specialChar2: 'Usa almeno due caratteri speciali (per esempio: !,@,#,$,%,^,&,*,?,_,~).',
        comboUpperAndLower: 'Usa maiuscole e minuscole.',
        comboLettersAndNumbers: 'Usa sia numeri che caratteri.',
        comboLettersNumbersSpecial: 'Usa numeri, lettere e caratteri speciali.',
        yourPasswordIsStrong: 'Puoi renderla ancora più forte aumentando la sua lunghezza.',
        passwordStrength: 'La forza della password è %%strength%%.',
        strengthVeryStrong: 'MOLTO SICURA',
        strengthStrong: 'SICURA',
        strengthVeryWeak: 'MOLTO DEBOLE',
        strengthWeak: 'DEBOLE',
        strengthMedium: 'MEDIO',
        hidePassword: 'Nascondi password',
        showPassword: 'Mostra password',
        generateButton: 'Genera',
        generateButtonHint: 'Genera una password sicura',
    },

    Pagination: {
        totalShort: 'di %%total%%',
        itemsPerPage: 'Elementi per pagina',
        all: 'Tutti',
        total: 'Pagina %%current%% di %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Impossibile trovare elementi corrispondenti al filtro',
        emptyTitle: 'Sembra che non ci sia nulla qui',
        filteredDescription: 'Perfezionare la query o rimuovere alcuni criteri dal filtro e riprovare.',
    },

    Dialog: {
        cancelButton: 'Annulla',
    },

    GaOptOutLink: {
        gaSuccess: 'Google Analytics ha interrotto il suo monitoraggio.',
    },

    InPlaceText: {
        ok: 'Salvare (Ctrl+Enter)',
        cancel: 'Annullare (Esc)',
        edit: 'Modificare',
        add: 'OK',
    },

    Team: {
        Layout: {
            title: 'Organizzazione',
            members: 'Utenti',
            invitations: 'Inviti',
        },
        settings: 'Impostazioni',
        Members: {
            email: 'E-mail',
            fullName: 'Nome e cognome',
            mfaStatus: 'Stato MFA',
            tags: 'Segni speciali',
            remove: 'Eliminare',
            removeMemberConfirmation: '<b>{name}</b> non avrà più accesso all\'organizzazione e alle risorse condivise al suo interno, ai server, ai siti web e alle licenze.',
            SpecialMarks: {
                you: 'Tu',
                owner: 'Proprietario',
            },
            Onboarding: {
                title: 'Crea la tua organizzazione sulla nostra piattaforma aggiungendo i tuoi colleghi',
                description: 'La nostra piattaforma ti offre funzionalità per una gestione efficiente della tua organizzazione e per la sua collaborazione. I membri della tua organizzazione possono lavorare con te sui server collegati accedendovi direttamente da qui. Non è più necessario utilizzare password condivise non sicure o complicate modalità di accesso.',
                addMembersButton: 'Aggiungi membri dell\'organizzazione',
            },
            MultifactorStatus: {
                enabled: 'MFA abilitato',
                disabled: 'MFA non abilitata',
            },
        },
        Invitations: {
            email: 'E-mail',
            expireAt: 'Scade il',
            expired: 'Scaduta',
            add: 'OK',
            revoke: 'Revocare',
            Onboarding: {
                title: 'Qui puoi invitare i membri della tua organizzazione',
                description: 'Aggiungi i tuoi colleghi per condividere con loro server e siti web.',
                addButton: 'Invita un nuovo membro',
            },
            ListActions: {
                resendInvitationTooltip: 'Invita di nuovo.',
                successMessage: 'Email d\'invito inviata nuovamente.',
            },
            AddInvitations: {
                title: 'Aggiungi nuovi membri all\'organizzazione',
                infoMessage: 'Invita i membri dell\'organizzazione a entrare inserendo i loro indirizzi email professionali di seguito.',
                infoDescription: 'Ti consigliamo di inserire gli indirizzi email professionali per proteggere i dati personali degli utenti.',
                emailsLabel: 'Indirizzi e-mail per il link di invito',
                submitButton: 'Invia invito',
                successMessage: '{count, plural, =1{L\'invito è stato inviato a {email}.} one{È stato inviato # invito.} other{Sono stati inviati # inviti.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Revocare',
                noSelectedInvitations: 'Seleziona uno o più inviti da revocare.',
                revokeInvitationConfirmation: '{count, plural, =1{Vuoi revocare l\'invito a <b>{email}</b>?} one{Vuoi revocare <b>#</b> invito?} other{Vuoi revocare <b>#</b> inviti?}} Quest\'azione non può essere annullata.',
                successRevokeInvitation: '{count, plural, =1{L\'invito a <b>{email}</b> è stato revocato.} one{È stato revocato <b>#</b> invito.} other{Sono stati revocati <b>#</b> inviti.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'Acconsento alla ricezione da parte di WebPros di newsletter e offerte individuali in conformità con la sua <a>Informativa sulla privacy</a>.',
            text: 'Potrò revocare tale consenso in qualunque momento facendo clic sul link presente nelle comunicazioni che riceverò',
        },
    },

    components: {
        List: {
            selectAll: 'Seleziona tutto',
            deselectAll: 'Deseleziona',
            selectedRows: '{selectedRows, plural, one{Hai selezionato # di {totalRows, plural, one{# elemento} other{# elementi}}} other{Hai selezionato # di {totalRows, plural, one{# elemento} other{# elementi}}}}',
            totalRows: '{totalRows, plural, one {# elemento in totale} other {# elementi in totale}}',
            foundRows: '{foundRows, plural, one {# elemento trovato} other {# elementi trovati}}',
        },
        DataList: {
            groupSearch: 'Cerca',
        },
    },
};