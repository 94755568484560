// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { updateEmail } from '@platform360/accounts/web/api/me';

type UseUpdateEmailMutationOptions = {
    onSuccess: (data: { email: string }) => void;
};

export const useUpdateEmailMutation = (options: UseUpdateEmailMutationOptions) =>
    useMutation({ ...options, mutationFn: updateEmail });
