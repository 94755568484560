// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useLocation, Link, Outlet } from 'react-router-dom';
import { Tabs, Tab } from '@plesk/ui-library';
import Layout from '@platform360/libs/shared-web/components/Public';
import styles from './Legal.module.css';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

const legalPath = '/legal';

const LegalLayout = () => {
    const translate = useTranslate('app.Legal');

    const pages = [
        {
            url: 'terms',
            title: translate('terms'),
            icon: 'four-squares',
        },
        {
            url: 'privacy-policy',
            title: translate('privacyPolicy'),
            icon: 'eye-closed',
        },
        {
            url: 'data-processing-agreement',
            title: translate('dpa'),
            icon: 'database',
        },
        {
            url: 'opt-outs',
            title: translate('optOuts'),
            icon: 'check-mark-circle',
        },
        {
            url: 'cookie-statement',
            title: translate('cookies'),
            icon: 'check-list',
        },
    ] as const;

    const { pathname } = useLocation();
    const activeTab = pages.findIndex(({ url }) => pathname.includes(url)) + 1;
    return (
        <Layout isWide>
            <div className={styles.panel}>
                <Tabs active={activeTab}>
                    {pages.map(({ url, title, icon }) => (
                        <Tab
                            key={url}
                            title={title}
                            icon={icon}
                            component={Link}
                            to={`${legalPath}/${url}`}
                        />
                    ))}
                </Tabs>
                <Outlet />
            </div>
        </Layout>
    );
};

export default LegalLayout;
