// Copyright 2024. WebPros International GmbH. All rights reserved.

import shared from '@platform360/libs/shared-web/locale/locales/de';
import accounts from '@platform360/accounts/web/locales/de';
import auth from '@platform360/auth/web/locales/de';
import app from '@platform360/app/web/locales/de';

export default {
    ...shared,
    accounts,
    auth,
    app,
};
