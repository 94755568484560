// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Toaster from '@platform360/libs/shared-web/toaster';
import { AnalyticsClientContext, analyticsClient } from '@platform360/libs/shared-web/analytics';
import { ConfigContext } from '@platform360/libs/shared-web/helpers/useConfig';
import { locales } from '@scp/frontend/locales';
import ChangePassword from '@platform360/accounts/web/profile/ChangePassword';
import BusinessProfile from '@platform360/accounts/web/components/BusinessProfile';
import { UserMenuModalsProvider } from '@platform360/libs/shared-web/components/UserMenu';
import ApiTokens from '@platform360/accounts/web/components/ApiTokens';
import { AuthProvider } from '@platform360/libs/shared-web/auth';
import { UserProvider } from '@platform360/libs/shared-web/user';
import { useCurrentUserQuery } from '@platform360/accounts/web/queries/useCurrentUserQuery';
import { useDepersonateMutation } from '@platform360/accounts/web/mutations';
import { QueryProvider } from '@platform360/libs/shared-web/query';
import { adminPanelAppSettings, globalSsoAppSettings } from '@scp/frontend/applications-settings';
import { Application } from '@platform360/libs/common/application';
import Document from '@platform360/libs/shared-web/components/Document';
import { Outlet } from 'react-router-dom';
import { captureException } from '@sentry/browser';
import axios from 'axios';
import {
    LocaleCode,
    LocaleProvider,
    fallbackLocaleCode,
} from '@platform360/libs/shared-web/locale';
import {
    ApplicationSettings,
    ApplicationSettingsContext,
} from '@platform360/libs/shared-web/application-settings/context';

const userMenuModals = {
    'change-password': ChangePassword,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'business-profile': () => <BusinessProfile modalPath="business-profile" />,
    'api-tokens': ApiTokens,
};

const applicationSettings: Record<Application, ApplicationSettings> = {
    'admin-panel': adminPanelAppSettings,
    'global-sso': globalSsoAppSettings,
};

const handleSetLocale = async (localeCode: LocaleCode) => {
    try {
        await axios.put('/api/v1/accounts/user/locale', {
            localeCode,
        });
    } catch (e) {
        captureException(e);
    }
};

const App = () => (
    <ConfigContext.Provider value={window.__INITIAL_STATE__.config}>
        <ApplicationSettingsContext.Provider value={applicationSettings}>
            <LocaleProvider
                locales={locales}
                fallbackLocaleCode={fallbackLocaleCode}
                onLocaleChange={handleSetLocale}
            >
                <QueryProvider>
                    {/* https://react-query.tanstack.com/devtools#import-the-devtools */}
                    <ReactQueryDevtools initialIsOpen={false} />
                    <AnalyticsClientContext.Provider value={analyticsClient}>
                        <AuthProvider>
                            <UserMenuModalsProvider modals={userMenuModals}>
                                <>
                                    <Toaster />
                                    <UserProvider
                                        useCurrentUserQuery={useCurrentUserQuery}
                                        useDepersonateMutation={useDepersonateMutation}
                                    >
                                        <Document />
                                        <Outlet />
                                    </UserProvider>
                                </>
                            </UserMenuModalsProvider>
                        </AuthProvider>
                    </AnalyticsClientContext.Provider>
                </QueryProvider>
            </LocaleProvider>
        </ApplicationSettingsContext.Provider>
    </ConfigContext.Provider>
);

export default App;
