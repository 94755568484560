// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DateTime } from 'luxon';

export const toDateTime = (dateTime: string | Date | DateTime): DateTime => {
    if (typeof dateTime === 'string') {
        return DateTime.fromISO(dateTime);
    }

    if (dateTime instanceof Date) {
        return DateTime.fromJSDate(dateTime);
    }

    return dateTime;
};

/**
 * Human-readable formatting of dateTime with respect of timezone.
 */
export const formatDateTime = (
    dateTime: string | Date | DateTime,
    timezone = 'UTC',
    { format = 'yyyy-LL-dd HH:mm:ss ZZ' } = {},
): string => toDateTime(dateTime).setZone(timezone).toFormat(format);
