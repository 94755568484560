// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';

export const enableMfa = async () => {
    await apiClient.post('/accounts/me/mfa/ticket');
};

type DisableMfaPayload = {
    currentPassword: string;
    otp: string;
};

export const disableMfa = async (payload: DisableMfaPayload) => {
    await apiClient.delete('/accounts/me/mfa', {
        data: payload,
    });
};
