// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Rechtliches',
        terms: 'Nutzungsbedingungen',
        privacyPolicy: 'Datenschutzrichtlinie',
        dpa: 'Vereinbarung zur Datenverarbeitung',
        optOuts: 'Zustimmung aufheben',
        cookies: 'Erklärung zu Cookies',
        gaHeader: 'Zustimmung zur Verwendung von Google Analytics aufheben',
        gaOptOut: 'Hier klicken, um die Zustimmung zur Verwendung von Google Analytics aufzuheben',
        cookieHeader: 'Einwilligung zur Verwendung von Cookies anpassen',
        cookieModify: 'Einwilligung anpassen',
        cookieWithdraw: 'Einwilligung aufheben',
        cookieSuccess: 'Die Einwilligung wurde zurückgenommen',
    },
};