// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

/** @deprecated use `useSearchParams` from react-router-dom instead */
const useSearchParams = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

export default useSearchParams;
