// Copyright 2024. WebPros International GmbH. All rights reserved.

import HomePage from './components/HomePage';
import { LegalLayout, OptOuts } from './components/Legal';
import { Navigate, RouteObject } from 'react-router-dom';
import { PrivateRoutes } from '@platform360/auth/web/components/PrivateRoutes';
import { legalDocumentsLoader } from '@platform360/app/web/helpers/legalDocumentsLoader';
import { Suspense, lazy } from 'react';
import { ContentLoader } from '@plesk/ui-library';
import { CookiesList } from '@platform360/app/web/components/CookieManager';

const Privacy = lazy(async () => ({ default: (await legalDocumentsLoader()).Privacy }));
const Terms = lazy(async () => ({ default: (await legalDocumentsLoader()).Terms }));
const DataProcessingAgreement = lazy(async () => ({
    default: (await legalDocumentsLoader()).DataProcessingAgreement,
}));

const CookiesContent = lazy(async () => ({
    default: (await legalDocumentsLoader()).CookiesContent,
}));

export const routes: RouteObject[] = [
    {
        path: 'legal',
        element: <LegalLayout />,
        children: [
            {
                index: true,
                element: <Navigate to="terms" />,
            },
            {
                path: 'terms',
                element: (
                    <Suspense fallback={<ContentLoader />}>
                        <Terms />
                    </Suspense>
                ),
            },
            {
                path: 'privacy-policy',
                element: (
                    <Suspense fallback={<ContentLoader />}>
                        <Privacy />
                    </Suspense>
                ),
            },
            {
                path: 'data-processing-agreement',
                element: (
                    <Suspense fallback={<ContentLoader />}>
                        <DataProcessingAgreement />
                    </Suspense>
                ),
            },
            {
                path: 'opt-outs',
                element: (
                    <Suspense fallback={<ContentLoader />}>
                        <OptOuts />
                    </Suspense>
                ),
            },
            {
                path: 'cookie-statement',
                element: (
                    <Suspense fallback={<ContentLoader />}>
                        <CookiesContent cookiesList={<CookiesList />} />
                    </Suspense>
                ),
            },
        ],
    },
    {
        element: <PrivateRoutes />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
        ],
    },
];
