// Copyright 2024. WebPros International GmbH. All rights reserved.

import { FormProps } from '@plesk/ui-library';
import ChangePassword, { FormValues } from './ChangePassword';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import useChangePasswordMutation from '@platform360/accounts/web/mutations/useChangePasswordMutation';
import { useCurrentUser } from '@platform360/libs/shared-web/user';
import { useAuth } from '@platform360/libs/shared-web/auth';

type ChangePasswordContainerProps = FormProps<FormValues>;

export const ChangePasswordContainer = (props: ChangePasswordContainerProps) => {
    const translate = useTranslate('accounts.profile.ChangePassword');
    const { processLogout } = useAuth();
    const { email } = useCurrentUser();
    const { successToast } = useToaster();

    const {
        mutate,
        isPending: isLoading,
        errors,
    } = useChangePasswordMutation({
        onSuccess: () => {
            successToast(translate('successMessage'));
            processLogout();
        },
    });

    return (
        <ChangePassword
            errors={errors}
            isLoading={isLoading}
            email={email}
            onSubmit={mutate}
            isUserLoggedIn
            {...props}
        />
    );
};

export default ChangePasswordContainer;
