// Copyright 2024. WebPros International GmbH. All rights reserved.

import Loading from '@platform360/libs/shared-web/components/Loading';
import { useAuth } from '@platform360/libs/shared-web/auth';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { setSentryUser } from '@platform360/libs/shared-web/helpers/sentry';
import { analyticsClient } from '@platform360/libs/shared-web/analytics';
import { isRedirectUrlAllowed } from './isRedirectUrlAllowed';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';
import { Session } from '@platform360/libs/shared-web/auth/auth-state';
import { useRef } from 'react';

const notifyAmplitudeOnSuccessLogin = ({ userId }: Session, redirectUrl?: string) => {
    setSentryUser(userId);
    analyticsClient.setUserId(userId);
    analyticsClient.sessionStart(userId);

    if (redirectUrl) {
        const url = new URL(redirectUrl, 'http://localhost');
        analyticsClient.setUtmProperties(url.searchParams);
    }
};

const parseRedirectUrl = (str: string): string | undefined => {
    const { redirectUrl } = qs.parse(str);
    if (typeof redirectUrl !== 'string') {
        return undefined;
    }

    if (redirectUrl === '/') {
        return undefined;
    }

    return redirectUrl;
};

export const Callback = () => {
    const didInitialise = useRef(false);
    const { processAuthentication } = useAuth();
    const redirectUrl = parseRedirectUrl(window.location.search.substring(1));
    const { allowLoginRedirectsTo } = useConfig();
    const navigate = useNavigate();

    useEffectOnce(() => {
        // Avoid duplicate renders, especially in strict mode.
        if (didInitialise.current) {
            return;
        }

        didInitialise.current = true;

        const authenticate = async () => {
            try {
                const session = await processAuthentication();

                notifyAmplitudeOnSuccessLogin(session, redirectUrl);

                const redirectTo = processRedirectUrl();

                if (redirectTo.startsWith('/')) {
                    navigate(redirectTo);
                } else {
                    location.replace(redirectTo);
                }
            } catch {
                navigate(`/auth/login?${qs.stringify({ redirectUrl })}`);
            }
        };

        void authenticate();
    });

    const processRedirectUrl = (): string => {
        if (redirectUrl && isRedirectUrlAllowed(redirectUrl, allowLoginRedirectsTo)) {
            return redirectUrl;
        }

        return '/';
    };

    return (
        <>
            {'loading...'}
            <Loading />
        </>
    );
};

export default Callback;
