// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useMutation } from '@tanstack/react-query';
import { changePassword, ChangePasswordPayload } from '@platform360/accounts/web/api/me';
import { getValidationErrors } from '@platform360/libs/shared-web/helpers/apiErrors';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useTranslateValidationErrors } from '@platform360/libs/shared-web/helpers/translateErrors';

type UseChangePasswordMutationOptions = {
    onSuccess: (newPassword: string) => void;
};

const useChangePasswordMutation = ({ onSuccess }: UseChangePasswordMutationOptions) => {
    const translate = useTranslate('accounts.profile.ChangePassword');
    const translateValidationErrors = useTranslateValidationErrors();

    const { error, ...rest } = useMutation({
        mutationFn: async (payload: ChangePasswordPayload) => {
            await changePassword(payload);

            return payload.newPassword;
        },
        onSuccess,
    });

    const errors = translateValidationErrors(getValidationErrors(error));

    // TODO find out the solution to avoid translations dirty overrides
    if (errors?.repeatPassword?.match) {
        errors.repeatPassword.match = translate('passwordMatch');
    }

    return { ...rest, errors };
};

export default useChangePasswordMutation;
