// Copyright 2024. WebPros International GmbH. All rights reserved.

import { DPA_DIALOG_DATA_TYPE, DpaDialog } from './DpaDialog';
import { usePostConsentsMutation } from '@platform360/accounts/web/mutations';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { useToaster } from '@platform360/libs/shared-web/toaster';
import { useEffect, useState } from 'react';
import {
    AGREEMENT_TYPE_DPA,
    getDataProcessingAgreementText,
} from '@platform360/libs/common/agreements';
import { captureException } from '@sentry/browser';
import { useAccountsAnalyticsEvents } from '@platform360/accounts/web/helpers/analytics';

const DIALOG_ANIMATION_TIMEOUT = 300;

export type DpaDialogContainerProps = {
    isOpen: boolean;
    onAccept: () => void;
    onDecline: () => void;
    onClose: () => void;
    initialValue?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg';
};

export const DpaDialogContainer = ({
    isOpen,
    onAccept,
    onDecline,
    onClose,
    initialValue = false,
    size = 'md',
}: DpaDialogContainerProps) => {
    const translate = useTranslate('accounts.profile.DpaDialog');
    const analyticsEvents = useAccountsAnalyticsEvents();
    const { successToast } = useToaster();
    const [value, setValue] = useState(initialValue);
    const [isTextScrolled, setIsTextScrolled] = useState(false);
    const [prevIsOpen, setPrevIsOpen] = useState(isOpen);
    const { isPending: isSaving, mutateAsync } = usePostConsentsMutation({
        onSuccess: () => {
            successToast(translate('successMessage'));
        },
    });
    const handleSubmit = async () => {
        const newValue = !value;
        await mutateAsync([
            {
                type: AGREEMENT_TYPE_DPA,
                enabled: newValue,
                text: newValue ? getDataProcessingAgreementText(window.location.origin) : undefined,
            },
        ]);
        if (newValue) {
            onAccept();
            analyticsEvents.dpaSigned();
        } else {
            onDecline();
            analyticsEvents.dpaDeclined();
        }
    };

    if (prevIsOpen !== isOpen) {
        setPrevIsOpen(isOpen);
        if (isOpen) {
            setValue(initialValue);
            setIsTextScrolled(false);
        }
    }

    useEffect(() => {
        let content: Element | null = null;
        const handleScroll = () => {
            if (!content) {
                return;
            }

            if (content.scrollTop + content.clientHeight >= content.scrollHeight - 1) {
                setIsTextScrolled(true);
            }
        };
        const timeout = setTimeout(() => {
            if (!isOpen) {
                return;
            }

            content = document.querySelector(
                `[data-type=${DPA_DIALOG_DATA_TYPE}] .pul-dialog__content`,
            );

            if (!content) {
                captureException(new Error('DPA content element not found.'));
                return;
            }

            content.addEventListener('scroll', handleScroll);
        }, DIALOG_ANIMATION_TIMEOUT);

        return () => {
            clearTimeout(timeout);
            content?.removeEventListener('scroll', handleScroll);
        };
    }, [isOpen]);

    return (
        <DpaDialog
            isOpen={isOpen}
            isSaving={isSaving}
            isTextScrolled={isTextScrolled}
            onClose={onClose}
            value={value}
            onSubmit={handleSubmit}
            size={size}
        />
    );
};
