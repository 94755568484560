// Copyright 2024. WebPros International GmbH. All rights reserved.

import { RouteObject } from 'react-router-dom';
import { PrivateRoutes } from '@platform360/auth/web/components/PrivateRoutes';
import Layout from '@platform360/admin-panel/web/components/Layout';
import Users from '@platform360/admin-panel/web/components/Users';
import EmailMetas from '@platform360/admin-panel/web/components/EmailMetas';
import { LeikaEmailHashes } from '@platform360/admin-panel/web/components/LeikaEmailHashes/LeikaEmailHashes';
import ProtectedRoute from '@platform360/admin-panel/web/components/ProtectedRoute';
import HomePage from '@platform360/admin-panel/web/components/HomePage';
import {
    PERMISSION_ALL_USERS_MANAGE,
    PERMISSION_ALL_EMAIL_METAS_MANAGE,
    PERMISSION_ALL_LEIKA_EMAIL_HASHES_MANAGE,
} from '@platform360/libs/common/permissions';

export const routes: RouteObject[] = [
    {
        path: 'admin-panel',
        element: <PrivateRoutes />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        index: true,
                        element: <HomePage />,
                    },
                    {
                        path: 'users',
                        element: (
                            <ProtectedRoute permission={PERMISSION_ALL_USERS_MANAGE}>
                                <Users />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: 'email-metas',
                        element: (
                            <ProtectedRoute permission={PERMISSION_ALL_EMAIL_METAS_MANAGE}>
                                <EmailMetas />
                            </ProtectedRoute>
                        ),
                    },
                    {
                        path: 'leika-email-hashes',
                        element: (
                            <ProtectedRoute permission={PERMISSION_ALL_LEIKA_EMAIL_HASHES_MANAGE}>
                                <LeikaEmailHashes />
                            </ProtectedRoute>
                        ),
                    },
                ],
            },
        ],
    },
];
