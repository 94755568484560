// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ContentLoader } from '@plesk/ui-library';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';
import buildUrl from '@platform360/libs/shared-web/helpers/buildUrl';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

const ProxyLogout = () => {
    const {
        auth: { domain },
    } = useConfig();

    useEffectOnce(() => {
        const originalSearchParams = new URLSearchParams(window.location.search);
        const href = buildUrl(`https://${domain}/v2/logout`, {
            ...Object.fromEntries(originalSearchParams),
            returnTo: originalSearchParams.get('return_to') ?? undefined,
        });

        window.location.href = href;
    });

    return <ContentLoader />;
};

export default ProxyLogout;
