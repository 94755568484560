// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Avisos legales',
        terms: 'Términos de uso',
        privacyPolicy: 'Política de privacidad',
        dpa: 'Contrato de procesamiento de datos',
        optOuts: 'Opt-Outs',
        cookies: 'Declaración de cookies',
        gaHeader: 'Opt- out de Google Analytics',
        gaOptOut: 'Haga clic aquí para desactivar Google Analytics',
        cookieHeader: 'Ajustes de consentimiento de cookies',
        cookieModify: 'Modificar su consentimiento de cookies',
        cookieWithdraw: 'Retirar su consentimiento de cookies',
        cookieSuccess: 'Retirada correcta del consentimiento',
    },
};