// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Dialog, FormFieldText, StatusMessage, FormErrors } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import InputCopy from '@platform360/libs/shared-web/components/InputCopy';

type FormValues = {
    name: string;
};

export type AddTokenDialogProps = {
    errors?: FormErrors;
    token?: string;
    isLoading: boolean;
    onClose: () => void;
    onSubmit: (name: string) => void;
};

const AddTokenDialog = ({ isLoading, token, onClose, onSubmit, errors }: AddTokenDialogProps) => {
    const translate = useTranslate('accounts.ApiTokens.AddTokenDialog');

    const handleSubmit = ({ name }: FormValues) => onSubmit(name);

    if (token) {
        return (
            <Dialog
                title={translate('title')}
                size="sm"
                onClose={onClose}
                form={{
                    submitButton: {
                        children: translate('close'),
                    },
                    cancelButton: false,
                    onSubmit: onClose,
                }}
                isOpen
                data-type="add-token-output-dialog"
            >
                <StatusMessage intent="info" data-type="add-token-output-dialog-info">
                    {translate('tokenInfo')}
                </StatusMessage>
                <p>
                    <InputCopy value={token} successMessage={translate('tokenSuccessCopied')} />
                </p>
            </Dialog>
        );
    }

    return (
        <Dialog
            title={translate('title')}
            size="sm"
            form={{
                errors,
                state: isLoading ? 'submit' : undefined,
                submitButton: { children: translate('addButton') },
                cancelButton: { children: translate('closeButton') },
                onSubmit: handleSubmit,
                vertical: true,
            }}
            isOpen
            onClose={onClose}
            data-type="add-token-form-dialog"
        >
            <FormFieldText
                label={translate('nameLabel')}
                name="name"
                size="fill"
                description={translate('nameDescription')}
                autoFocus
            />
        </Dialog>
    );
};

export default AddTokenDialog;
