// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import AuthLayout from '@platform360/libs/shared-web/components/AuthLayout';
import { LocaleSwitcher } from '@platform360/libs/shared-web/locale';
import Header from '@platform360/libs/shared-web/components/Header';
import LegalLink from '@platform360/auth/web/components/LegalLink';
import useConsentItems from '@platform360/libs/shared-web/helpers/useConsentItems';
import { useCallback, useState } from 'react';
import { useSignTermsOfUseMutation } from '@platform360/auth/web/mutations';
import { getConsentsToSend } from '@platform360/auth/web/components/SignUpByInvitation/getConsentsToSend';
import styles from './SignTermsOfUse.module.css';
import { isAxiosError } from 'axios';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { useGetConsentsQuery } from '@platform360/auth/web/queries';
import Loading from '@platform360/libs/shared-web/components/Loading';
import {
    Form,
    FormFieldCheckbox,
    Heading,
    Paragraph,
    setIn,
    StatusMessage,
    Text,
} from '@plesk/ui-library';

type FormValues = {
    privacyPolicyAgreement: boolean;
    consents: Record<string, boolean>;
};

export const SignTermsOfUse = () => {
    const translate = useTranslate('auth.SignTermsOfUse');
    const knownConsents = useConsentItems();
    const [isLoading, setIsLoading] = useState(false);
    const { login } = useAuth();
    const errorTranslations: Record<string, string> = {
        invalidToken: translate('errors.invalidToken', {
            a: (chunk) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a onClick={() => login()}>{chunk}</a>
            ),
        }),
    };

    const { data: consents = [], isLoading: areConsentsLoading } = useGetConsentsQuery({
        meta: {
            defaultErrorHandler: false,
        },
    });

    const enabledConsents = consents.filter(({ enabled }) => enabled).map(({ type }) => type);

    const {
        mutateAsync: signTermsOfUse,
        error,
        errors,
    } = useSignTermsOfUseMutation((continueUrl) => {
        const searchParams = new URLSearchParams(window.location.search);

        window.location.href = `${continueUrl}?state=${searchParams.get('state')}`;
    });

    const [values, setValues] = useState<FormValues>({
        privacyPolicyAgreement: false,
        consents: Object.fromEntries(knownConsents.map(({ type }) => [type, false])),
    });

    const onFieldChange = <Key extends keyof FormValues>(key: Key, value: FormValues[Key]) =>
        setValues(setIn(values, key, value));

    /**
     * Excludes enabled consents.
     */
    const filterEnabledConsents = useCallback(
        <T extends { type: string }>(consents: T[]) =>
            consents.filter(({ type }) => !enabledConsents.includes(type)),
        [enabledConsents],
    );

    const handleSubmit = async ({ consents, privacyPolicyAgreement }: FormValues) => {
        if (!privacyPolicyAgreement) {
            return;
        }

        setIsLoading(true);

        const consentsToSend = filterEnabledConsents(getConsentsToSend(knownConsents, consents));

        try {
            await signTermsOfUse({
                consents: consentsToSend,
                privacyPolicyAgreement,
            });
        } catch {
            setIsLoading(false);
        }
    };

    const renderForm = () => {
        if (areConsentsLoading) {
            return <Loading />;
        }

        return (
            <Form
                onSubmit={handleSubmit}
                onFieldChange={onFieldChange}
                state={isLoading ? 'submit' : undefined}
                values={values}
                applyButton={false}
                cancelButton={false}
                errors={errors}
                submitButton={{
                    children: translate('continueBtn'),
                    disabled: !values.privacyPolicyAgreement,
                    type: 'submit',
                    fill: true,
                }}
                vertical
            >
                <div className={styles.privacyPolicy}>
                    <FormFieldCheckbox
                        name="privacyPolicyAgreement"
                        label={
                            <Text fontSize="sm">
                                {translate('privacyPolicyAgreementTitle', {
                                    termsLink: (chunk) => (
                                        <LegalLink to={'/legal/terms'}>{chunk}</LegalLink>
                                    ),
                                    privacyLink: (chunk) => (
                                        <LegalLink to={'/legal/privacy-policy'}>{chunk}</LegalLink>
                                    ),
                                })}
                            </Text>
                        }
                        required
                    />
                    {filterEnabledConsents(knownConsents).map(({ type, title, text }) => (
                        <FormFieldCheckbox
                            key={type}
                            name={`consents[${type}]`}
                            label={<Text fontSize="sm">{title}</Text>}
                            description={text}
                        />
                    ))}
                </div>
            </Form>
        );
    };

    return (
        <AuthLayout className={styles.root}>
            <Header addon={<LocaleSwitcher />} />
            {isAxiosError(error) && (
                <StatusMessage intent="danger">
                    {errorTranslations[error.response?.data.type]}
                </StatusMessage>
            )}
            <Heading level={3}>{translate('title')}</Heading>
            <Paragraph>
                {translate('descriptionText1')}
                <br />
                {translate('descriptionText2')}
            </Paragraph>
            {renderForm()}
        </AuthLayout>
    );
};
