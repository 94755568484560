// Copyright 2024. WebPros International GmbH. All rights reserved.

import { RouteObject } from 'react-router-dom';
import { UpdateEmail } from '@platform360/accounts/web/components/UpdateEmail';

export const routes: RouteObject[] = [
    {
        path: 'accounts/update-email',
        element: <UpdateEmail />,
    },
];
