// Copyright 2024. WebPros International GmbH. All rights reserved.

import { AGREEMENT_TYPE_MARKETING } from '@platform360/libs/common/agreements';

export type Consent = DisabledConsent | EnabledConsent;

export type DisabledConsent = {
    enabled: false;
    type: string;
};

export type EnabledConsent = {
    enabled: true;
    type: string;
    text: string;
    data: Record<string, unknown>;
};

type ConsentWithTranslationKeys = {
    type: string;
    titleLocale: string;
    textLocale: string;
};

const consents: ConsentWithTranslationKeys[] = [
    {
        type: AGREEMENT_TYPE_MARKETING,
        titleLocale: 'sendPlatform360Announce.title',
        textLocale: 'sendPlatform360Announce.text',
    },
];

export default consents;
