// Copyright 2024. WebPros International GmbH. All rights reserved.

import { FormErrors } from '@plesk/ui-library';
import { Translate } from '@platform360/libs/shared-web/locale/Translate';
import set from 'lodash/set';
import { ReactNode, useCallback } from 'react';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

export type ValidationError = {
    path: string;
    type: string;
    category?: string;
    message: string;
    params?: Record<string, string | number>;
};

type TranslateErrorProps = {
    category?: string;
    type: string;
    message: string;
    params?: Record<string, string | number>;
};

/**
 * Converts validation errors to Form validation errors structure and translates texts.
 */
export const useTranslateValidationErrors = () => {
    const translate = useTranslate();

    return useCallback(
        (errors?: ValidationError[]): FormErrors | undefined => {
            if (!errors) {
                return undefined;
            }

            const result = {};

            errors.forEach(({ path, type, message, params, category }) => {
                const translatedError = translateError(
                    { category, type, message, params },
                    translate,
                );

                set(result, `${path}.${type}`, translatedError);
            });

            return result;
        },
        [translate],
    );
};

export const translateError = (
    { category = 'common', type, message, params = {} }: TranslateErrorProps,
    translate?: ReturnType<typeof useTranslate>,
): ReactNode => {
    const key = `${category}.errors.${type}`;
    const paramsWithMessage = { message, ...params };

    if (translate) {
        return translate(key, paramsWithMessage, message);
    }

    return <Translate content={key} params={paramsWithMessage} fallback={message} />;
};
