// Copyright 2024. WebPros International GmbH. All rights reserved.

import { RouteObject } from 'react-router-dom';
import NotFound from '@platform360/app/web/components/NotFound';
import App from '@scp/frontend/components/App';
import { routes as accountsRoutes } from '@platform360/accounts/web/routes';
import { routes as authRoutes } from '@platform360/auth/web/routes';
import { routes as adminRoutes } from '@platform360/admin-panel/web/routes';
import { routes as appRoutes } from '@platform360/app/web/routes';

export const routes: RouteObject[] = [
    {
        path: '*',
        element: <App />,
        children: [
            ...accountsRoutes,
            ...authRoutes,
            ...adminRoutes,
            ...appRoutes,
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
];
