// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useContext } from 'react';
import { ApplicationSettings, ApplicationSettingsContext } from './context';
import useConfig from '@platform360/libs/shared-web/helpers/useConfig';

const useApplicationSettings = (): ApplicationSettings => {
    const { application } = useConfig();
    const applicationSettingsContext = useContext(ApplicationSettingsContext);

    if (applicationSettingsContext === undefined) {
        throw new Error('Application settings context must be initialized.');
    }

    return applicationSettingsContext[application];
};

export default useApplicationSettings;
