// Copyright 2024. WebPros International GmbH. All rights reserved.

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import './styles/index.less';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routes } from '@scp/frontend/routes';
import './index.module.css';
import './dark-theme.less';
import findAuthUserIdInLocalStorage from '@platform360/libs/shared-web/helpers/findAuthUserIdInLocalStorage';
import { setSentryUser } from '@platform360/libs/shared-web/helpers/sentry';
import { MockedAuthClient } from '@platform360/libs/shared-web/auth';

// Expose global variables for the cypress tests to be able to mock them.
if (window.Cypress && !window.__CYPRESS__) {
    window.__CYPRESS__ = {
        // This code is used to replace the default Auth client implementation in Cypress tests.
        // It is crucial to note that a real Auth client cannot be used due to security reasons.
        // The Auth0 client verifies the running host during runtime and will fail if the schema is not HTTPS or if the host is not "localhost".
        // see: https://github.com/auth0/auth0-spa-js/blob/master/FAQ.md#why-do-i-get-auth0-spa-js-must-run-on-a-secure-origin
        AuthClient: MockedAuthClient,
    };
}

Sentry.init({
    integrations: [new Sentry.BrowserTracing()],
    // 50%(0.5 sample rate) generates ~100k transactions monthly.
    // So 10%(0.1) sample rate will be equal to ~20k transactions monthly.
    tracesSampleRate: 0.1,
    ignoreErrors: [
        // some bot with an old browser engine
        'Object Not Found Matching Id',
        // Error from usercentrics
        /"msg":"EUD ERROR\[V\d+]/,
    ],
    denyUrls: [
        /safari-extension:\/\//,
        /moz-extension:\/\//,
        /chrome-extension:\/\//,
        /google-analytics\.com/,
        /connect\.facebook\.net/,
        /translate\.goog/,
        /static\.zdassets\.com/,
    ],
    ...window.__INITIAL_STATE__.sentry,
});
setSentryUser(findAuthUserIdInLocalStorage());

const container = document.getElementById('root');

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
const router = createBrowserRouter(routes);

root.render(
    <StrictMode>
        <RouterProvider router={router} />
    </StrictMode>,
);
