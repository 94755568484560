// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Branding, isBranding } from '@platform360/libs/common/branding';
import { useBranding } from '@platform360/libs/shared-web/hooks/useBranding';
import styles from './Layout.module.css';
import classNames from 'classnames';
import { ReactNode } from 'react';

// Brands.
import cpanelLogo from './brand-logos/cpanel.svg';
import sitejetLogo from './brand-logos/sitejet.svg';
import solusVmLogo from './brand-logos/solusvm.svg';
import wptLogo from './brand-logos/wpt.svg';
import wpgLogo from './brand-logos/wpg.svg';
import monitoringLogo from './brand-logos/monitoring.svg';
import xoviLogo from './brand-logos/xovi.svg';
import koalityLogo from './brand-logos/koality.svg';
import whmcsLogo from './brand-logos/whmcs.svg';
import platformLogo from './brand-logos/platform.svg';
import pleskLogo from './brand-logos/plesk.svg';

const logos: Record<Branding, string> = {
    cpanel: cpanelLogo,
    sitejet: sitejetLogo,
    solusvm: solusVmLogo,
    wpt: wptLogo,
    wpg: wpgLogo,
    monitoring: monitoringLogo,
    xovi: xoviLogo,
    koality: koalityLogo,
    whmcs: whmcsLogo,
    platform: platformLogo,
    plesk: pleskLogo,
};

export type LayoutProps = {
    children: ReactNode;
    footer?: ReactNode;
    branding?: Branding;
    className?: string;
};

export const AuthLayout = ({ branding: brand, children, className, footer }: LayoutProps) => {
    const { branding: defaultBranding } = useBranding();
    const branding = brand || defaultBranding;

    return (
        <div className={styles.container}>
            <div className={classNames(styles.root, className)}>
                <div
                    className={
                        isBranding(branding)
                            ? classNames(styles.inner, styles[branding])
                            : styles.inner
                    }
                >
                    {isBranding(branding) && (
                        <div className={styles.logo}>
                            <img src={logos[branding]} alt={branding} />
                        </div>
                    )}
                    <div className={styles.dialog}>{children}</div>
                </div>
                {footer}
            </div>
        </div>
    );
};
