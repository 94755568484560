// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Copiado al portapapeles.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Está suplantando a otra cuenta.',
            depersonateButton: 'Dejar de suplantar',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Ya no está suplantando a otra cuenta.',
        },
        UserMenu: {
            team: 'Organización',
            logout: 'Cerrar sesión',
            changePassword: 'Cambiar la contraseña',
            businessProfile: 'Perfil corporativo',
            apiTokens: 'Tokens de API',
        },
    },

    common: {
        errors: {
            isNotEmpty: 'Este campo obligatorio está vacío.',
            required: 'Este campo obligatorio está vacío.',
            email: 'El valor debe ser una dirección de email válida.',
            isEmail: 'El valor debe ser una dirección de email válida.',
            min: '{min, plural, one{Debe contener al menos # carácter.} other{Debe contener al menos # caracteres.}}',
            maxLength: '{max, plural, one{Debe contener como máximo # carácter.} other{Debe contener como máximo # caracteres.}}',
            unique: 'El valor debe ser único.',
            unknown: 'Error desconocido: {message}',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Indique una contraseña.',
            errorPasswordIsWrong: 'Contraseña incorrecta.',
            errorPasswordIsTooWeak: 'La contraseña no es suficientemente segura.',
            errorPasswordContainsPersonalInfo: 'La contraseña no debe contener información personal.',
            errorPasswordTooCommon: 'La contraseña es demasiado común.',
            errorPasswordPreviouslyUsed: 'La contraseña se ha utilizado anteriormente.',
            errorInvalidOtp: 'El código indicado no es válido.',
        },
    },

    ConfirmationPopover: {
        cancel: 'Cancelar',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'WebPros Account utiliza ZendeskChat, una aplicación de terceros.',
            description2: 'Al proceder, acepto usar ZendeskChat, que es una aplicación de terceros. Esto puede implicar que se transfieran mis datos personales (como, por ejemplo, la dirección IP) a terceros dentro o fuera de Europa. Si desea más información, consulte nuestra <a>Política de privacidad</a>.',
            confirmButton: 'Iniciar ZendeskChat',
        },
        supportFormPopover: {
            description: 'En este momento no hay ningún agente disponible. Espere o utilice nuestro formulario de tickets para enviar una solicitud.',
            confirmButton: 'Abrir formulario',
        },
        ChatButton: {
            title: 'Ayuda',
        },
    },

    emailRequired: "To log in to WebPros using your Facebook account, access to view the email address must be granted. Make sure that the corresponding permission is granted and try again.",
    userBlocked: "La cuenta se ha suspendido por una infracción del contrato del usuario. Si considera que se trata de un error, escriba a {emailLink}.",
    invalidDomain: 'Se ha denegado el acceso. El email del usuario no está en la lista blanca.',

    errorDefault: 'Algo ha salido mal. Inténtelo de nuevo en unos minutos.',
    errorDefaultWithReqId: 'Algo salió mal. Inténtelo de nuevo más tarde. ID de la petición: {requestId}.',
    errorTooManyRequests: 'Límite de llamada API alcanzado para esta dirección IP. Inténtelo de nuevo en unos minutos.',
    errorTokenRenewal: 'Su sesión ha expirado. Vuelva a iniciar sesión.',
    errorNetworkIssue: 'Parece que tiene problemas para conectarse a la red en este momento.',

    errorCopyToClipboard: 'Se ha producido un error de copia. Intente llevar a cabo la acción de copiar de forma manual.',

    PrivacyPolicy: {
        privacyPolicyText: 'WebPros International GmbH procesará los datos proporcionados por usted para procesar su petición conforme a su <a>política de privacidad</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'El usuario no existe.',
            bounceOrComplaint: 'Esta dirección de email no existe o ha bloqueado nuestro mensaje porque lo considera spam. Póngase en <webProsContactLink>contacto</webProsContactLink> con el equipo de soporte de WebPros Account.',
            userIsNotVerified: 'El usuario existe pero no ha sido verificado. Le hemos enviado un nuevo email de verificación, por lo que le recomendamos examinar su buzón de correo.',
            userExist: 'El usuario ya existe.',
            errorPasswordIsEmpty: 'Indique la contraseña.',
            errorPasswordIsWrong: 'Contraseña incorrecta.',
            errorPasswordIsTooWeak: 'La contraseña no es suficientemente segura.',
        },
        AuthContainer: {
            alreadyHaveAnAccount: '¿Ya dispone de una cuenta?',
            loginLink: 'Iniciar sesión',
        },
        SignUp: {
            signUp: 'Crear cuenta',
            emailLabel: 'Email',
            passwordLabel: 'Contraseña',
            privacyPolicyAgreementTitle: 'Acepto los <termsLink>Términos de uso</termsLink> y consiento que WebPros procese en sus sistemas internos los datos personales que he proporcionado, de acuerdo con la <privacyLink>Política de privacidad</privacyLink> de WebPros',
            SignUpByInvitation: {
                emailDescription: 'Se le registrará con el email que se muestra arriba',
                invitationSucceed: 'Se ha registrado correctamente. Inicie sesión ahora.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Nombre',
                    lastNamePlaceholder: 'Apellidos',
                    nameLabel: 'Nombre',
                },
            },
        },
        ResetPasswordDrawer: {
            title: 'Restablecer la contraseña',
            text: 'Introduzca su dirección de email y le enviaremos un email con el enlace de restablecimiento de la contraseña.',
            emailLabel: 'Email:',
            submitButton: 'Enviar',
            successTitle: 'El email de restablecimiento de contraseña ha sido enviado',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Reenviar',
            loginForm: 'Iniciar sesión ahora',
            retryCountdown: 'Se podrá proceder con el reenvío en {time}',
        },
    },
    Footer: {
        copyright: '© {year} WebPros International GmbH. Todos los derechos reservados.',
        company: 'Empresa',
        knowledgeBase: 'Base de conocimiento',
        aboutWebPros: 'Acerca de WebPros',
        legal: 'Avisos legales',
        cookieSettings: 'Configuración de cookies',
        privacyPolicy: 'Política de privacidad',
        documentation: 'Documentación',
        contactUs: 'Contacte con nosotros',

    },
    Form: {
        requiredLegend: 'Campos obligatorios',
        submitButton: 'ACEPTAR',
        cancelButton: 'Cancelar',
    },

    FormField: {
      addMore: 'Añadir más',
      remove: 'Eliminar',
      clear: 'Borrar',
    },

    FormFieldPassword: {
        improvePassword: 'Para disponer de una contraseña más segura:',
        passwordTooShort: 'Aumente la longitud de la contraseña.',
        lettersLowerCase: 'Use al menos un carácter en minúscula.',
        lettersUpperCase: 'Use al menos un carácter en mayúscula.',
        numbers1: 'Use al menos un número.',
        numbers3: 'Use al menos tres números.',
        specialChar1: 'Use al menos un carácter especial (por ejemplo: !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Use al menos dos caracteres especiales (por ejemplo: : !, @, #, $, %, ^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Use tanto caracteres en minúsculas como caracteres en mayúsculas.',
        comboLettersAndNumbers: 'Use tanto números como caracteres.',
        comboLettersNumbersSpecial: 'Use números, letras y caracteres especiales.',
        yourPasswordIsStrong: 'Si desea que todavía sea más segura, aumente su longitud.',
        passwordStrength: 'La seguridad de la contraseña es %%strength%%.',
        strengthVeryStrong: 'MUY SEGURA',
        strengthStrong: 'SEGURA',
        strengthVeryWeak: 'MUY POCO SEGURA',
        strengthWeak: 'POCO SEGURA',
        strengthMedium: 'MEDIA',
        hidePassword: 'Ocultar la contraseña',
        showPassword: 'Mostrar la contraseña',
        generateButton: 'Generar',
        generateButtonHint: 'Genere una contraseña segura',
    },

    Pagination: {
        totalShort: 'de %%total%%',
        itemsPerPage: 'Elementos por página',
        all: 'Todo',
        total: 'Página %%current%% de %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'No se encontró ningún elemento que coincida con el filtro',
        emptyTitle: 'Parece que aquí no hay nada',
        filteredDescription: 'Refine su consulta o elimine algunos de los criterios del filtro e inténtelo de nuevo.',
    },

    Dialog: {
        cancelButton: 'Cancelar',
    },

    GaOptOutLink: {
        gaSuccess: 'Ahora Google Analytics ya no efectúa ningún seguimiento.',
    },

    InPlaceText: {
        ok: 'Guardar (Ctrl+Enter)',
        cancel: 'Cancelar (Esc)',
        edit: 'Editar',
        add: 'Añadir',
    },

    Team: {
        Layout: {
            title: 'Organización',
            members: 'Miembros',
            invitations: 'Invitaciones',
        },
        settings: 'Configuración',
        Members: {
            email: 'Email',
            fullName: 'Nombre completo',
            mfaStatus: 'Estado MFA',
            tags: 'Marcas especiales',
            remove: 'Eliminar',
            removeMemberConfirmation: '<b>{name}</b> ya no dispondrá de acceso a la organización ni a los recursos compartidos en servidores, sitios web y licencias.',
            SpecialMarks: {
                you: 'Usted',
                owner: 'Propietario',
            },
            Onboarding: {
                title: 'Cree su organización en nuestra plataforma añadiendo a sus compañeros',
                description: 'Nuestra plataforma le ofrece prestaciones para una colaboración y gestión eficiente de su empresa. Los miembros de su empresa pueden colaborar con usted en servidores conectados accediendo a ellos directamente desde aquí. Ya no es necesario que use contraseñas compartidas inseguras o complicados procesos de acceso.',
                addMembersButton: 'Añadir miembros de la organización',
            },
            MultifactorStatus: {
                enabled: 'MFA activado',
                disabled: 'MFA no activado',
            },
        },
        Invitations: {
            email: 'Email',
            expireAt: 'Expiración',
            expired: 'Expirada',
            add: 'Añadir',
            revoke: 'Revocar',
            Onboarding: {
                title: 'Aquí puede invitar a miembros de su organización',
                description: 'Añada a compañeros para compartir con ellos servidores y sitios web.',
                addButton: 'Invitar a un miembro nuevo',
            },
            ListActions: {
                resendInvitationTooltip: 'Reenvíe la invitación.',
                successMessage: 'Email de invitación enviado de nuevo.',
            },
            AddInvitations: {
                title: 'Añadir nuevos miembros a la organización',
                infoMessage: 'Invite a los miembros de su empresa a unirse indicando sus direcciones de email laborales abajo.',
                infoDescription: 'Es mejor introducir direcciones de email laborables en vez de direcciones personales, puesto que de esta forma se salvaguarda la información personal de los usuarios.',
                emailsLabel: 'Direcciones de email para enlace de invitación',
                submitButton: 'Enviar invitación',
                successMessage: '{count, plural, =1{Se ha enviado la invitación a {email}.} one{Se ha enviado # invitación.} other{Se han enviado # invitaciones.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Revocar',
                noSelectedInvitations: 'Seleccione una o más invitaciones a revocar.',
                revokeInvitationConfirmation: '{count, plural, =1{¿Revocar <b>{email}</b>?} one{¿Revocar <b>#</b> invitación?} other{¿Revocar <b>#</b> invitaciones?}} Esta acción no puede deshacerse.',
                successRevokeInvitation: '{count, plural, =1{Se ha revocado el correo electrónico <b>{email}</b>.} one{Se ha revocado <b>#</b> invitación.} other{Se han revocado <b>#</b> invitaciones.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'Acepto que WebPros me envíe newsletters y ofertas individuales de acuerdo con la <a>Política de privacidad</a> de WebPros.',
            text: 'Puedo retirar mi consentimiento haciendo clic en el enlace que se encuentra en las comunicaciones que he recibido',
        },
    },

    components: {
        List: {
            selectAll: 'Seleccionar todo',
            deselectAll: 'Deseleccionar',
            selectedRows: '{selectedRows, plural, one{# de {totalRows, plural, one{# objeto} other{# objetos}} seleccionado} other{# de {totalRows, plural, one{# objeto} other{# objetos}} seleccionados}}',
            totalRows: '{totalRows, plural, one{# objeto en total} other{# objetos en total}}',
            foundRows: '{foundRows, plural, one{# objeto encontrado} other{# objetos encontrados}}',
        },
        DataList: {
            groupSearch: 'Buscar',
        },
    },
};