// Copyright 2024. WebPros International GmbH. All rights reserved.

import { IdToken, RedirectLoginOptions, RedirectLoginResult } from '@auth0/auth0-spa-js';
import { AuthClient } from './AuthClient';
import { getCustomClaimName } from '@platform360/libs/common/custom-jwt-claims';

export default class MockedAuthClient implements AuthClient {
    public getIdTokenClaims(): Promise<IdToken | undefined> {
        return Promise.resolve({
            __raw: 'auth-token',
            email: 'john-doe@tld.com',
            sub: 'auth|fake-id',
            [getCustomClaimName('teamId')]: 1,
        });
    }

    public getTokenSilently(): Promise<string> {
        return Promise.resolve('mocked-auth-token');
    }

    public handleRedirectCallback(): Promise<RedirectLoginResult> {
        return Promise.resolve({
            appState: {},
        });
    }

    public loginWithRedirect({ authorizationParams }: RedirectLoginOptions): Promise<void> {
        if (authorizationParams?.redirect_uri) {
            window.location.href = authorizationParams.redirect_uri;
        }

        return Promise.resolve();
    }

    public logout(): Promise<void> {
        window.location.href = '/auth/logout-callback';

        return Promise.resolve();
    }
}
