// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'Auth token is missing or not valid.',
        invitationNotExist: 'Invitation does not exist.',
        invitationExpired: 'The invitation is expired.',
        invitationConflict: 'Invited user is already registered.',
    },
    SignTermsOfUse: {
        title: 'Privacy and Terms',
        descriptionText1: 'To create a WebPros Account, you must agree to the WebPros Terms of Use and Privacy Policy.',
        descriptionText2: 'You may also subscribe to our newsletter to receive product updates and individual offers.',
        privacyPolicyAgreementTitle: 'I agree to the <termsLink>Terms of Use</termsLink>. I understand that WebPros will process my personal data according to the WebPros <privacyLink>Privacy Policy</privacyLink>',
        continueBtn: 'Continue',
        errors: {
            invalidToken: 'Your current session has expired. Please <a>log in again</a> to continue.',
        },
    },
    ChangePassword: {
        successText: 'Your password has been changed successfully. Click the button below to log in.',
        loginBtn: 'Log in',
    },
    ChangePasswordForm: {
        setTitle: 'Set password',
        passwordLabel: 'New password',
        submitButton: 'Confirm',
        description: 'Enter your new password and confirm it. It must contain at least 8 characters.',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'Password reset email sent',
        successSendResetText: 'We sent an email to {email} with a link to reset your password.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Expired link',
        resetText: 'The password reset link has expired.',
        setText: 'The link to set a password is no longer valid. For your security, the link to set a password is valid for 4 hours. If you did not have time to use the link to set your password, you can re-enter your email below and set the password through the password change procedure.',
        emailLabel: 'Email:',
        submitButton: 'Send',
        bottomText: 'Do you remember the password? You can <a>Log in now</a>.',
    },
    LandingPage: {
        signUpButton: 'Create WebPros account',
        signInButton: 'Log in',
        title: 'Enablement Ecosystem with WebPros',
        description: 'Your WebPros account connects all your WebPros products and services. Log in to manage your account.',
    },
    ResetPassword: {
        emailLabel: 'Email',
        backBtn: 'Back',
        nextBtn: 'Next',
        description: 'Enter your email address and we will send you an email with a link to reset your password.',
        successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
        successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
        resendButton: 'Resend',
        retryCountdown: 'Resend email in {time}',
    },
};
