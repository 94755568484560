// Copyright 2024. WebPros International GmbH. All rights reserved.

import { makeEnum, UnionOf } from '@platform360/libs/common/make-enum';

export const { Connections, connectionValues, isConnection } = makeEnum(
    'Connection',
    'email',
    'Username-Password-DB',
    'platform360',
    'cpanelid',
    'google-oauth2',
    'facebook',
    'Username-Password-Authentication',
);

export type Connection = UnionOf<typeof connectionValues>;
