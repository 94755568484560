// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useState } from 'react';
import { Paragraph, FormErrors } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import ChangePasswordInvalidTokenForm from './ChangePasswordInvalidTokenForm';
import { SetPasswordUseCase } from '@platform360/auth/web/types';

type ChangePasswordInvalidTokenProps = {
    useCase: SetPasswordUseCase;
    resetPassword: (email: string) => void;
    errors?: FormErrors;
    isComplete: boolean;
    isLoading: boolean;
};

export const ChangePasswordInvalidToken = ({
    useCase,
    errors,
    resetPassword,
    isComplete,
    isLoading,
}: ChangePasswordInvalidTokenProps) => {
    const translate = useTranslate('auth.ChangePasswordInvalidToken');

    const [email, setEmail] = useState<string>();

    if (isComplete) {
        return <Paragraph>{translate('successSendResetText', { email })}</Paragraph>;
    }

    return (
        <ChangePasswordInvalidTokenForm
            useCase={useCase}
            errors={errors}
            isLoading={isLoading}
            onSubmit={resetPassword}
            onEmailChange={setEmail}
        />
    );
};

export default ChangePasswordInvalidToken;
