// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions } from '@platform360/libs/shared-web/typings/api';
import { WithDateTimeProps } from '@platform360/libs/shared-web/types';
import { DateTime } from 'luxon';

export type Tag = 'owner' | 'you';

export type Member = {
    id: number;
    email: string;
    fullName: string;
    isMfaEnabled: boolean;
    tags: Tag[];
};

type TeamInvitationResponse = {
    id: number;
    userEmail: string;
    expireAt: string;
};

export type TeamInvitation = WithDateTimeProps<TeamInvitationResponse, 'expireAt'>;

export type AddInvitationsPayload = {
    invitations: string[];
};

export const getTeamMembers = async ({ signal }: FetcherOptions) => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<Member[]>>('/accounts/me/team/members', { signal });
    return data;
};

export const deleteTeamMember = async (userId: number) =>
    apiClient.delete(`/accounts/me/team/members/${userId}`);

const normalizeTeamInvitation = (invitation: TeamInvitationResponse) => ({
    ...invitation,
    expireAt: DateTime.fromISO(invitation.expireAt),
});

export const getTeamInvitations = async ({ signal }: FetcherOptions): Promise<TeamInvitation[]> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<TeamInvitationResponse[]>>(
        '/accounts/me/team/invitations',
        {
            signal,
        },
    );

    return data.map(normalizeTeamInvitation);
};

export const addTeamInvitations = async (
    payload: AddInvitationsPayload,
): Promise<TeamInvitation[]> => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<TeamInvitationResponse[]>>(
        '/accounts/me/team/invitations',
        {
            invitations: payload.invitations.map((email) => ({
                email,
            })),
        },
    );
    return data.map(normalizeTeamInvitation);
};

export const resendTeamInvitation = async (invitationId: number): Promise<TeamInvitation> => {
    const {
        data: { data },
    } = await apiClient.post<ApiResponse<TeamInvitationResponse>>(
        `/accounts/me/team/invitations/${invitationId}/resend`,
    );

    return normalizeTeamInvitation(data);
};

export const deleteTeamInvitation = async (id: number) =>
    apiClient.delete(`/accounts/me/team/invitations/${id}`);
