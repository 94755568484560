// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        invalidToken: 'Токен авторизации недействительный или отсутствует.',
        invitationNotExist: 'Приглашения не существует.',
        invitationExpired: 'Срок действия приглашения истек.',
        invitationConflict: 'Приглашенный пользователь уже зарегистрирован.',
    },
    SignTermsOfUse: {
        title: 'Конфиденциальность и Условия',
        descriptionText1: 'Чтобы создать учетную запись WebPros, вам необходимо принять Условия использования и Политику конфиденциальности WebPros.',
        descriptionText2: 'Вы также можете подписаться на нашу информационную рассылку, чтобы получать новости о продуктах и индивидуальные предложения.',
        privacyPolicyAgreementTitle: 'Я принимаю <termsLink>Условия использования</termsLink>. Я понимаю, что компания WebPros будет обрабатывать мои персональные данные в соответствии с <privacyLink>Политикой конфиденциальности</privacyLink> WebPros.',
        continueBtn: 'Продолжить',
        errors: {
            invalidToken: 'Срок действия вашего текущего сеанса истек. <a>Войдите еще раз</a> для продолжения.',
        },
    },
    ChangePassword: {
        successText: 'Ваш пароль был успешно изменен. Нажмите кнопку ниже, чтобы выполнить вход.',
        loginBtn: 'Войти',
    },
    ChangePasswordForm: {
        setTitle: 'Задать пароль',
        passwordLabel: 'Новый пароль',
        submitButton: 'Подтвердить',
        description: 'Введите новый пароль и подтвердите его. Пароль должен быть длиной не менее 8 символов.',
    },
    ChangePasswordInvalidToken: {
        successSendResetTitle: 'Письмо сброса пароля отправлено',
        successSendResetText: 'Мы отправили письмо со ссылкой для сброса пароля на адрес {email}.',
    },
    ChangePasswordInvalidTokenForm: {
        title: 'Срок действия ссылки истек',
        resetText: 'Срок действия ссылки для сброса пароля истек.',
        setText: 'Ссылка для установки пароля больше недействительна. В целях безопасности ссылка для настройки пароля действует в течение 4 часов. Если вы не успели воспользоваться ссылкой, чтобы создать пароль, повторно введите свой адрес электронной почты в поле ниже и установите пароль, воспользовавшись процедурой изменения пароля.',
        emailLabel: 'Электронная почта:',
        submitButton: 'Отправить',
        bottomText: 'Вспомнили пароль? Вы можете <a>войти сейчас</a>.',
    },
    LandingPage: {
        signUpButton: 'Создать учетную запись WebPros',
        signInButton: 'Войти',
        title: 'Экосистема веб-служб от WebPros',
        description: 'Учетная запись WebPros позволяет вам подключаться ко всем продуктам и услугам WebPros. Для управления учетной записью выполните вход.',
    },
    ResetPassword: {
        emailLabel: 'Электронная почта',
        backBtn: 'Назад',
        nextBtn: 'Далее',
        description: 'Введите свой адрес электронной почты, и мы отправим вам письмо со ссылкой для сброса пароля.',
        successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
        successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
        resendButton: 'Отправить повторно',
        retryCountdown: 'Повторно отправить письмо через {time}',
    },
};