// Copyright 2024. WebPros International GmbH. All rights reserved.

import Page from '@platform360/libs/shared-web/components/Page';
import Profile from './Profile';
import WelcomeBanner from './WelcomeBanner';

export const HomePage = () => (
    <Page contentAddon={<WelcomeBanner />}>
        <Profile />
    </Page>
);
