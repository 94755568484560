// Copyright 2024. WebPros International GmbH. All rights reserved.

import { useQuery } from '@tanstack/react-query';
import { getConsents } from '@platform360/auth/web/api/auth';
import { createQuery } from '@platform360/libs/shared-web/query';

export type UseGetConsentsQueryVariables = undefined;

export type UseGetConsentsQueryData = PromiseValue<ReturnType<typeof getConsents>>;

const useGetConsentsQuery = createQuery<UseGetConsentsQueryVariables, UseGetConsentsQueryData>({
    queryName: 'auth/useGetConsentsQuery',
    fetcher: getConsents,
    useQuery: (options) =>
        useQuery({
            ...options,
            refetchInterval: false,
            refetchOnWindowFocus: false,
        }),
});

export default useGetConsentsQuery;
