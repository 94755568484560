// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Form, FormErrors, FormFieldText, Paragraph, Link } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { SetPasswordUseCase } from '@platform360/auth/web/types';
import { useAuth } from '@platform360/libs/shared-web/auth';

type ChangePasswordInvalidTokenFormProps = {
    errors?: FormErrors;
    onEmailChange: (email: string) => void;
    onSubmit: (email: string) => void;
    isLoading: boolean;
    useCase: SetPasswordUseCase;
};

type FormValues = {
    email: string;
};

const DataTestIds = {
    FORM: 'ChangePassword--invalidTokenForm',
};

export const ChangePasswordInvalidTokenForm = ({
    useCase,
    errors,
    onSubmit,
    onEmailChange,
    isLoading,
}: ChangePasswordInvalidTokenFormProps) => {
    const { login } = useAuth();
    const translate = useTranslate('auth.ChangePasswordInvalidTokenForm');
    const handleSubmit = ({ email }: FormValues) => onSubmit(email);

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                state={isLoading ? 'submit' : undefined}
                errors={errors}
                applyButton={false}
                cancelButton={false}
                submitButton={{
                    children: translate('submitButton'),
                    type: 'submit',
                    fill: true,
                }}
                data-type={DataTestIds.FORM}
                vertical
            >
                <Paragraph>
                    {useCase === 'reset' ? translate('resetText') : translate('setText')}
                </Paragraph>
                <FormFieldText
                    name="email"
                    onChange={onEmailChange}
                    label={translate('emailLabel')}
                    size="fill"
                    autoFocus
                />
            </Form>
            {useCase === 'reset' ? (
                <>
                    <br />
                    <Paragraph>
                        {translate('bottomText', {
                            a: (chunk) => <Link onClick={() => login()}>{chunk}</Link>,
                        })}
                    </Paragraph>
                </>
            ) : undefined}
        </>
    );
};

export default ChangePasswordInvalidTokenForm;
