// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, SectionItem } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { DpaDialog } from '@platform360/accounts/web/profile/DpaDialog';

export type DpaManagerProps = {
    isAccepted: boolean;
    isEditing: boolean;
    onStartEdit: () => void;
    onStopEdit: () => void;
};

export const DpaManager = ({ isAccepted, isEditing, onStartEdit, onStopEdit }: DpaManagerProps) => {
    const translate = useTranslate('accounts.profile.DpaManager');

    return (
        <SectionItem title={translate('title')} data-type="dpa-manager-section">
            {isAccepted ? translate('accepted') : translate('notAccepted')}
            <Button
                ghost
                icon="pencil"
                data-type="edit-dpa-button"
                onClick={onStartEdit}
                title={translate('buttonEdit')}
            />
            <DpaDialog
                isOpen={isEditing}
                onAccept={onStopEdit}
                onDecline={onStopEdit}
                onClose={onStopEdit}
                initialValue={isAccepted}
            />
        </SectionItem>
    );
};
