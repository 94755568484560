// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { AxiosRequestConfig } from 'axios';
import { Consent } from '@platform360/libs/common/consents';

export type SignUpByInvitationRequest = {
    password: string;
    firstName: string;
    lastName: string;
    consents: Consent[];
};

export const signUpByInvitation = (request: SignUpByInvitationRequest) =>
    apiClient.post('/auth/sign-up/invitation', request, {
        skipAuthRefresh: true,
    } as AxiosRequestConfig);
