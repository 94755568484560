// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    Legal: {
        title: 'Informations légales',
        terms: 'Conditions d\'utilisation',
        privacyPolicy: 'Politique de confidentialité',
        dpa: 'Contrat relatif au traitement des données',
        optOuts: 'Opt-Outs',
        cookies: 'Déclaration sur la confidentialité et les cookies',
        gaHeader: 'Désactivation de Google Analytics',
        gaOptOut: 'Cliquez ici pour désactiver Google Analytics',
        cookieHeader: 'Ajustement du consentement aux cookies',
        cookieModify: 'Modifier votre consentement aux cookies',
        cookieWithdraw: 'Retirer votre consentement aux cookies',
        cookieSuccess: 'Votre consentement a bien été retiré',
    },
};