// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, ContentLoader, Heading, SectionItem, StatusMessage } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { EditConsentDialog } from './EditConsentDialog';
import { Consent } from '@platform360/accounts/web/types';
import { AGREEMENT_TYPE_MARKETING } from '@platform360/libs/common/agreements';

export type ConsentManagerProps = {
    consents: Consent[];
    isLoading: boolean;
    isEditing: boolean;
    editingConsent?: Consent;
    isSaving: boolean;
    isError: boolean;
    onStartEditing: (consent: Consent) => void;
    onStopEditing: () => void;
    onChange: (value: boolean) => void;
    onSave: (consent: Consent) => Promise<void>;
};

const ConsentManager = ({
    consents,
    isLoading,
    isEditing,
    editingConsent,
    isSaving,
    isError,
    onStartEditing,
    onStopEditing,
    onChange,
    onSave,
}: ConsentManagerProps) => {
    const translate = useTranslate('accounts.profile.ConsentManager');
    const consentTitles: Record<string, string> = {
        [AGREEMENT_TYPE_MARKETING]: translate('consentTitles.announce'),
    };

    if (isLoading) {
        return <ContentLoader />;
    }

    if (isError) {
        return (
            <StatusMessage intent="danger">
                <Heading level={5}>{translate('error')}</Heading>
            </StatusMessage>
        );
    }

    return (
        <>
            {consents.map((consent) => (
                <SectionItem key={consent.type} title={consentTitles[consent.type]}>
                    {consent.enabled ? translate('subscribed') : translate('notSubscribed')}
                    <Button
                        ghost
                        icon="pencil"
                        data-type="edit-consent-button"
                        onClick={() => onStartEditing(consent)}
                        title={translate('buttonEdit')}
                    />
                </SectionItem>
            ))}
            {editingConsent ? (
                <EditConsentDialog
                    isOpen={isEditing}
                    onClose={onStopEditing}
                    consent={editingConsent}
                    onChange={onChange}
                    isSaving={isSaving}
                    onSubmit={onSave}
                />
            ) : undefined}
        </>
    );
};

export default ConsentManager;
