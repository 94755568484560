// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    CopyText: {
        copied: 'Copiado para a área de transferência.',
    },
    Page: {
        ImpersonationBanner: {
            title: 'Você está se passando por uma conta diferente.',
            depersonateButton: 'Parar de se Passar Por Outro',
            auth0IdLabel: 'auth0Id:',
            teamGuidLabel: 'teamGuid:',
            successToast: 'Você não está mais se passando por uma conta diferente.',
        },
        UserMenu: {
            team: 'Organização',
            logout: 'Sair',
            changePassword: 'Mudar senha',
            businessProfile: 'Perfil do negócio',
            apiTokens: 'Tokens de API',
        },
    },

    common: {
        errors: {
            isNotEmpty: 'Este campo obrigatório está vazio.',
            required: 'Este campo obrigatório está vazio.',
            email: 'O valor deve ser um endereço de e-mail válido.',
            isEmail: 'O valor deve ser um endereço de e-mail válido.',
            min: '{min, plural, one{O valor deve ter pelo menos # caractere.} other{O valor deve ter pelo menos # caracteres.}}',
            maxLength: '{max, plural, one{O valor deve ter no máximo # caractere.} other{O valor deve ter no máximo # caracteres.}}',
            unique: 'O valor deve ser único.',
            unknown: 'Erro desconhecido: {message}',
        },
    },

    auth0: {
        errors: {
            errorPasswordIsEmpty: 'Você deve digitar uma senha.',
            errorPasswordIsWrong: 'Senha incorreta.',
            errorPasswordIsTooWeak: 'A senha é muito fraca.',
            errorPasswordContainsPersonalInfo: 'A senha não deve conter informações pessoais.',
            errorPasswordTooCommon: 'A senha é muito comum.',
            errorPasswordPreviouslyUsed: 'A senha foi usada anteriormente.',
            errorInvalidOtp: 'O código inserido não é válido.',
        },
    },

    ConfirmationPopover: {
        cancel: 'Cancelar',
    },

    ZendeskChat: {
        confirmationPopover: {
            description1: 'A WebPros Account usa ZendeskChat, ums aplicação de terceiros.',
            description2: 'Ao prosseguir, concordo em usar o ZendeskChat, uma aplicação de terceiros. Isto pode envolver a transferência dos meus dados pessoais (por exemplo, endereço IP) para terceiros dentro ou fora da Europa. Para mais informações, leia nossa <a>Política de Privacidade</a>.',
            confirmButton: 'Iniciar o ZendeskChat',
        },
        supportFormPopover: {
            description: 'Nenhum agente está disponível no momento. Aguarde ou use nosso formulário de ticket para enviar uma solicitação.',
            confirmButton: 'Abrir Formulário',
        },
        ChatButton: {
            title: 'Suporte',
        },
    },

    emailRequired: "To log in to WebPros using your Facebook account, access to view the email address must be granted. Make sure that the corresponding permission is granted and try again.",
    userBlocked: "A conta foi suspensa devido a uma violação do acordo do usuário. Se você acredita que isso é um erro, envie um e-mail para este endereço {emailLink}.",
    invalidDomain: 'Acesso negado. O e-mail do usuário não está na lista de permissões.',

    errorDefault: 'Algo deu errado. Por favor, tente novamente mais tarde.',
    errorDefaultWithReqId: 'Algo deu errado. Por favor, tente novamente mais tarde. Identificação do Pedido: {requestId}.',
    errorTooManyRequests: 'Limite de chamadas de API atingido para este endereço IP. Por favor, tente novamente em um minuto.',
    errorTokenRenewal: 'Sua sessão expirou. Faça login novamente.',
    errorNetworkIssue: 'Parece que você está tendo problemas para se conectar à rede no momento.',

    errorCopyToClipboard: 'Ocorreu um erro de cópia, tente copiar manualmente.',

    PrivacyPolicy: {
        privacyPolicyText: 'A WebPros International GmbH processará os dados fornecidos para processar sua solicitação de acordo com sua <a>Política de Privacidade</a>.',
    },

    Auth: {
        errors: {
            userDoesntExist: 'O usuário não existe.',
            bounceOrComplaint: 'Este endereço de e-mail não existe ou bloqueou nossa mensagem como spam. Por favor <webProsContactLink>entre em contato</webProsContactLink> com a equipe de suporte da WebPros Account.',
            userIsNotVerified: 'O usuário existe, mas não foi verificado. Enviamos um novo e-mail de verificação. Verifique sua caixa postal.',
            userExist: 'O usuário já existe.',
            errorPasswordIsEmpty: 'A senha é necessária.',
            errorPasswordIsWrong: 'Senha incorreta.',
            errorPasswordIsTooWeak: 'A senha é muito fraca.',
        },
        AuthContainer: {
            alreadyHaveAnAccount: 'Já possui uma conta?',
            loginLink: 'Log in',
        },
        SignUp: {
            signUp: 'Criar conta',
            emailLabel: 'E-mail',
            passwordLabel: 'Senha',
            privacyPolicyAgreementTitle: 'Concordo com os <termsLink>Termos de Uso</termsLink> e que a WebPros processará meus dados pessoais fornecidos em seus sistemas internos de acordo com a <privacyLink>Política de Privacidade</privacyLink> da WebPros',
            SignUpByInvitation: {
                emailDescription: 'Você será cadastrado com o e-mail acima',
                invitationSucceed: 'Você se inscreveu com sucesso. Por favor, faça o login agora.',
            },
            SignUpForm: {
                NameFormField: {
                    firstNamePlaceholder: 'Primeiro nome',
                    lastNamePlaceholder: 'Sobrenome',
                    nameLabel: 'Nome',
                },
            },
        },
        ResetPasswordDrawer: {
            title: 'Redefinir senha',
            text: 'Digite seu endereço de e-mail e nós lhe enviaremos um e-mail com um link de redefinição de senha.',
            emailLabel: 'E-mail:',
            submitButton: 'Enviar',
            successTitle: 'E-mail de Redefinição de Senha Enviado',
            successText1: 'If an account associated with {email} exists, you will receive an email with a password reset link. Click the link to log in to your WebPros Account and set a new password.',
            successText2: 'If you do not receive the email, check the Spam folder or click <b>Resend</b> after a few minutes.',
            resendButton: 'Reenviar',
            loginForm: 'Faça o login agora',
            retryCountdown: 'O reenvio é possível em {time}',
        },
    },
    Footer: {
        copyright: '© {year} WebPros International GmbH. Todos os direitos reservados.',
        company: 'Empresa',
        knowledgeBase: 'Base de Conhecimento',
        aboutWebPros: 'Sobre o WebPros',
        legal: 'Jurídico',
        cookieSettings: 'Configurações de Cookies',
        privacyPolicy: 'Política de Privacidade',
        documentation: 'Documentação',
        contactUs: 'Contato',

    },
    Form: {
        requiredLegend: 'Os campos obrigatórios',
        submitButton: 'OK',
        cancelButton: 'Cancelar',
    },

    FormField: {
      addMore: 'Adicione mais um',
      remove: 'Remover',
      clear: 'Limpar',
    },

    FormFieldPassword: {
        improvePassword: 'Para uma senha mais segura:',
        passwordTooShort: 'Crie uma senha mais longa.',
        lettersLowerCase: 'Use pelo menos um caractere minúsculo.',
        lettersUpperCase: 'Use pelo menos um caractere maiúsculo.',
        numbers1: 'Use pelo menos um número.',
        numbers3: 'Use pelo menos três números.',
        specialChar1: 'Use pelo menos um caractere especial (por exemplo: !, @, #, $, % ,^, &, *, ?, _, ~).',
        specialChar2: 'Use pelo menos dois caracteres especiais (por exemplo: !, @, #, $, %, ^, &, *, ?, _, ~).',
        comboUpperAndLower: 'Use caracteres maiúsculos e minúsculos.',
        comboLettersAndNumbers: 'Use números e caracteres.',
        comboLettersNumbersSpecial: 'Use números, caracteres e caracteres especiais.',
        yourPasswordIsStrong: 'Você pode torná-lo ainda mais forte aumentando seu comprimento.',
        passwordStrength: 'A força da senha é %%strength%%.',
        strengthVeryStrong: 'MUITO FORTE',
        strengthStrong: 'FORTE',
        strengthVeryWeak: 'MUITO FRACA',
        strengthWeak: 'FRACA',
        strengthMedium: 'MÉDIA',
        hidePassword: 'Esconder a senha',
        showPassword: 'Mostrar senha',
        generateButton: 'Gerar',
        generateButtonHint: 'Gerar uma senha forte',
    },

    Pagination: {
        totalShort: 'de %%total%%',
        itemsPerPage: 'Itens por página',
        all: 'Todos',
        total: 'Página %%current%% de %%total%%',
    },

    ListEmptyView: {
        filteredTitle: 'Não foi possível encontrar nenhum item que corresponda ao filtro',
        emptyTitle: 'Parece que não há nada aqui',
        filteredDescription: 'Refine sua consulta ou remova alguns dos critérios do filtro e tente novamente.',
    },

    Dialog: {
        cancelButton: 'Cancelar',
    },

    GaOptOutLink: {
        gaSuccess: 'O Google Analytics parou de rastrear.',
    },

    InPlaceText: {
        ok: 'Salvar (Ctrl+Enter)',
        cancel: 'Cancelar (Esc)',
        edit: 'Editar',
        add: 'Adicionar',
    },

    Team: {
        Layout: {
            title: 'Organização',
            members: 'Membros',
            invitations: 'Convites',
        },
        settings: 'Configurações',
        Members: {
            email: 'E-mail',
            fullName: 'Nome completo',
            mfaStatus: 'Status MFA',
            tags: 'Marcas especiais',
            remove: 'Remover',
            removeMemberConfirmation: '<b>{name}</b> não terá mais acesso à organização e aos recursos compartilhados dentro dela, servidores, websites e licenças.',
            SpecialMarks: {
                you: 'Você',
                owner: 'Proprietário',
            },
            Onboarding: {
                title: 'Crie sua organização em nossa plataforma adicionando seus colegas',
                description: 'Nossa plataforma oferece recursos para gerenciamento e colaboração eficientes da organização. Os membros da sua organização podem trabalhar com você em servidores conectados acessando-os diretamente daqui. Você não precisa mais usar senhas compartilhadas inseguras ou arranjos de acesso complicados.',
                addMembersButton: 'Adicionar membros da organização',
            },
            MultifactorStatus: {
                enabled: 'MFA Habilitado',
                disabled: 'MFA Não habilitado',
            },
        },
        Invitations: {
            email: 'E-mail',
            expireAt: 'Expira Em',
            expired: 'Vencido',
            add: 'Adicionar',
            revoke: 'Revogar',
            Onboarding: {
                title: 'Aqui você pode convidar os membros de sua organização',
                description: 'Adicione seus colegas para compartilhar com eles servidores e websites.',
                addButton: 'Convidar novo membro',
            },
            ListActions: {
                resendInvitationTooltip: 'Reenviar convite.',
                successMessage: 'E-mail de convite enviado novamente.',
            },
            AddInvitations: {
                title: 'Adicionar novos membros à organização',
                infoMessage: 'Convide os membros da sua organização para participar inserindo seus endereços de e-mail de trabalho abaixo.',
                infoDescription: 'Os endereços de e-mail de trabalho são preferidos aos endereços de e-mail pessoais para proteger as informações pessoais dos usuários.',
                emailsLabel: 'Endereços de e-mail para link de convite',
                submitButton: 'Enviar convite',
                successMessage: '{count, plural, =1{Convite para {email} foi enviado.} one{# o convite foi enviado.} other{# convites foram enviados.}}',
            },
            RevokeInvitationsOperation: {
                revoke: 'Revogar',
                noSelectedInvitations: 'Selecione um ou mais convites para revogar.',
                revokeInvitationConfirmation: '{count, plural, =1{Revogar<b>{email}</b>?} one{Revogar<b>#</b> convite?} other{Revogar<b>#</b> convites?}} Você não pode desfazer esta ação.',
                successRevokeInvitation: '{count, plural, =1{O e-mail<b>{email}</b> foi revogado.} one{<b>#</b> convite foi revogado.} other{<b>#</b> convites foram revogados.}}',
            },
        },
    },

    consents: {
        sendPlatform360Announce: {
            title: 'A WebPros pode me fornecer boletins informativos e ofertas individuais de acordo com a <a>Política de Privacidade</a> da WebPros.',
            text: 'Posso revogar este consentimento clicando no link em qualquer comunicação recebida',
        },
    },

    components: {
        List: {
            selectAll: 'Selecionar tudo',
            deselectAll: 'Desmarcar',
            selectedRows: '{selectedRows, plural, one{# de {totalRows, plural, one{# item} other{# itens}} selecionado} other{# de {totalRows, plural, one{# item} other{# itens}} selecionados}}',
            totalRows: '{totalRows, plural, one{total de # item} other{total de # itens}}',
            foundRows: '{foundRows, plural, one{# item encontrado} other{# itens encontrados}}',
        },
        DataList: {
            groupSearch: 'Pesquisar',
        },
    },
};