// Copyright 2024. WebPros International GmbH. All rights reserved.

import apiClient from '@platform360/libs/shared-web/helpers/apiClient';
import { ApiResponse, FetcherOptions, FilterQuery } from '@platform360/libs/shared-web/typings/api';
import { Connection } from '@platform360/libs/common/connection';

export type UserResponse = {
    email: string | null;
    name: string | null;
    blocked: boolean;
    blockingComment: string;
    id: number;
    auth0Id: string;
    userGuid: string;
    webprosUser: boolean;
    signupSource: string;
    emailVerified: boolean;
    lastLogin?: string | null;
    createdAt: string;
    isDpaAccepted: boolean;
    isMarketingConsentAccepted: boolean;
    auth0Connection: Connection | null;
};

type GetUsersOptions = FetcherOptions<FilterQuery>;

export const getUsers = async ({ variables, signal }: GetUsersOptions): Promise<UserResponse[]> => {
    const {
        data: { data },
    } = await apiClient.get<ApiResponse<UserResponse[]>>('/admin-panel/users', {
        params: variables,
        signal,
    });

    return data;
};

type UpdateUserOptions = {
    userId: number;
    data: Partial<UserResponse>;
};

export const updateUser = async ({
    userId,
    data: updatedData,
}: UpdateUserOptions): Promise<void> => {
    await apiClient.patch(`/admin-panel/users/${userId}`, updatedData);
};

export const deleteUser = async (userId: number): Promise<void> => {
    await apiClient.delete(`/admin-panel/users/${userId}`);
};

export const impersonate = async (userId: number): Promise<void> => {
    await apiClient.post(`/admin-panel/users/${userId}/impersonate`);
};
