// Copyright 2024. WebPros International GmbH. All rights reserved.

import { ApplicationSettings } from '@platform360/libs/shared-web/application-settings/context';
import PlatformNotFoundPage from '@platform360/libs/shared-web/components/PlatformNotFoundPage';
import HomePage from '@platform360/accounts/web/components/HomePage';
import WebprosLogo from '@platform360/libs/shared-web/components/WebprosLogo';
import { cookieModify, cookieWithdraw } from '@platform360/app/web/components/CookieManager';
import Login from '@platform360/auth/web/components/Login';

const globalSsoApplicationSettings: ApplicationSettings = {
    document: {
        title: 'WebPros Account',
        favicon: '/global-sso.ico',
        darkThemeDataAttribute: 'legacy-dark',
    },
    logo: <WebprosLogo />,
    homePage: <HomePage />,
    notFoundPage: <PlatformNotFoundPage />,
    header: {
        baseUrl: '/',
        userMenu: {
            businessProfile: false,
            changePassword: false,
            apiTokens: false,
            organization: false,
        },
    },
    loginPage: <Login />,
    cookieManager: {
        cookieModify,
        cookieWithdraw,
    },
};

export default globalSsoApplicationSettings;
