// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        newInvitation: 'Eine Einladung für diese E-Mail-Adresse ist bereits vorhanden.',
        invitationMemberExist: 'Der Benutzer für diese E-Mail ist bereits als Teammitglied registriert.',
        bounceOrComplaint: 'Diese E-Mail-Adresse ist nicht vorhanden oder die Nachricht wurde als Spam eingestuft. Bitte wenden Sie sich an das Plesk 360-Supportteam.',
        emailsAreEqual: 'Die E-Mail-Adresse kann nicht mit der aktuellen Adresse übereinstimmen.',
        consentKeeperNotAvailable: 'Es kann nicht überprüft werden, ob die Vereinbarung zur Datenverarbeitung unterzeichnet ist. Bitte versuchen Sie es später noch einmal.',
    },
    profile: {
        MfaControl: {
            mfaEnable: 'E-Mail zur Registrierung senden',
            mfaCheckEmail: 'Öffnen Sie die erhaltene E-Mail, um die Registrierung abzuschließen.',
            mfaDisable: 'MFA deaktivieren',
            mfaDisableSuccessToast: 'MFA ist deaktiviert.',
            MfaDisableConfirmationDialog: {
                dialogTitle: 'MFA deaktivieren',
                description: 'Multi-Faktor-Authentifizierung deaktivieren?',
                currentPassword: 'Aktuelles Passwort',
                otp: 'Einmaliger Code',
                otpDescription: 'Von Ihrer Authentifizierungs-App bereitgestellter Einmalcode zur Zwei-Faktor-Authentifizierung.',
                buttonSave: 'Bestätigen',
                buttonCancel: 'Abbrechen',
            },
        },
        AccountRemoval: {
            removeAccountButton: 'Konto löschen',
            removeAccount: 'Konto <b>{email}</b> in WebPros Account löschen?',
            removeAccountWithMembers: '{teamMembersCount, plural, one{Ihr Konto <b>{email}</b> und # Organisationsmitglied in WebPros Account löschen?} other{Ihr Konto <b>{email}</b> und # Organisationsmitglieder in WebPros Account löschen?}}',
            removingAccount: 'Konto wird entfernt...',
            loading: 'Wird geladen...',
        },
        ChangePassword: {
            title: 'Passwortänderung',
            submit: 'Passwort ändern',
            labelCurrentPassword: 'Aktuelles Passwort',
            labelNewPassword: 'Neues Passwort',
            labelRepeatPassword: 'Passwort bestätigen',
            forgotPassword: 'Passwort vergessen?',
            successMessage: 'Ihr Passwort wurde geändert.',
            passwordMatch: 'Die eingegebenen Passwörter stimmen nicht überein.',
        },
        ChangeableEmail: {
            buttonEdit: 'Bearbeiten',
            dialogTitle: 'E-Mail-Adresse ändern',
            description: 'Wir senden Ihnen eine E-Mail mit einer Anleitung zum Aktualisieren Ihrer Adresse.',
            email: 'Neue E-Mail-Adresse',
            currentPassword: 'Aktuelles Passwort',
            otp: 'Einmaliger Code',
            otpDescription: 'Der einmalige Code, der als Teil der Zwei-Faktor-Authentifizierung verwendet wird. Sie finden ihn in Ihrer Authenticator-App.',
            consentsTitle: 'Verwaltung der Zustimmung',
            buttonSave: 'Senden',
            buttonCancel: 'Abbrechen',
            successMessage: 'Wir haben eine Bestätigungs-E-Mail an <b>{email}</b> gesendet. Bitte folgen Sie der Anleitung, um Ihre neue E-Mail-Adresse zu bestätigen.',
        },
        DpaManager: {
            title: 'Vereinbarung zur Datenverarbeitung',
            accepted: 'Akzeptiert',
            notAccepted: 'Nicht akzeptiert',
            buttonEdit: 'Bearbeiten',
        },
        DpaDialog: {
            title: 'Vereinbarung zur Datenverarbeitung',
            subtitle: 'Um einige unserer Funktionen zu nutzen, müssen Sie die Bedingungen der Vereinbarung zur Datenverarbeitung akzeptieren.',
            dpaLinkText: 'Vereinbarung zur Datenverarbeitung',
            buttonAccept: 'Akzeptieren',
            buttonDecline: 'Ablehnen',
            successMessage: 'Die Vereinbarung zur Datenverarbeitung wurde aktualisiert.',
        },
        ConsentManager: {
            error: 'Ein Fehler ist beim Laden der Zustimmungsdaten aufgetreten',
            successMessage: 'Die Zustimmungsdaten wurden aktualisiert',
            subscribed: 'Abonniert',
            notSubscribed: 'Nicht abonniert',
            buttonEdit: 'Bearbeiten',
            dialogTitle: 'Verwaltung der Zustimmung',
            buttonSave: 'Speichern',
            consentTitles: {
                announce: 'Projekt-Updates und geschäftliche Mitteilungen',
            },
        },
        ChangeableName: {
            successMessage: 'Name erfolgreich aktualisiert',
            updating: 'Wird aktualisiert',
            firstName: 'Vorname',
            lastName: 'Nachname',
        },
        Timezone: {
            buttonEdit: 'Bearbeiten',
            timezone: 'Zeitzone',
            dialogTitle: 'Zeitzone ändern',
            buttonCancel: 'Abbrechen',
            buttonSave: 'Speichern',
            successMessage: 'Zeitzone erfolgreich aktualisiert.',
        },
    },
    BusinessProfile: {
        title: 'Wir würden gern etwas mehr über Sie erfahren',
        save: 'Speichern',
        done: 'Fertig',
        answerLater: 'Später antworten',
        tour: {
            gotIt: 'OK',
            text: 'Sie können das Unternehmensprofil dann später im Kontomenü aktualisieren.',
        },
        firstSectionTitle: 'Ihr Unternehmen',
        secondSectionTitle: 'Wie verwenden Sie Plesk?',
        whoDoYouCreateWebsitesFor: 'Für wen erstellen Sie Websites?',
        clients: 'Kunden',
        ownPurposes: 'Für mich selbst',
        whatKindOfBusinessDoYouDo: 'In welchem Bereich ist Ihr Unternehmen tätig?',
        hosting: 'Hosting',
        agency: 'Agentur',
        isTheWebsiteForBusinessOrPrivatePurposes: 'Ist die Website für eine Privatperson oder für ein Unternehmen?',
        business: 'Unternehmen',
        private: 'Privat',
        whatIsYourCoreBusiness: 'In welchem Bereich arbeiten Sie hauptsächlich?',
        webHosting: 'Webhosting',
        vpsAndServerHosting: 'VPS und Serverhosting',
        iaas: 'Infrastructure-as-a-Service (IaaS)',
        saas: 'Software-as-a-Service (SaaS)',
        other: 'Sonstiges',
        webDevelopment: 'Entwicklung von Websites',
        webDesignOrManagement: 'Webdesign und Verwaltung von Websites',
        marketingOrMedia: 'Marketing und Medien',
        whichDepartmentDoYouBelongTo: 'In welcher Abteilung arbeiten Sie?',
        itAndSystemAdministration: 'IT und Systemadministration',
        systemsIntegration: 'Systemintegration',
        softwareDevelopment: 'Softwareentwicklung',
        marketingOrCommunication: 'Marketing und Kommunikation',
        forWhichKindOfOrganisationIsTheWebsite: 'Für wen ist diese Website?',
        privatePersonOrFamily: 'Privatperson oder Familie',
        association: 'Verband/Verein',
        nonProfitOrganisation: 'Non-Profit-Organisation',
        howManyEmployeesDoesYourCompanyHave: 'Wie viele Mitarbeiter hat Ihr Unternehmen?',
        selfEmployed: 'Einzelunternehmen',
        howManyMembersDoesYourOrganisationHave: 'Wie viele Mitglieder hat Ihre Organisation?',
        howManyServersDoYouManage: 'Wie viele Server verwalten Sie?',
        howManyServersDoYouManageDescription: 'mit und ohne Plesk',
        areTheyYourServers: 'Gehören diese Server Ihnen?',
        yes: 'Ja, die Server gehören mir.',
        no: 'Nein, ich verwalte die Server für andere Personen.',
        partly: 'Beides. Mir gehören einige Server, aber ich verwalte auch Server für andere Personen.',
        whatIsYourMainPurposeOfPlesk: 'Wofür setzen Sie Plesk ein?',
        whatIsYourMainPurposeOfPleskDescription: 'Alles Zutreffende auswählen',
        webhosting: 'Webhosting',
        emailHosting: 'E-Mail-Hosting',
        eCommerceHosting: 'E-Commerce-Hosting',
        wordPressManagement: 'WordPress-Verwaltung',
        systemAdministration: 'Systemadministration',
        collaboration: 'Zusammenarbeit',
        automation: 'Automatisierung',
        reselling: 'Reselling',
        howWouldYouDescribeYourTechnicalKnowledge: 'Wie würden Sie Ihre technischen Kenntnisse einstufen?',
        notTechnicalAtAll: 'Ich habe keine technischen Kenntnisse.',
        basicKnowledge: 'Ich habe Grundkenntnisse in Linux und Windows.',
        expert: 'Ich bin ein Experte und verwende Plesk zur Automatisierung von Abläufen.',
    },
    ApiTokens: {
        title: 'API-Tokens',
        name: 'Name',
        creationDate: 'Zeitpunkt der Generierung',
        lastUsageDate: 'Zuletzt verwendet',
        tokenNeverUsed: 'Nie',
        addButton: 'API-Token generieren',
        addButtonLimitExceededTooltip: 'Das Limit der API-Tokens wurde erreicht. Löschen Sie vorhandene Tokens, um neue zu erstellen.',
        countTooltip: '{max, plural, one{Maximal # Token pro Konto.} other{Maximal # Tokens pro Konto.}}',
        totalAmount: 'insgesamt {itemsAmount}/{maxAmount} <infoIcon></infoIcon>',
        tokenAddingSuccess: 'Token <info>{name}</info> generiert. Speichern Sie es jetzt an einem sicheren Ort. Sie können später nicht mehr darauf zugreifen.',
        emptyListTitle: 'API-Tokens für Ihre Anwendungen oder Skripts für den Zugriff auf die WebPros Account API generieren',
        emptyListDescription: 'Ihre API-Tokens werden hier angezeigt.',
        createToken: 'API-Token generieren',
        successCopied: 'In Zwischenablage kopiert.',
        successTokenDelete: 'Token gelöscht',
        expireAtTooltip: 'Token läuft ab um {expireAt}.',
        AddTokenDialog: {
            title: 'Neues API-Token',
            nameLabel: 'Tokenname',
            nameDescription: 'Wofür wird dieses Token verwendet?',
            addButton: 'Generieren',
            closeButton: 'Schließen',
            tokenInfo: 'Speichern Sie es jetzt an einem sicheren Ort. Sie können später nicht mehr darauf zugreifen. Wenn ein Token kompromittiert ist, generieren Sie sofort ein neues Token. Ihr neues Token:',
            close: 'Schließen',
            tokenSuccessCopied: 'Token in Zwischenablage kopiert.',
        },
        DeleteButton: {
            deleteButton: 'Token löschen',
            deleteConfirmation: 'Möchten Sie das Token <tooltip>{name}</tooltip> wirklich löschen? Anwendungen oder Skripts, die dieses Token verwenden, können dann nicht mehr auf die WebPros Account API zugreifen.',
            copy: 'In Zwischenablage kopieren',
            deleteConfirmationInputDesc: 'Geben Sie den Namen des Tokens ein, um die Löschung zu bestätigen.',
            deleteConfirmationButton: 'Token löschen',
        },
    },
    UpdateEmail: {
        loadingText: 'E-Mail-Adresse wird geändert...',
        ErrorMessage: {
            tryAgainButton: 'Erneut versuchen',
            errors: {
                forbidden: 'Der Link zum Ändern der E-Mail-Adresse ist abgelaufen. Bitte versuchen Sie es erneut.',
                changingEmailConflict: 'Ein Benutzer mit dieser E-Mail-Adresse ist bereits registriert.',
                unexpected: 'Der Dienst ist vorübergehend nicht verfügbar. Bitte versuchen Sie es später erneut.',
            },
        },
    },
    HomePage: {
        WelcomeBanner: {
            title: 'Willkommen bei WebPros Account!',
        },
        Profile: {
            nameField: 'Name',
            emailAddressField: 'E-Mail-Adresse',
            passwordField: 'Passwort',
            changePasswordLink: 'Passwort ändern',
            timezoneField: 'Zeitzone',
            personalInfoSection: 'Personenbezogene Daten',
            profilePictureSection: 'Profilbild',
            securitySection: 'Sicherheit',
            mfaField: 'Multi-Faktor-Authentifizierung',
            agreementsSection: 'Vereinbarungen',
            changeGravatar: 'Konfigurieren Sie Ihr Profilbild mithilfe von <link>Gravatar</link>.',
            removeAccountButton: 'Mein Konto entfernen',
            plesk360ConfigureName: 'Konfigurieren Sie Ihren Namen auf Ihrer <link>Plesk 360-Profilseite</link>.',
            plesk360ConfigureEmail: 'Konfigurieren Sie Ihre E-Mail-Adresse auf Ihrer <link>Plesk 360-Profilseite</link>.',
            plesk360ConfigurePassword: 'Konfigurieren Sie Ihr Passwort auf Ihrer <link>Plesk 360-Profilseite</link>.',
            cpanelStoreConfigureName: 'Konfigurieren Sie Ihren Namen auf Ihrer <link>cPanel-Profilseite</link>.',
            cpanelStoreConfigureEmail: 'Konfigurieren Sie Ihre E-Mail-Adresse auf Ihrer <link>cPanel-Profilseite</link>.',
            cpanelStoreConfigurePassword: 'Konfigurieren Sie Ihr Passwort auf Ihrer <link>cPanel-Profilseite</link>.',
            googleConfigureName: 'Konfigurieren Sie Ihren Namen auf Ihrer <link>Google-Kontoseite</link>.',
            googleConfigureEmail: 'Konfigurieren Sie Ihre E-Mail-Adresse auf Ihrer <link>Google-Kontoseite</link>.',
            googleConfigurePassword: 'Konfigurieren Sie Ihr Passwort auf Ihrer <link>Google-Kontoseite</link>.',
            facebookConfigureName: 'Konfigurieren Sie Ihren Namen auf Ihrer <link>Facebook-Kontoseite</link>.',
            facebookConfigureEmail: 'Konfigurieren Sie Ihre E-Mail-Adresse auf Ihrer <link>Facebook-Kontoseite</link>.',
            facebookConfigurePassword: 'Konfigurieren Sie Ihr Passwort auf Ihrer <link>Facebook-Kontoseite</link>.',
        },
    },
};