// Copyright 2024. WebPros International GmbH. All rights reserved.

import useSearchParams from '@platform360/libs/shared-web/helpers/useSearchParams';
import { useAuth } from '@platform360/libs/shared-web/auth';
import { getCookie } from '@platform360/libs/shared-web/cookies';
import { useEffectOnce } from '@platform360/libs/shared-web/hooks';

export const EmailVerified = () => {
    const email = getCookie('verified-email');
    const searchParams = useSearchParams();
    const { login } = useAuth();

    useEffectOnce(() => {
        void login({
            email,
            message: searchParams.get('message') ?? undefined,
            success: searchParams.get('success') ?? undefined,
        });
    });

    return null;
};

export default EmailVerified;
